import { Injectable } from '@angular/core';
import { NewsBarService } from './../components/bewatec-news-bar/service/news-bar.service';

@Injectable({ providedIn: 'root' })
export class NotificationsLibService {
  constructor(private newsBarService: NewsBarService) {}

  public setNewsBarTop(top: string) {
    this.newsBarService.top = top;
  }
}
