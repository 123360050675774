import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NotificationsModule } from '../notifications/notifications.module';
import { UtilsModule } from '../utils/utils.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { SelectionComponent } from './components/selection/selection.component';
import { DevIdDirective } from './directives/devId.directive';
import { HeaderModuleConfig } from './header.module.config';
import {
  featureName,
  reducers as HeaderModuleReducers,
  HeaderModuleState,
} from './reducers/module';

export const HEADER_REDUCER_TOKEN: InjectionToken<
  ActionReducerMap<HeaderModuleState>
> = new InjectionToken<ActionReducerMap<HeaderModuleState>>('Feature Reducers');

export function getReducers(): ActionReducerMap<HeaderModuleState> {
  return HeaderModuleReducers;
}

export function createTranslateLoader(
  http: HttpClient,
  headerModuleConfig: HeaderModuleConfig,
) {
  return new TranslateHttpLoader(
    http,
    headerModuleConfig.langEndpoint,
    '.json',
  );
}

@NgModule({
  imports: [
    NgOptimizedImage,
    CommonModule,
    RouterModule.forChild([]),
    StoreModule.forFeature(featureName, HEADER_REDUCER_TOKEN),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, HeaderModuleConfig],
      },
    }),
    NotificationsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatBadgeModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    UtilsModule,
  ],
  exports: [HeaderComponent],
  declarations: [
    DevIdDirective,
    HeaderComponent,
    LoginComponent,
    BreadcrumbComponent,
    SelectionComponent,
  ],
  providers: [],
})
export class HeaderModule {
  public static forRoot(
    config: HeaderModuleConfig,
  ): ModuleWithProviders<HeaderModule> {
    return {
      ngModule: HeaderModule,
      providers: [
        {
          provide: HeaderModuleConfig,
          useValue: config,
        },
        {
          provide: HEADER_REDUCER_TOKEN,
          useFactory: getReducers,
        },
      ],
    };
  }
}
