import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { Logger } from '../../external-modules/utils/logger/logger.service';

@Injectable({ providedIn: 'root' })
export abstract class AbstractService {
  constructor(
    protected httpClient: HttpClient,
    protected oauthService: OAuthService,
    protected logger: Logger,
  ) {}

  handleError(error: Response | any): Observable<any> {
    const objError: {
      status: string;
      title?: string;
      text?: string;
      url?: string;
      detail?: string;
    } = {
      status: error.status,
      title: error.name,
      text: error.message,
      url: error.url,
      detail: error?.error?.detail,
    };
    return throwError(objError);
  }
}
