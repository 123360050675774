<div class="header mat-elevation-z2">
  <div class="row justify-content-center header-content align-items-center">
    <div class="col-12 row logo-bar align-items-center">
      <div class="d-flex align-items-center">
        <!-- <img class="cc-img" height="60px" src="assets/images/bewatec-logo_0-padding.png"> -->
        <img
          id="logo"
          height="30"
          width="260"
          loading="eager"
          style="margin-right: 5px"
          [ngSrc]="'assets/images/logo.png'"
        />
        <ng-content
          *ngIf="config.useCustomHeading"
          select="[heading]"
        ></ng-content>
        <h1
          class="heading"
          style="margin: 0px 0 -2px; color: gray"
          *ngIf="!config.useCustomHeading"
        >
          DeviceManagement
        </h1>
      </div>
      <div class="col-12 d-none d-md-block col-md">
        <ng-content></ng-content>
      </div>
      <div
        class="col-md-auto login"
        [ngClass]="{
          'order-2': mediaBreakpointDownSm,
          'col-12': !loggedIn,
          'col-6': loggedIn,
        }"
      >
        <login-component [roles]="userRoles"></login-component>
      </div>
    </div>
    <div
      *ngIf="loggedIn && config.showBottomRow"
      devId="sideNavButton"
      #mainNavButton
      class="col-md-auto main-nav-button-wrapper col-auto"
      [ngClass]="{ 'order-0': mediaBreakpointDownSm }"
    >
      <button
        class="main-nav-button"
        mat-icon-button
        (click)="onClickMenuButton()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div
      *ngIf="(loggedIn && config.showBottomRow) || config.showBreadcrumbsAlways"
      class="col-12 col-md breadcrumb-container-wrapper"
      [ngClass]="{ 'order-3': mediaBreakpointDownSm }"
    >
      <div class="row align-items-center m-0 p-0">
        <div class="col breadcrumb-wrapper">
          <app-breadcrumb></app-breadcrumb>
        </div>
      </div>
    </div>
    <div
      *ngIf="showSelection() && config.showBottomRow"
      class="col col-md-auto selection-wrapper"
    >
      <selection-component></selection-component>
    </div>
  </div>
</div>
