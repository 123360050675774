import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Directive, Injectable, InjectionToken, Injector } from '@angular/core';

export const CONTAINER_DATA = new InjectionToken<any>('CONTAINER_DATA');

@Directive()
export class LoadingOverlayBase {
  private overlayRef: OverlayRef;
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private overlayDataToken: InjectionToken<any>,
  ) {}

  open(
    componentRef: ComponentType<any>,
    dialogConfig: OverlayConfig,
    data: any,
  ) {
    this.overlayRef = this.overlay.create(
      new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        scrollStrategy: dialogConfig.scrollStrategy,
        panelClass: dialogConfig.panelClass,
        hasBackdrop: dialogConfig.hasBackdrop,
        direction: dialogConfig.direction,
        minWidth: dialogConfig.minWidth,
        minHeight: dialogConfig.minHeight,
        maxWidth: dialogConfig.maxWidth,
        maxHeight: dialogConfig.maxHeight,
        disposeOnNavigation: dialogConfig.disposeOnNavigation,
      }),
    );
    const containerPortal = new ComponentPortal(
      componentRef,
      null,
      this.createInjector(data),
    );
    this.overlayRef.attach(containerPortal);
  }

  close() {
    this.overlayRef.dispose();
  }

  private createInjector(data: any) {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: this.overlayDataToken, useValue: data }],
    });
  }
}

@Injectable({ providedIn: 'root' })
export class LoadingOverlay extends LoadingOverlayBase {
  constructor(overlay: Overlay, injector: Injector) {
    super(overlay, injector, CONTAINER_DATA);
  }
}
