import { IDevicesUpdateStart } from '@/app/interfaces/devices-update-start.interface';
import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IDeviceUpdateSession } from '../../../interfaces/device-update-session.interface';
import { AbstractService } from '../../../services/abstract/abstract.service';
import { IUpdate } from './../../../interfaces/update.interface';

@Injectable({ providedIn: 'root' })
export class DeviceUpdateService extends AbstractService {
  stopUpdate(update: IUpdate): Observable<any> {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      update.id;

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .delete(url, options)
      .pipe(catchError(this.handleError));
  }

  getDeviceUpdates(currentPage: string, pageSize: string): Observable<any> {
    const url =
      environment.update_management.root +
      environment.update_management.updates;

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: { headers?: HttpHeaders; params?: HttpParams } = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.params = new HttpParams()
      .set('page', currentPage.toString())
      .set('size', pageSize.toString())
      .set('sort', 'created,desc');

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getDeviceUpdatesHistory(
    currentPage: string,
    pageSize: string,
  ): Observable<any> {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/history';

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: { headers?: HttpHeaders; params?: HttpParams } = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.params = new HttpParams()
      .set('page', currentPage.toString())
      .set('size', pageSize.toString());

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getSessionHistoryById(sessionId: string): Observable<any> {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      sessionId +
      '/history';

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: { headers?: HttpHeaders; params?: HttpParams } = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getDeviceUpdateSessionById(id: number) {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      id;

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: { headers?: HttpHeaders; params?: HttpParams } = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getAvailableVersions() {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.headers.set('Content-Type', 'application/json');
    const url =
      environment.update_management.root +
      environment.update_management.releases;

    const getReq = new HttpRequest('GET', url, options);

    return this.httpClient.request(getReq).pipe(catchError(this.handleError));
  }

  getAvailableVersionsForCustomer() {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.headers.set('Content-Type', 'application/json');
    const url =
      environment.update_management.root +
      environment.update_management.customer_releases;

    const getReq = new HttpRequest('GET', url, options);

    return this.httpClient.request(getReq).pipe(catchError(this.handleError));
  }

  getUpdateSessionDeviceStatus(
    updateId: number,
  ): Observable<{ deviceCount: number; state: string }[]> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.headers.set('Content-Type', 'application/json');
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      updateId +
      '/status';

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getReleaseById(releaseId: number): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.headers.set('Content-Type', 'application/json');
    const url =
      environment.update_management.root +
      environment.update_management.releases +
      '/' +
      releaseId;

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  addNewUpdateToMultipleDevices(update: IDevicesUpdateStart): Observable<any> {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/start';

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .post(url, update, options)
      .pipe(catchError((error) => of(error)));
  }

  addNewUpdate(update: IDeviceUpdateSession): Observable<any> {
    let url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      update.osVersion;

    if (update.locationGroupId) {
      url = url + '/location-group/' + update.locationGroupId;
    }

    if (update.deviceGroupId) {
      url = url + '/device-group/' + update.deviceGroupId;
    }

    const params: any = {};

    if (update.beginDownloadTime) {
      params.downloadTime = update.beginDownloadTime.toISOString();
    }

    if (update.beginInstallationTime) {
      params.installationTime = update.beginInstallationTime.toISOString();
    }

    if (update.maxConcurrentDownloads) {
      params.maxConcurrentDownloads = update.maxConcurrentDownloads;
    }

    if (update.maxConcurrentInstallations) {
      params.maxConcurrentInstallations = update.maxConcurrentInstallations;
    }

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.params = params;

    return this.httpClient
      .post(url, ' ', options)
      .pipe(catchError((error) => of(error)));
  }

  getLocationGroups(): Observable<any> {
    const url =
      environment.location_management.root +
      environment.location_management.locations_groups;

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getTargetGroup(targetId: number, groupType: string) {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    let url = '';
    if (groupType === 'device-groups') {
      url =
        environment.asset_management.root +
        environment.asset_management.device_groups +
        '/' +
        targetId;
    }
    if (groupType === 'location-groups') {
      url =
        environment.location_management.root +
        environment.location_management.locations_groups +
        '/' +
        targetId;
    }

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getDeviceGroups(): Observable<any> {
    const url =
      environment.asset_management.root +
      environment.asset_management.device_groups;

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getSessionDevices(updateId: string): Observable<any> {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      updateId +
      '/devices';

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  getSessionDevice(sessionId: string, deviceId: string | number | undefined) {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      sessionId +
      '/devices/' +
      deviceId;

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }

  retryDeviceUpdate(sessionId: string, deviceId: string) {
    const url =
      environment.update_management.root +
      environment.update_management.updates +
      '/' +
      sessionId +
      '/devices/' +
      deviceId +
      '/restart';

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .post(url, ' ', options)
      .pipe(catchError(this.handleError));
  }
}
