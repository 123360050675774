import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class BewatecOAuthService extends OAuthService {
  // Override calcTimeout for token expires bug
  override calcTimeout(storedAt: number, expiration: number): number {
    const tokenLifetime = expiration - storedAt;
    const refreshTime = storedAt + tokenLifetime * (this.timeoutFactor || 1);
    const timeLeft = Math.max(0, refreshTime - Date.now());
    return timeLeft;
  }
}
