import { environment } from '@/environments/environment';
import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { interval, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { Logger } from '../../external-modules/utils/logger/logger.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class WebsocketService implements OnDestroy {
  private readonly _oauthService = inject(OAuthService);
  private readonly _logger = inject(Logger);

  private readonly webSocketEndPoint =
    environment.update_management.websocket_broker;

  private readonly token = signal<string | undefined>(undefined);
  private readonly stompClient = signal<Stomp.Client | undefined>(undefined);
  private readonly isConnected = computed(
    () => this.stompClient()?.connected ?? false,
  );

  private readonly reconnectDelay = 5000;
  private readonly retryInterval = 5000;

  constructor() {
    // Initialize token
    this.token.set(this._oauthService.getAccessToken());

    this._oauthService.events
      .pipe(untilDestroyed(this))
      .subscribe((event: OAuthEvent) => {
        if (event.type === 'token_received') {
          this.disconnect();
          this.token.set(this._oauthService.getAccessToken());
          if (this.isAuthenticationValid()) this.initializeWebSocket();
        }
      });

    if (this.isAuthenticationValid()) {
      this.initializeWebSocket();
    } else {
      this._logger.log(
        'WebSocketAPIService',
        'Cannot initialize: Missing authentication token',
      );
    }
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  public send(message: unknown[], topic: string): void {
    if (!this.isAuthenticationValid()) {
      this._logger.log(
        'WebSocketAPIService',
        'Cannot send: Missing authentication token',
      );
      return;
    }

    if (!this.isConnected()) {
      this._logger.log(
        'WebSocketAPIService',
        'WebSocket not connected. Scheduling message send.',
      );
      this.retryOperation(() => this.sendMessage(message, topic));
      return;
    }

    this.sendMessage(message, topic);
  }

  public subscribe(topic: string): Observable<any> {
    return new Observable((observer) => {
      if (!this.isAuthenticationValid()) {
        return;
      }

      if (!this.isConnected()) {
        this.retryOperation(() => this.subscribeToTopic(topic, observer));
        return;
      }

      this.subscribeToTopic(topic, observer);
    });
  }

  private connect(token?: string): void {
    if (!token) {
      this._logger.log('WebSocketAPIService', 'Cannot connect: Missing token');
      return;
    }

    const client = Stomp.over(
      new SockJS(
        `${this.webSocketEndPoint}?${environment.websocketToken}=${encodeURIComponent(token)}`,
      ),
    );
    client.debug = () => {}; // Disable debug logging

    client.connect(
      { Authorization: `Bearer ${token}` },
      () => {
        this.stompClient.set(client);
        this._logger.log('WebSocketAPIService', 'WebSocket Connected');
      },
      (error) => {
        this._logger.log('WebSocketAPIService', 'Connection error:', error);
        setTimeout(() => {
          if (this.isAuthenticationValid()) {
            this.connect(this.token());
          }
        }, this.reconnectDelay);
      },
    );
  }

  private sendMessage(message: unknown[], topic: string): void {
    this.stompClient()?.send(topic, {}, JSON.stringify(message));
  }

  private subscribeToTopic(topic: string, observer: any): void {
    this.stompClient()?.subscribe(topic, (result) => observer.next(result));
  }

  private retryOperation(operation: () => void): void {
    interval(this.retryInterval)
      .pipe(
        untilDestroyed(this),
        takeWhile(() => !this.isConnected()),
      )
      .subscribe({
        next: () => {
          this._logger.log('WebSocketAPIService', 'Retrying operation');
          if (this.isConnected()) operation();
        },
        complete: () => {
          if (this.isConnected()) operation();
        },
      });
  }

  private disconnect(): void {
    if (this.isConnected()) {
      this.stompClient()?.disconnect(() => {
        this._logger.log('WebSocketAPIService', 'WebSocket Disconnected');
        this.stompClient.set(undefined);
      });
    }
  }

  private isAuthenticationValid(): boolean {
    return Boolean(this.token() && this.webSocketEndPoint);
  }

  private initializeWebSocket(): void {
    this.connect(this.token());
  }
}
