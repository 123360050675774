import { environment } from './environments/environment';
import { HttpClient } from '@angular/common/http';
import type {
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse as AngularHttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
export type IsOnline4400 = { [key: string]: unknown };

export type IsOnline1400 = { [key: string]: unknown };

export type IsOnline5400 = { [key: string]: unknown };

export type IsOnline2400 = { [key: string]: unknown };

export type IsOnline3400 = { [key: string]: unknown };

export type IsOnline400 = { [key: string]: unknown };

export type DeleteDeviceCommand400 = { [key: string]: unknown };

export type DeleteMultiplePorts400 = { [key: string]: unknown };

export type CancelConfigUpdateSession400 = { [key: string]: unknown };

export type CancelConfigUpdateSession200 = { [key: string]: unknown };

export type DeletePropertyTemplate400 = { [key: string]: unknown };

export type GetAllActivationOptions400 = { [key: string]: unknown };

export type GetAllActivationOptions200 = { [key: string]: unknown };

export type GetApplicationHeal400 = { [key: string]: unknown };

export type GetOverview400 = { [key: string]: unknown };

export type GetOverviewParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
};

export type GetAnnouncementByDeviceId400 = { [key: string]: unknown };

export type DeleteAnnouncementById400 = { [key: string]: unknown };

export type DeleteAnnouncementById200 = { [key: string]: unknown };

export type GetAnnouncementById400 = { [key: string]: unknown };

export type GetCertificateByDeviceId400 = { [key: string]: unknown };

export type DeleteCertificatedById400 = { [key: string]: unknown };

export type DeleteCertificatedById200 = { [key: string]: unknown };

export type GetCertificateById400 = { [key: string]: unknown };

export type GetAllCertificates400 = { [key: string]: unknown };

export type GetAllCertificatesParams = {
  searchString?: string;
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type GetLatestConfigInfoForDevice400 = { [key: string]: unknown };

export type GetLatestConfigInfoForDevice200 = { [key: string]: unknown };

export type GetConfigJsonById400 = { [key: string]: unknown };

export type GetConfigJsonById200 = { [key: string]: unknown };

export type GetConfigJsonByIdParams = {
  patientProfileId?: number;
  deviceId?: number;
  updateCache?: boolean;
  testConfig?: boolean;
};

export type GetAllCriterias400 = { [key: string]: unknown };

export type GetAllCriterias200 = { [key: string]: unknown };

export type DeleteCustomerBridge400 = { [key: string]: unknown };

export type GetCustomerBridge400 = { [key: string]: unknown };

export type GetCustomerDetailData400 = { [key: string]: unknown };

export type GetDistributor1400 = { [key: string]: unknown };

export type GetNumberOfDevices400 = { [key: string]: unknown };

export type GetDownloadUrl400 = { [key: string]: unknown };

export type GetDownloadUrl200 = { [key: string]: unknown };

export type GetDownloadUrlParams = {
  renew?: boolean;
};

export type DeleteDeviceImage400 = { [key: string]: unknown };

export type DeleteDeviceImage200 = { [key: string]: unknown };

export type GetDeviceImage400 = { [key: string]: unknown };

export type GetDeviceImage200 = { [key: string]: unknown };

export type GetAllImages400 = { [key: string]: unknown };

export type GetAllImages200 = { [key: string]: unknown };

export type GetAllImagesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type GetAllDeviceFamilies400 = { [key: string]: unknown };

export type GetCommandForDevice400 = { [key: string]: unknown };

export type GetCommandForDevice200 = { [key: string]: unknown };

export type ExportDevicesToCsvFile503 = { [key: string]: unknown };

export type ExportDevicesToCsvFile400 = { [key: string]: unknown };

export type ExportDevicesToCsvFileParams = {
  header?: boolean;
  search?: string;
};

export type GetDeviceByMac400 = { [key: string]: unknown };

export type GetDevicesWithoutGroup400 = { [key: string]: unknown };

export type GetDevicesWithoutGroupParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
};

export type GetDeviceGroup400 = { [key: string]: unknown };

export type GetDistributorDetailData400 = { [key: string]: unknown };

export type GetFeaturesByCustomerId400 = { [key: string]: unknown };

export type GetPaymentConfigFeaturesForCustomer400 = { [key: string]: unknown };

export type GetPaymentConfigFeaturesForCustomer200 = { [key: string]: boolean };

export type GetFirewallResult400 = { [key: string]: unknown };

export type GetFirewallResultList400 = { [key: string]: unknown };

export type GetFirewallResultListParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
  filters?: string[];
};

export type GetNotificationById400 = { [key: string]: unknown };

export type GetNotificationById200 = { [key: string]: unknown };

export type GetNotificationByIdParams = {
  locale: string;
};

export type GetNotifications400 = { [key: string]: unknown };

export type GetNotificationsParams = {
  read?: boolean;
  locale?: string;
};

export type GetById1400 = { [key: string]: unknown };

export type GetAllEntries400 = { [key: string]: unknown };

export type GetAllEntries200 = { [key: string]: unknown };

export type GetAllEntriesContextItem =
  (typeof GetAllEntriesContextItem)[keyof typeof GetAllEntriesContextItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllEntriesContextItem = {
  PROFILE_CLONE: 'PROFILE_CLONE',
  PROFILE_CREATE: 'PROFILE_CREATE',
  PROFILE_DELETE: 'PROFILE_DELETE',
  PROFILE_EDIT: 'PROFILE_EDIT',
  PROFILE_IMPORT: 'PROFILE_IMPORT',
  PROFILE_SCHEMA_CREATE: 'PROFILE_SCHEMA_CREATE',
  PROFILE_UPDATE_CANCEL: 'PROFILE_UPDATE_CANCEL',
  PROFILE_UPDATE_START: 'PROFILE_UPDATE_START',
  PROFILE_ORDER_EDIT: 'PROFILE_ORDER_EDIT',
  PROFILE_PROPERTY_TEMPLATE_ADD: 'PROFILE_PROPERTY_TEMPLATE_ADD',
  PROFILE_PROPERTY_TEMPLATE_UPDATE: 'PROFILE_PROPERTY_TEMPLATE_UPDATE',
  PROFILE_PROPERTY_TEMPLATE_DELETE: 'PROFILE_PROPERTY_TEMPLATE_DELETE',
  PROFILE_PROPERTY_ADD: 'PROFILE_PROPERTY_ADD',
  PROFILE_PROPERTY_UPDATE: 'PROFILE_PROPERTY_UPDATE',
  PROFILE_PROPERTY_DELETE: 'PROFILE_PROPERTY_DELETE',
  CERTIFICATE_CREATE: 'CERTIFICATE_CREATE',
  CERTIFICATE_DELETE: 'CERTIFICATE_DELETE',
  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_DELETE: 'CUSTOMER_DELETE',
  CUSTOMER_EDIT: 'CUSTOMER_EDIT',
  DEVICE_CREATE: 'DEVICE_CREATE',
  DEVICE_DELETE: 'DEVICE_DELETE',
  DEVICE_EDIT: 'DEVICE_EDIT',
  DEVICE_EXPORT: 'DEVICE_EXPORT',
  DEVICE_GROUP_CREATE: 'DEVICE_GROUP_CREATE',
  DEVICE_GROUP_DELETE: 'DEVICE_GROUP_DELETE',
  DEVICE_GROUP_EDIT: 'DEVICE_GROUP_EDIT',
  DEVICE_GROUP_EDIT_DEVICES: 'DEVICE_GROUP_EDIT_DEVICES',
  DEVICE_IMAGE_CREATE: 'DEVICE_IMAGE_CREATE',
  DEVICE_IMAGE_DELETE: 'DEVICE_IMAGE_DELETE',
  DEVICE_IMPORT: 'DEVICE_IMPORT',
  DEVICE_LOCK: 'DEVICE_LOCK',
  DEVICE_REBOOT: 'DEVICE_REBOOT',
  DEVICE_REMOVE_CUSTOMER_ASSOCIATION: 'DEVICE_REMOVE_CUSTOMER_ASSOCIATION',
  DEVICE_TYPE_CREATE: 'DEVICE_TYPE_CREATE',
  DEVICE_TYPE_EDIT: 'DEVICE_TYPE_EDIT',
  DEVICE_UNLOCK: 'DEVICE_UNLOCK',
  DISTRIBUTOR_CREATE: 'DISTRIBUTOR_CREATE',
  DISTRIBUTOR_DELETE: 'DISTRIBUTOR_DELETE',
  DISTRIBUTOR_EDIT: 'DISTRIBUTOR_EDIT',
  LICENSE_MODEL_CUSTOMER_CREATE: 'LICENSE_MODEL_CUSTOMER_CREATE',
  LICENSE_MODEL_CUSTOMER_DELETE: 'LICENSE_MODEL_CUSTOMER_DELETE',
  LICENSE_MODEL_CUSTOMER_EDIT: 'LICENSE_MODEL_CUSTOMER_EDIT',
  PATIENT_PROFILE_RULE_CREATE: 'PATIENT_PROFILE_RULE_CREATE',
  PATIENT_PROFILE_RULE_DELETE: 'PATIENT_PROFILE_RULE_DELETE',
  PATIENT_PROFILE_RULE_EDIT: 'PATIENT_PROFILE_RULE_EDIT',
  PORT_CREATE: 'PORT_CREATE',
  PORT_CUSTOMER_ASSOCIATION: 'PORT_CUSTOMER_ASSOCIATION',
  PORT_DELETE: 'PORT_DELETE',
  PORT_EDIT: 'PORT_EDIT',
  PORT_EXPORT: 'PORT_EXPORT',
  PORT_GROUP_CREATE: 'PORT_GROUP_CREATE',
  PORT_GROUP_DELETE: 'PORT_GROUP_DELETE',
  PORT_GROUP_EDIT: 'PORT_GROUP_EDIT',
  PORT_IMPORT: 'PORT_IMPORT',
  PORT_PROPERTIES_IMPORT: 'PORT_PROPERTIES_IMPORT',
  PUSH_MESSAGE_SENT: 'PUSH_MESSAGE_SENT',
  RELEASE_CREATE: 'RELEASE_CREATE',
  RELEASE_CUSTOMER_EDIT: 'RELEASE_CUSTOMER_EDIT',
  RELEASE_DELETE: 'RELEASE_DELETE',
  RELEASE_EDIT: 'RELEASE_EDIT',
  SOFTWARE_UPDATE_CANCEL: 'SOFTWARE_UPDATE_CANCEL',
  SOFTWARE_UPDATE_SINGLE_DEVICE_RESTART:
    'SOFTWARE_UPDATE_SINGLE_DEVICE_RESTART',
  SOFTWARE_UPDATE_START: 'SOFTWARE_UPDATE_START',
  REINSTALL_SOFTWARE: 'REINSTALL_SOFTWARE',
  REINSTALL_PROFILE: 'REINSTALL_PROFILE',
  PROFILE_PROPERTY_MULTIPLE_DELETE: 'PROFILE_PROPERTY_MULTIPLE_DELETE',
  DEVICE_IMAGE_LOAD_FROM_S3: 'DEVICE_IMAGE_LOAD_FROM_S3',
} as const;

export type GetAllEntriesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  context?: GetAllEntriesContextItem[];
  from?: string;
  to?: string;
  userId?: string;
  customerId?: string;
};

export type GetCategories400 = { [key: string]: unknown };

export type GetCategories200 = { [key: string]: unknown };

export type GetLldpLocation400 = { [key: string]: unknown };

export type GetLldpLocation200 = { [key: string]: unknown };

export type GetDeviceGroupListConfig400 = { [key: string]: unknown };

export type GetDeviceListConfig400 = { [key: string]: unknown };

export type GetLocationGroupListConfiguration400 = { [key: string]: unknown };

export type GetLocationListConfiguration400 = { [key: string]: unknown };

export type GetLicenseModelById400 = { [key: string]: unknown };

export type GetLicenseModelById200 = { [key: string]: unknown };

export type GetAllLicenseModels400 = { [key: string]: unknown };

export type GetAllLicenseModels200 = { [key: string]: unknown };

export type GetAllLicenseModelsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type GetBridge400 = { [key: string]: unknown };

export type GetBridge200 = { [key: string]: unknown };

export type GetBridges400 = { [key: string]: unknown };

export type GetBridges200 = { [key: string]: unknown };

export type GetBridgesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  unassigned?: boolean;
};

export type GetDailyJournalDownloadUrl400 = { [key: string]: unknown };

export type GetDailyJournalDownloadUrl200 = { [key: string]: unknown };

export type GetLogcatDownloadUrl400 = { [key: string]: unknown };

export type GetLogcatDownloadUrl200 = { [key: string]: unknown };

export type GetAllOperators400 = { [key: string]: unknown };

export type GetAllOperators200 = { [key: string]: unknown };

export type GetPatientProfile400 = { [key: string]: unknown };

export type GetPatientProfile200 = { [key: string]: unknown };

export type GetPatientProfileParams = {
  light?: boolean;
};

export type GetPortGroupForDeviceId400 = { [key: string]: unknown };

export type GetPortGroupForDeviceId200 = { [key: string]: unknown };

export type GetAllAssignedDevices400 = { [key: string]: unknown };

export type ExportPortsToCSV400 = { [key: string]: unknown };

export type ExportPortsToCSVParams = {
  search?: string;
  header?: boolean;
  format?: string;
};

export type GetAllPortsWithoutGroup400 = { [key: string]: unknown };

export type GetAllPortsWithoutGroupParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
};

export type GetReleasesForCustomer400 = { [key: string]: unknown };

export type GetReleasesForCustomer200 = { [key: string]: unknown };

export type GetReleasesForCustomerParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  customerId?: string;
};

export type GetReleaseNotesDownloadUrl400 = { [key: string]: unknown };

export type GetReleaseNotesDownloadUrl200 = { [key: string]: unknown };

export type GetPatientProfileRulesForDevice400 = { [key: string]: unknown };

export type GetPatientProfileRulesForDevice200 = { [key: string]: unknown };

export type GetAllPatientProfileRules400 = { [key: string]: unknown };

export type GetAllPatientProfileRules200 = { [key: string]: unknown };

export type GetSessionByConfigId400 = { [key: string]: unknown };

export type GetSessionByConfigIdParams = {
  latest?: boolean;
};

export type GetSessionById400 = { [key: string]: unknown };

export type GetSessionById200 = { [key: string]: unknown };

export type GetDeviceForSession400 = { [key: string]: unknown };

export type GetSessionDevicesBySessionId400 = { [key: string]: unknown };

export type GetAllSessions400 = { [key: string]: unknown };

export type GetAllSessionsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  active?: boolean;
};

export type GetDeviceSWVersions400 = { [key: string]: unknown };

export type GetUpdateHistory400 = { [key: string]: unknown };

export type GetUpdateHistoryParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type GetDevicesForUpdateSession400 = { [key: string]: unknown };

export type GetUpdateSessionHistory400 = { [key: string]: unknown };

export type GetUpdateSessionHistory200 = { [key: string]: unknown };

export type GetUpdateSessionStats400 = { [key: string]: unknown };

export type GetUpdateSessionStats200 = { [key: string]: unknown };

export type GetUpdateSessionDeviceStatus400 = { [key: string]: unknown };

export type GetUpdateSessionDeviceStatus200 = { [key: string]: unknown };

export type DeleteUpdateSession400 = { [key: string]: unknown };

export type GetUpdateSession400 = { [key: string]: unknown };

export type GetDeviceForUpdateSession400 = { [key: string]: unknown };

export type GetDeviceForUpdateSession200 = { [key: string]: unknown };

export type GetAllUpdateSessions400 = { [key: string]: unknown };

export type GetAllUpdateSessionsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  active?: boolean;
};

export type GetAllUsers400 = { [key: string]: unknown };

export type GetAllUsers200 = { [key: string]: unknown };

export type GetAllUsersParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  searchString?: string;
  customer?: string;
  distributor?: string;
};

export type GetLatestProfileInfoForDevice400 = { [key: string]: unknown };

export type GetLatestProfileInfoForDevice200 = { [key: string]: unknown };

export type GetProfileJson400 = { [key: string]: unknown };

export type GetProfileJson200 = { [key: string]: unknown };

export type GetProfileJsonParams = {
  patientProfileId?: number;
  deviceId?: number;
  updateCache?: boolean;
  testConfig?: boolean;
};

export type PostNotification400 = { [key: string]: unknown };

export type GetAllNotifications400 = { [key: string]: unknown };

export type GetAllNotificationsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type ImportCertificateFromFile503 = { [key: string]: unknown };

export type ImportCertificateFromFile400 = { [key: string]: unknown };

export type ImportCertificateFromFile200 = { [key: string]: unknown };

export type ImportCertificateFromFileBody = {
  file: Blob;
};

export type ClonePatientProfile400 = { [key: string]: unknown };

export type ClonePatientProfile200 = { [key: string]: unknown };

export type CreatePatientProfile400 = { [key: string]: unknown };

export type CreatePatientProfile200 = { [key: string]: unknown };

export type GetCustomerPatientProfiles400 = { [key: string]: unknown };

export type GetCustomerPatientProfiles200 = { [key: string]: unknown };

export type CheckGroupsForCustomers400 = { [key: string]: unknown };

export type AddCustomerBridge400 = { [key: string]: unknown };

export type GetCustomerBridges400 = { [key: string]: unknown };

export type GetCustomerBridges200 = { [key: string]: unknown };

export type PostContactForCustomer400 = { [key: string]: unknown };

export type PostContactForCustomer200 = { [key: string]: unknown };

export type GetContactsForCustomer400 = { [key: string]: unknown };

export type GetContactsForCustomer200 = { [key: string]: unknown };

export type PostUserForCustomer400 = { [key: string]: unknown };

export type PostUserForCustomer200 = { [key: string]: unknown };

export type GetUsersForCustomer400 = { [key: string]: unknown };

export type GetUsersForCustomerParams = {
  searchString?: string;
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type PostCustomer400 = { [key: string]: unknown };

export type GetCustomers1400 = { [key: string]: unknown };

export type GetCustomers1Params = {
  deleted?: boolean;
  alphabetically?: boolean;
  search?: string;
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type PostDeviceGroup400 = { [key: string]: unknown };

export type GetAllGroups1400 = { [key: string]: unknown };

export type GetAllGroups1Params = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  deviceProfileId?: number;
  search?: string;
  fullView?: boolean;
};

export type PostDeviceType400 = { [key: string]: unknown };

export type GetAllDeviceTypes400 = { [key: string]: unknown };

export type GetAllDeviceTypesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type PostResponseFromDevice400 = { [key: string]: unknown };

export type GetAllCommands400 = { [key: string]: unknown };

export type ImportDevices400 = { [key: string]: unknown };

export type ImportDevicesBody = {
  file: Blob;
};

export type ImportDevicesParams = {
  customerId?: string;
  distributorId: string;
  groupId?: number;
  groupName?: string;
  locked?: boolean;
};

export type RestartDevice1400 = { [key: string]: unknown };

export type RestartDevice400 = { [key: string]: unknown };

export type PostDevice400 = { [key: string]: unknown };

export type GetAllDevices400 = { [key: string]: unknown };

export type GetAllDevicesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  mac?: string;
  search?: string;
  deviceProfileId?: number;
  fullView?: boolean;
};

export type CheckDistributorUsersGroupAttributes400 = {
  [key: string]: unknown;
};

export type CheckDistributorUsersGroupAttributesParams = {
  fix?: boolean;
};

export type CheckGroupsForDistributors400 = { [key: string]: unknown };

export type PostImport400 = { [key: string]: unknown };

export type PostContactForDistributor400 = { [key: string]: unknown };

export type PostContactForDistributor200 = { [key: string]: unknown };

export type GetContactsForDistributor400 = { [key: string]: unknown };

export type GetContactsForDistributor200 = { [key: string]: unknown };

export type PostCustomerForDistributor400 = { [key: string]: unknown };

export type GetCustomers400 = { [key: string]: unknown };

export type PostUserForDistributor400 = { [key: string]: unknown };

export type PostUserForDistributor200 = { [key: string]: unknown };

export type GetUsersForDistributor400 = { [key: string]: unknown };

export type GetUsersForDistributorParams = {
  searchString?: string;
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type PostDistributor400 = { [key: string]: unknown };

export type GetAll400 = { [key: string]: unknown };

export type GetAllParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
};

export type CreateFeature400 = { [key: string]: unknown };

export type GetAllFeatures400 = { [key: string]: unknown };

export type GetAllFeaturesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type PostFeedback400 = { [key: string]: unknown };

export type PostFeedbackBody = {
  screenshot?: Blob;
};

export type PostFeedbackParams = {
  description: string;
};

export type PostProbingRequestAll400 = { [key: string]: unknown };

export type PostProbingRequestByProbeResultId400 = { [key: string]: unknown };

export type MigrateProperties400 = { [key: string]: unknown };

export type PostLocationForPort400 = { [key: string]: unknown };

export type PostLocationForPort200 = { [key: string]: unknown };

export type PostLocation400 = { [key: string]: unknown };

export type PostLocation200 = { [key: string]: unknown };

export type GetAllLldpLocations400 = { [key: string]: unknown };

export type GetAllLldpLocationsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type CreateDeviceTableConfiguration400 = { [key: string]: unknown };

export type GetAllDeviceTableConfigurations400 = { [key: string]: unknown };

export type CreateFilterCategory3400 = { [key: string]: unknown };

export type GetAllFilterCategories1400 = { [key: string]: unknown };

export type CreateFilterCategory2400 = { [key: string]: unknown };

export type GetAllFilterCategories400 = { [key: string]: unknown };

export type CreateTableConfiguration400 = { [key: string]: unknown };

export type GetAllTableConfigurations400 = { [key: string]: unknown };

export type PostDailyJournal400 = { [key: string]: unknown };

export type PostDailyJournalBody = {
  dailyjournal: Blob;
};

export type PostDailyJournalParams = {
  macAddress: string;
};

export type PostLogcatFile400 = { [key: string]: unknown };

export type PostLogcatFileBody = {
  logcatFile: Blob;
};

export type PostLogcatFileParams = {
  macAddress: string;
};

export type DeleteDailyJournal400 = { [key: string]: unknown };

export type CreateDailyJournal400 = { [key: string]: unknown };

export type DeleteLogcatFile400 = { [key: string]: unknown };

export type CreateLogcatFile400 = { [key: string]: unknown };

export type SendPushNotificationToDeviceGroup400 = { [key: string]: unknown };

export type SendPushNotificationToLocationGroup400 = { [key: string]: unknown };

export type SendPushNotifications400 = { [key: string]: unknown };

export type SendPushNotificationToDevice400 = { [key: string]: unknown };

export type PostGroup400 = { [key: string]: unknown };

export type PostGroup200 = { [key: string]: unknown };

export type GetAllGroups400 = { [key: string]: unknown };

export type GetAllGroupsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  deviceProfileId?: number;
  search?: string;
  fullView?: boolean;
};

export type PostLocationsForDevices400 = { [key: string]: unknown };

export type ImportLocations400 = { [key: string]: unknown };

export type ImportLocationsBody = {
  file: Blob;
};

export type ImportLocationsType =
  (typeof ImportLocationsType)[keyof typeof ImportLocationsType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImportLocationsType = {
  LLDP: 'LLDP',
  MAC: 'MAC',
  PROPERTIES: 'PROPERTIES',
} as const;

export type ImportLocationsParams = {
  customerId: string;
  distributorId?: string;
  groupId?: number;
  groupName?: string;
  type?: ImportLocationsType;
  header?: boolean;
};

export type ImportPropertiesLocations503 = { [key: string]: unknown };

export type ImportPropertiesLocations400 = { [key: string]: unknown };

export type ImportPropertiesLocationsBody = {
  file: Blob;
};

export type ImportPropertiesLocationsParams = {
  customerId: string;
  header?: boolean;
};

export type PostPort400 = { [key: string]: unknown };

export type GetAllPorts400 = { [key: string]: unknown };

export type GetAllPorts200 = { [key: string]: unknown };

export type GetAllPortsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  deviceId?: number;
  search?: string;
  fullView?: boolean;
};

export type RegisterDevice400 = { [key: string]: unknown };

export type RegisterDevice200 = { [key: string]: unknown };

export type RegisterPhysicalLocation400 = { [key: string]: unknown };

export type RegisterPhysicalLocation200 = { [key: string]: unknown };

export type RegisterPatient400 = { [key: string]: unknown };

export type RegisterSipState400 = { [key: string]: unknown };

export type PostReleaseForCustomer400 = { [key: string]: unknown };

export type PostReleaseForCustomer200 = { [key: string]: unknown };

export type PostImageForRelease400 = { [key: string]: unknown };

export type PostImageForRelease200 = { [key: string]: unknown };

export type GetImagesForRelease400 = { [key: string]: unknown };

export type GetImagesForRelease200 = { [key: string]: unknown };

export type PostReleaseNotes400 = { [key: string]: unknown };

export type PostReleaseNotesBody = {
  file: Blob;
};

export type PostReleaseNotesParams = {
  name?: string;
  info?: string;
};

export type GetReleaseNotes400 = { [key: string]: unknown };

export type GetReleaseNotes200 = { [key: string]: unknown };

export type CheckForMissingRoles400 = { [key: string]: unknown };

export type CheckForMissingRolesParams = {
  createMissing?: boolean;
};

export type ProcessBatchConfigUpdate400 = { [key: string]: unknown };

export type ProcessBatchConfigUpdate200 = { [key: string]: unknown };

export type CreateAndStartConfigUpdateSession400 = { [key: string]: unknown };

export type CreateAndStartConfigUpdateSession200 = { [key: string]: unknown };

export type CreateAndStartConfigUpdateSessionParams = {
  max_concurrent_downloads?: number;
  test_only?: boolean;
};

export type PostBulkUpdate400 = { [key: string]: unknown };

export type RestartSingleDeviceUpdate400 = { [key: string]: unknown };

export type PostDeviceUpdateSession400 = { [key: string]: unknown };

export type PostDeviceUpdateSessionParams = {
  downloadTime?: string;
  installationTime?: string;
  maxConcurrentDownloads?: number;
  maxConcurrentInstallations?: number;
  useCanary?: boolean;
};

export type PostLocationUpdateSession400 = { [key: string]: unknown };

export type PostLocationUpdateSessionParams = {
  downloadTime?: string;
  installationTime?: string;
  maxConcurrentDownloads?: number;
  maxConcurrentInstallations?: number;
  useCanary?: boolean;
};

export type PostWebApp400 = { [key: string]: unknown };

export type GetAllWebApps400 = { [key: string]: unknown };

export type GetAllWebApps200 = { [key: string]: unknown };

export type GetAllWebAppsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type CloneProfileById400 = { [key: string]: unknown };

export type CloneProfileById200 = { [key: string]: unknown };

export type ImportProfileFromFile503 = { [key: string]: unknown };

export type ImportProfileFromFile400 = { [key: string]: unknown };

export type ImportProfileFromFile200 = { [key: string]: unknown };

export type ImportProfileFromFileBody = {
  file: Blob;
  meta?: ProfileDto;
};

export type StartMigrationOfProfiles400 = { [key: string]: unknown };

export type StartMigrationOfProfiles200 = { [key: string]: unknown };

export type StartMigrationOfProfilesParams = {
  not_migrated?: boolean;
};

export type AddProfilePropertiesList400 = { [key: string]: unknown };

export type AddProfilePropertiesList200 = { [key: string]: unknown };

export type AddProfilePropertiesFromTemplate400 = { [key: string]: unknown };

export type AddProfilePropertiesFromTemplate200 = { [key: string]: unknown };

export type AddProfileProperties400 = { [key: string]: unknown };

export type AddProfileProperties200 = { [key: string]: unknown };

export type AddProfilePropertiesParams = {
  parentId?: number;
  templateId?: number;
};

export type GetProfileProperties400 = { [key: string]: unknown };

export type GetProfileProperties200 = { [key: string]: unknown };

export type GetProfilePropertiesParams = {
  path?: string;
};

export type DeleteNotificationById400 = { [key: string]: unknown };

export type PutNotificationById400 = { [key: string]: unknown };

export type CreateAnnouncement400 = { [key: string]: unknown };

export type UpdateAnnouncement400 = { [key: string]: unknown };

export type GetAllAnnouncements400 = { [key: string]: unknown };

export type GetAllAnnouncementsParams = {
  searchString?: string;
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type UpdateCustomerPatientProfileOrder400 = { [key: string]: unknown };

export type UpdateCustomerPatientProfileOrder200 = { [key: string]: unknown };

export type UpdateCustomerPatientProfileOrderBody = { [key: string]: number };

export type RemovePatientProfile400 = { [key: string]: unknown };

export type RemovePatientProfile200 = { [key: string]: unknown };

export type UpdatePatientProfile400 = { [key: string]: unknown };

export type UpdatePatientProfile200 = { [key: string]: unknown };

export type UpdatePatientProfileParams = {
  deleted?: boolean;
};

export type DeleteContactForCustomer400 = { [key: string]: unknown };

export type PutContactForCustomer400 = { [key: string]: unknown };

export type PutContactForCustomer200 = { [key: string]: unknown };

export type AssociateDeviceGroupToCustomer400 = { [key: string]: unknown };

export type AssociateDevicesToCustomer400 = { [key: string]: unknown };

export type GetDevicesForCustomer400 = { [key: string]: unknown };

export type GetDevicesForCustomerParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type PutDistributorForCustomer400 = { [key: string]: unknown };

export type AssociatePortsToCustomer400 = { [key: string]: unknown };

export type DeleteCustomer400 = { [key: string]: unknown };

export type PutCustomer400 = { [key: string]: unknown };

export type GetCustomer400 = { [key: string]: unknown };

export type PutDevices503 = { [key: string]: unknown };

export type PutDevices400 = { [key: string]: unknown };

export type GetGroupDevices400 = { [key: string]: unknown };

export type GetGroupDevicesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
};

export type DeleteDeviceGroup400 = { [key: string]: unknown };

export type PutDeviceGroup400 = { [key: string]: unknown };

export type GetDeviceById400 = { [key: string]: unknown };

export type GetDeviceById200 = { [key: string]: unknown };

export type PutDeviceImage400 = { [key: string]: unknown };

export type PutDeviceImage200 = { [key: string]: unknown };

export type PutDeviceType400 = { [key: string]: unknown };

export type GetDeviceTypeById400 = { [key: string]: unknown };

export type DeleteDeviceLock400 = { [key: string]: unknown };

export type PutDeviceLock400 = { [key: string]: unknown };

export type RevokeCustomerAssociation400 = { [key: string]: unknown };

export type PutDeviceTestStatus400 = { [key: string]: unknown };

export type PutDeviceTestStatusParams = {
  value: boolean;
};

export type DeleteDevice400 = { [key: string]: unknown };

export type PutDevice400 = { [key: string]: unknown };

export type GetDevice400 = { [key: string]: unknown };

export type GetDevice200 = { [key: string]: unknown };

export type GetDeviceParams = {
  fullView?: boolean;
};

export type DeleteContactForDistributor400 = { [key: string]: unknown };

export type PutContactForDistributor400 = { [key: string]: unknown };

export type PutContactForDistributor200 = { [key: string]: unknown };

export type AssociateDevicesToDistributor400 = { [key: string]: unknown };

export type GetDevicesForDistributor400 = { [key: string]: unknown };

export type GetDevicesForDistributorParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type DeleteDistributor400 = { [key: string]: unknown };

export type PutDistributor400 = { [key: string]: unknown };

export type GetDistributor400 = { [key: string]: unknown };

export type DeleteFeature400 = { [key: string]: unknown };

export type UpdateFeature400 = { [key: string]: unknown };

export type GetFeature400 = { [key: string]: unknown };

export type DeletePinStatusNotificationById400 = { [key: string]: unknown };

export type PutPinStatusNotificationById400 = { [key: string]: unknown };

export type DeleteReadStatusNotificationById400 = { [key: string]: unknown };

export type PutReadStatusNotificationById400 = { [key: string]: unknown };

export type DeleteDeviceTableConfiguration400 = { [key: string]: unknown };

export type UpdateDeviceTableConfigurations400 = { [key: string]: unknown };

export type GetDeviceTableConfigurations400 = { [key: string]: unknown };

export type DeleteDeviceFilterConfiguration400 = { [key: string]: unknown };

export type CreateFilterCategory1400 = { [key: string]: unknown };

export type GetFilterCategory1400 = { [key: string]: unknown };

export type CreateFilterCategory400 = { [key: string]: unknown };

export type GetFilterCategory400 = { [key: string]: unknown };

export type UpdateTableConfigurations400 = { [key: string]: unknown };

export type GetTableConfigurations400 = { [key: string]: unknown };

export type DeleteLicenseModelDataForCustomer400 = { [key: string]: unknown };

export type DeleteLicenseModelDataForCustomer200 = { [key: string]: unknown };

export type DeleteLicenseModelDataForCustomerParams = {
  customerId: string;
};

export type CreateLicenseModelData400 = { [key: string]: unknown };

export type CreateLicenseModelData200 = { [key: string]: unknown };

export type CreateLicenseModelDataParams = {
  customerId: string;
};

export type UpdateLicenseModelDataForCustomer400 = { [key: string]: unknown };

export type UpdateLicenseModelDataForCustomer200 = { [key: string]: unknown };

export type UpdateLicenseModelDataForCustomerParams = {
  customerId?: string;
};

export type GetCustomerLicensesInLicenseModelByCustomerId400 = {
  [key: string]: unknown;
};

export type GetCustomerLicensesInLicenseModelByCustomerId200 = {
  [key: string]: unknown;
};

export type GetCustomerLicensesInLicenseModelByCustomerIdParams = {
  customerId?: string;
};

export type PutPortLocations400 = { [key: string]: unknown };

export type GetLocations400 = { [key: string]: unknown };

export type GetLocationsParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  search?: string;
};

export type DeleteGroup400 = { [key: string]: unknown };

export type PutGroup400 = { [key: string]: unknown };

export type GetById400 = { [key: string]: unknown };

export type DeletePort400 = { [key: string]: unknown };

export type PutPort400 = { [key: string]: unknown };

export type GetPortById400 = { [key: string]: unknown };

export type DeleteRelease400 = { [key: string]: unknown };

export type DeleteRelease202 = { [key: string]: unknown };

export type PutRelease1400 = { [key: string]: unknown };

export type PutRelease1200 = { [key: string]: unknown };

export type GetRelease400 = { [key: string]: unknown };

export type GetRelease200 = { [key: string]: unknown };

export type PostRelease400 = { [key: string]: unknown };

export type PostRelease200 = { [key: string]: unknown };

export type PutRelease400 = { [key: string]: unknown };

export type PutRelease200 = { [key: string]: unknown };

export type GetAllReleasesPageable400 = { [key: string]: unknown };

export type GetAllReleasesPageable200 = { [key: string]: unknown };

export type GetAllReleasesPageableParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
};

export type CreatePatientProfileRule400 = { [key: string]: unknown };

export type CreatePatientProfileRule200 = { [key: string]: unknown };

export type UpdatePatientProfileRulesSingleUpdate400 = {
  [key: string]: unknown;
};

export type UpdatePatientProfileRulesSingleUpdate200 = {
  [key: string]: unknown;
};

export type GetPatientProfileRulesForConfigId400 = { [key: string]: unknown };

export type GetPatientProfileRulesForConfigId200 = { [key: string]: unknown };

export type DeletePatientProfileRule400 = { [key: string]: unknown };

export type DeletePatientProfileRule200 = { [key: string]: unknown };

export type UpdatePatientProfileRule400 = { [key: string]: unknown };

export type UpdatePatientProfileRule200 = { [key: string]: unknown };

export type GetPatientProfileRules400 = { [key: string]: unknown };

export type GetPatientProfileRules200 = { [key: string]: unknown };

export type ReinstallDeviceProfile400 = { [key: string]: unknown };

export type ReinstallDeviceSoftware400 = { [key: string]: unknown };

export type DeleteUser400 = { [key: string]: unknown };

export type PutUser400 = { [key: string]: unknown };

export type PutUserParams = {
  enabled?: boolean;
  resetPassword?: boolean;
};

export type DeleteWebApp400 = { [key: string]: unknown };

export type PutWebAppDto400 = { [key: string]: unknown };

export type DeleteProfile400 = { [key: string]: unknown };

export type DeleteProfile200 = { [key: string]: unknown };

export type UpdateProfile400 = { [key: string]: unknown };

export type UpdateProfile200 = { [key: string]: unknown };

export type GetProfile400 = { [key: string]: unknown };

export type GetProfile200 = { [key: string]: unknown };

export type CreateNewProfile400 = { [key: string]: unknown };

export type CreateNewProfile200 = { [key: string]: unknown };

export type BulkProfilePropertyUpdate400 = { [key: string]: unknown };

export type BulkProfilePropertyUpdate200 = { [key: string]: unknown };

export type GetAllProfiles400 = { [key: string]: unknown };

export type GetAllProfiles200 = { [key: string]: unknown };

export type GetAllProfilesParams = {
  /**
   * Zero-based page index (0..N)
   */
  page?: number;
  /**
   * The size of the page to be returned
   */
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string[];
  is_patient_profile?: boolean;
  pageable?: boolean;
};

export type PutPropertyTemplatesById400 = { [key: string]: unknown };

export type GetPropertyTemplatesById400 = { [key: string]: unknown };

export type AddPropertyTemplate400 = { [key: string]: unknown };

export type PutProfilePropertyByPath400 = { [key: string]: unknown };

export type PutProfilePropertyByPathParams = {
  propertyPath: string;
};

export type GetPropertyTemplates400 = { [key: string]: unknown };

export type GetPropertyTemplatesParams = {
  path?: string;
};

export type DeletePropertyByIds400 = { [key: string]: unknown };

export type DeletePropertyByIds200 = { [key: string]: unknown };

export type BatchUpdatePropertyById400 = { [key: string]: unknown };

export type BatchUpdatePropertyById200 = { [key: string]: unknown };

export type UpdatePropertyById1400 = { [key: string]: unknown };

export type UpdatePropertyById1200 = { [key: string]: unknown };

export type DeletePropertyById400 = { [key: string]: unknown };

export type DeletePropertyById200 = { [key: string]: unknown };

export type UpdatePropertyById400 = { [key: string]: unknown };

export type UpdatePropertyById200 = { [key: string]: unknown };

export type GetPropertyById400 = { [key: string]: unknown };

export type GetPropertyById200 = { [key: string]: unknown };

export type ExposeDenyError3400 = { [key: string]: unknown };

export type ExposeDenyError1400 = { [key: string]: unknown };

export type ExposeDenyError2400 = { [key: string]: unknown };

export type ExposeDenyError400 = { [key: string]: unknown };

export interface Status {
  description?: string;
  status?: string;
}

export type OverviewResultDtoVersions = { [key: string]: number };

export interface OverviewResultDto {
  customerName?: string;
  distributorName?: string;
  fd?: number;
  greaterOrEqualV3x?: number;
  greaterOrEqualV3xPercent?: number;
  lessThanV3x?: number;
  maxOS?: string;
  minOS?: string;
  pt1?: number;
  sf?: number;
  stb?: number;
  sum?: number;
  versions?: OverviewResultDtoVersions;
  vna?: number;
  ws?: number;
}

export interface PageOverviewResultDto {
  content?: OverviewResultDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface PageFrontendNotificationDto {
  content?: FrontendNotificationDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface PageAnnouncementDto {
  content?: AnnouncementDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface DeviceCertificateDto {
  certificate?: string;
  created?: string;
  createdBy?: string;
  customerId?: string;
  distributorId?: string;
  id?: number;
  issuer?: string;
  modifiedBy?: string;
  subject?: string;
  updated?: string;
  validityNotAfter?: string;
  validityNotBefore?: string;
}

export interface PageDeviceCertificateDto {
  content?: DeviceCertificateDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export type CustomerBridgeDtoBridgeVersion =
  (typeof CustomerBridgeDtoBridgeVersion)[keyof typeof CustomerBridgeDtoBridgeVersion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerBridgeDtoBridgeVersion = {
  OLD: 'OLD',
  NEW: 'NEW',
  UNKNOWN: 'UNKNOWN',
} as const;

export interface CustomerBridgeDto {
  bridgeVersion?: CustomerBridgeDtoBridgeVersion;
  connected?: boolean;
  connectTimestamp?: string;
  created?: string;
  customerId?: string;
  distributorId?: string;
  id?: number;
  name: string;
  updated?: string;
}

export interface CustomerDataDto {
  customerId?: number;
  deviceCount?: number;
  deviceGroupCount?: number;
}

export interface PageCustomerFullDto {
  content?: CustomerFullDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface DeviceCountWrapper {
  deviceCount?: number;
}

export interface PageDeviceType {
  content?: DeviceType[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export type DeviceCommandPriority =
  (typeof DeviceCommandPriority)[keyof typeof DeviceCommandPriority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeviceCommandPriority = {
  LOW: 'LOW',
  NORMAL: 'NORMAL',
  HIGH: 'HIGH',
} as const;

export interface DeviceCommand {
  deviceId?: number;
  id?: number;
  message?: MqttMessage;
  priority?: DeviceCommandPriority;
  sendWhenDeviceLocked?: boolean;
}

export interface FullDeviceEntity {
  backendInstance?: string;
  backendUrl?: string;
  created?: string;
  createdBy?: string;
  customerId?: string;
  customerName?: string;
  dailyJournalCreatedTimestamp?: string;
  deviceDeleted?: boolean;
  deviceDeliveryNoteNumber?: string;
  deviceDisplayLocation?: string;
  deviceGroupDeviceProfileId?: number;
  deviceGroupId?: number;
  deviceGroupName?: string;
  deviceHardwareVersion?: string;
  deviceId?: number;
  deviceIdOld?: number;
  deviceLocked?: boolean;
  deviceNetworkInterfaces?: NetworkInterface[];
  devicePowerSource?: string;
  deviceProfileId?: number;
  deviceProfileName?: string;
  deviceSerialNumber?: string;
  deviceSoftwareRevision?: string;
  deviceSoftwareVersion?: string;
  deviceTestDevice?: boolean;
  deviceTypeDisplayName?: string;
  deviceTypeDisplayPpi?: number;
  deviceTypeDisplayResolutionX?: number;
  deviceTypeDisplayResolutionY?: number;
  deviceTypeDisplaySizeCm?: number;
  deviceTypeDisplaySizeInches?: number;
  deviceTypeId?: number;
  deviceTypeName?: string;
  displayManufacturerName?: string;
  displayProductCode?: string;
  distributorId?: string;
  distributorName?: string;
  dosConnectionType?: string;
  dosLastChangeTimestamp?: string;
  dosLastOnlineStateTimestamp?: string;
  dosOnline?: boolean;
  externalIpAddress?: string;
  externalIpAddressFirstUsage?: string;
  lastPatientLogin?: string;
  lldpDataPort?: string;
  lldpDataSystem?: string;
  lldpPortReported?: string;
  lldpSystemReported?: string;
  logcatCreatedTimestamp?: string;
  macDataMac?: string;
  modifiedBy?: string;
  oneWireDataBed?: string;
  oneWireDataBuilding?: string;
  oneWireDataDepartment?: string;
  oneWireDataRoom?: string;
  oneWireDataSipPassword?: string;
  oneWireDataSipUsername?: string;
  patientLoggedIn?: boolean;
  patientProfileId?: number;
  patientProfileName?: string;
  phoneBootloaderVersion?: string;
  phoneHardwareVersion?: string;
  phoneSoftwareVersion?: string;
  portBed?: string;
  portBuilding?: string;
  portDepartment?: string;
  portGroupDeviceProfileId?: number;
  portGroupId?: number;
  portGroupName?: string;
  portId?: number;
  portPort?: string;
  portRoom?: string;
  portSipPassword?: string;
  portSipUsername?: string;
  sipState?: string;
  updated?: string;
}

export interface DistributorDataDto {
  customersDeviceCount?: number;
  distributorId?: number;
}

export interface PageDevice {
  content?: Device[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface PageKeycloakUserDto {
  content?: KeycloakUserDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface PageDistributor {
  content?: Distributor[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface FeatureNameDTO {
  id?: number;
  name?: string;
}

export interface FeatureDTO {
  created?: string;
  description?: string;
  id?: number;
  name?: string;
  updated?: string;
}

export interface PageFeatureDTO {
  content?: FeatureDTO[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface PageFwProbingResultOverviewDto {
  content?: FwProbingResultOverviewDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export type FwProbingResultOverviewItemDtoCheckType =
  (typeof FwProbingResultOverviewItemDtoCheckType)[keyof typeof FwProbingResultOverviewItemDtoCheckType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewItemDtoCheckType = {
  DM_BACKEND: 'DM_BACKEND',
  DM_AUTH: 'DM_AUTH',
  CMS_CONTENT: 'CMS_CONTENT',
  PAYMENT_PROXY: 'PAYMENT_PROXY',
  UBUNTU_REPOSITORY: 'UBUNTU_REPOSITORY',
  MQTT: 'MQTT',
  S3_ACCESS: 'S3_ACCESS',
  OTHER: 'OTHER',
} as const;

export type FwProbingResultOverviewItemDtoCheckTarget =
  (typeof FwProbingResultOverviewItemDtoCheckTarget)[keyof typeof FwProbingResultOverviewItemDtoCheckTarget];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewItemDtoCheckTarget = {
  TABLET: 'TABLET',
  CONNECT_SERVER: 'CONNECT_SERVER',
} as const;

export interface FwProbingResultOverviewItemDto {
  certCheckPassed?: boolean;
  checkTarget?: FwProbingResultOverviewItemDtoCheckTarget;
  checkType?: FwProbingResultOverviewItemDtoCheckType;
  connectionCheckPassed?: boolean;
  expectedCert?: string;
  expectedStatusCode?: number;
  returnedCert?: string;
  returnedStatusCode?: number;
  statusCodeCheckPassed?: boolean;
  timestamp?: string;
  url?: string;
}

export type FwProbingResultOverviewDtoTabletPaymentProxyStatus =
  (typeof FwProbingResultOverviewDtoTabletPaymentProxyStatus)[keyof typeof FwProbingResultOverviewDtoTabletPaymentProxyStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoTabletPaymentProxyStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoTabletDmStatus =
  (typeof FwProbingResultOverviewDtoTabletDmStatus)[keyof typeof FwProbingResultOverviewDtoTabletDmStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoTabletDmStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoTabletDmAuthStatus =
  (typeof FwProbingResultOverviewDtoTabletDmAuthStatus)[keyof typeof FwProbingResultOverviewDtoTabletDmAuthStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoTabletDmAuthStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoTabletCmsStatus =
  (typeof FwProbingResultOverviewDtoTabletCmsStatus)[keyof typeof FwProbingResultOverviewDtoTabletCmsStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoTabletCmsStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoProbingTrigger =
  (typeof FwProbingResultOverviewDtoProbingTrigger)[keyof typeof FwProbingResultOverviewDtoProbingTrigger];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoProbingTrigger = {
  CRON: 'CRON',
  MANUAL: 'MANUAL',
} as const;

export type FwProbingResultOverviewDtoOverallStatus =
  (typeof FwProbingResultOverviewDtoOverallStatus)[keyof typeof FwProbingResultOverviewDtoOverallStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoOverallStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsUbuntuRepositoryStatus =
  (typeof FwProbingResultOverviewDtoCsUbuntuRepositoryStatus)[keyof typeof FwProbingResultOverviewDtoCsUbuntuRepositoryStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsUbuntuRepositoryStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsS3Status =
  (typeof FwProbingResultOverviewDtoCsS3Status)[keyof typeof FwProbingResultOverviewDtoCsS3Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsS3Status = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsPaymentProxyStatus =
  (typeof FwProbingResultOverviewDtoCsPaymentProxyStatus)[keyof typeof FwProbingResultOverviewDtoCsPaymentProxyStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsPaymentProxyStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsMqttStatus =
  (typeof FwProbingResultOverviewDtoCsMqttStatus)[keyof typeof FwProbingResultOverviewDtoCsMqttStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsMqttStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsDmStatus =
  (typeof FwProbingResultOverviewDtoCsDmStatus)[keyof typeof FwProbingResultOverviewDtoCsDmStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsDmStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsDmAuthStatus =
  (typeof FwProbingResultOverviewDtoCsDmAuthStatus)[keyof typeof FwProbingResultOverviewDtoCsDmAuthStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsDmAuthStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export type FwProbingResultOverviewDtoCsCmsStatus =
  (typeof FwProbingResultOverviewDtoCsCmsStatus)[keyof typeof FwProbingResultOverviewDtoCsCmsStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FwProbingResultOverviewDtoCsCmsStatus = {
  ALL_POSITIVE: 'ALL_POSITIVE',
  PARTIAL_FAILURE: 'PARTIAL_FAILURE',
  ALL_FAILED: 'ALL_FAILED',
  NO_RESULTS: 'NO_RESULTS',
  OUTDATED: 'OUTDATED',
} as const;

export interface FwProbingResultOverviewDeviceDto {
  direct?: FwProbingResultOverviewItemDto[];
  id?: number;
  ip?: string;
  mac?: string;
  model?: string;
  proxy?: FwProbingResultOverviewItemDto[];
  proxyStatus?: string;
  ssh?: boolean;
}

export interface FwProbingResultOverviewDto {
  bridgeName?: string;
  created?: string;
  csCmsStatus?: FwProbingResultOverviewDtoCsCmsStatus;
  csDmAuthStatus?: FwProbingResultOverviewDtoCsDmAuthStatus;
  csDmStatus?: FwProbingResultOverviewDtoCsDmStatus;
  csMqttStatus?: FwProbingResultOverviewDtoCsMqttStatus;
  csPaymentProxyStatus?: FwProbingResultOverviewDtoCsPaymentProxyStatus;
  csS3Status?: FwProbingResultOverviewDtoCsS3Status;
  csUbuntuRepositoryStatus?: FwProbingResultOverviewDtoCsUbuntuRepositoryStatus;
  customerId?: string;
  customerName?: string;
  devices?: FwProbingResultOverviewDeviceDto[];
  distributorId?: string;
  distributorName?: string;
  hasBridge?: boolean;
  id?: number;
  overallStatus?: FwProbingResultOverviewDtoOverallStatus;
  probes?: FwProbingResultOverviewItemDto[];
  probingStartTimestamp?: string;
  probingTrigger?: FwProbingResultOverviewDtoProbingTrigger;
  tabletCmsStatus?: FwProbingResultOverviewDtoTabletCmsStatus;
  tabletDmAuthStatus?: FwProbingResultOverviewDtoTabletDmAuthStatus;
  tabletDmStatus?: FwProbingResultOverviewDtoTabletDmStatus;
  tabletPaymentProxyStatus?: FwProbingResultOverviewDtoTabletPaymentProxyStatus;
  timestamp?: string;
  updated?: string;
}

export interface UserFrontendNotificationDto {
  global?: boolean;
  icon?: string;
  id?: number;
  message?: string;
  pinned?: boolean;
  priority?: string;
  read?: boolean;
  title?: string;
  validFrom?: string;
  validTo?: string;
}

export type HistoryContextDtoPropertiesListItem = { [key: string]: string };

/**
 * @deprecated
 */
export type HistoryContextDtoProperties = { [key: string]: string };

export type HistoryContextDtoContextValue =
  (typeof HistoryContextDtoContextValue)[keyof typeof HistoryContextDtoContextValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HistoryContextDtoContextValue = {
  PROFILE_CLONE: 'PROFILE_CLONE',
  PROFILE_CREATE: 'PROFILE_CREATE',
  PROFILE_DELETE: 'PROFILE_DELETE',
  PROFILE_EDIT: 'PROFILE_EDIT',
  PROFILE_IMPORT: 'PROFILE_IMPORT',
  PROFILE_SCHEMA_CREATE: 'PROFILE_SCHEMA_CREATE',
  PROFILE_UPDATE_CANCEL: 'PROFILE_UPDATE_CANCEL',
  PROFILE_UPDATE_START: 'PROFILE_UPDATE_START',
  PROFILE_ORDER_EDIT: 'PROFILE_ORDER_EDIT',
  PROFILE_PROPERTY_TEMPLATE_ADD: 'PROFILE_PROPERTY_TEMPLATE_ADD',
  PROFILE_PROPERTY_TEMPLATE_UPDATE: 'PROFILE_PROPERTY_TEMPLATE_UPDATE',
  PROFILE_PROPERTY_TEMPLATE_DELETE: 'PROFILE_PROPERTY_TEMPLATE_DELETE',
  PROFILE_PROPERTY_ADD: 'PROFILE_PROPERTY_ADD',
  PROFILE_PROPERTY_UPDATE: 'PROFILE_PROPERTY_UPDATE',
  PROFILE_PROPERTY_DELETE: 'PROFILE_PROPERTY_DELETE',
  CERTIFICATE_CREATE: 'CERTIFICATE_CREATE',
  CERTIFICATE_DELETE: 'CERTIFICATE_DELETE',
  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_DELETE: 'CUSTOMER_DELETE',
  CUSTOMER_EDIT: 'CUSTOMER_EDIT',
  DEVICE_CREATE: 'DEVICE_CREATE',
  DEVICE_DELETE: 'DEVICE_DELETE',
  DEVICE_EDIT: 'DEVICE_EDIT',
  DEVICE_EXPORT: 'DEVICE_EXPORT',
  DEVICE_GROUP_CREATE: 'DEVICE_GROUP_CREATE',
  DEVICE_GROUP_DELETE: 'DEVICE_GROUP_DELETE',
  DEVICE_GROUP_EDIT: 'DEVICE_GROUP_EDIT',
  DEVICE_GROUP_EDIT_DEVICES: 'DEVICE_GROUP_EDIT_DEVICES',
  DEVICE_IMAGE_CREATE: 'DEVICE_IMAGE_CREATE',
  DEVICE_IMAGE_DELETE: 'DEVICE_IMAGE_DELETE',
  DEVICE_IMPORT: 'DEVICE_IMPORT',
  DEVICE_LOCK: 'DEVICE_LOCK',
  DEVICE_REBOOT: 'DEVICE_REBOOT',
  DEVICE_REMOVE_CUSTOMER_ASSOCIATION: 'DEVICE_REMOVE_CUSTOMER_ASSOCIATION',
  DEVICE_TYPE_CREATE: 'DEVICE_TYPE_CREATE',
  DEVICE_TYPE_EDIT: 'DEVICE_TYPE_EDIT',
  DEVICE_UNLOCK: 'DEVICE_UNLOCK',
  DISTRIBUTOR_CREATE: 'DISTRIBUTOR_CREATE',
  DISTRIBUTOR_DELETE: 'DISTRIBUTOR_DELETE',
  DISTRIBUTOR_EDIT: 'DISTRIBUTOR_EDIT',
  LICENSE_MODEL_CUSTOMER_CREATE: 'LICENSE_MODEL_CUSTOMER_CREATE',
  LICENSE_MODEL_CUSTOMER_DELETE: 'LICENSE_MODEL_CUSTOMER_DELETE',
  LICENSE_MODEL_CUSTOMER_EDIT: 'LICENSE_MODEL_CUSTOMER_EDIT',
  PATIENT_PROFILE_RULE_CREATE: 'PATIENT_PROFILE_RULE_CREATE',
  PATIENT_PROFILE_RULE_DELETE: 'PATIENT_PROFILE_RULE_DELETE',
  PATIENT_PROFILE_RULE_EDIT: 'PATIENT_PROFILE_RULE_EDIT',
  PORT_CREATE: 'PORT_CREATE',
  PORT_CUSTOMER_ASSOCIATION: 'PORT_CUSTOMER_ASSOCIATION',
  PORT_DELETE: 'PORT_DELETE',
  PORT_EDIT: 'PORT_EDIT',
  PORT_EXPORT: 'PORT_EXPORT',
  PORT_GROUP_CREATE: 'PORT_GROUP_CREATE',
  PORT_GROUP_DELETE: 'PORT_GROUP_DELETE',
  PORT_GROUP_EDIT: 'PORT_GROUP_EDIT',
  PORT_IMPORT: 'PORT_IMPORT',
  PORT_PROPERTIES_IMPORT: 'PORT_PROPERTIES_IMPORT',
  PUSH_MESSAGE_SENT: 'PUSH_MESSAGE_SENT',
  RELEASE_CREATE: 'RELEASE_CREATE',
  RELEASE_CUSTOMER_EDIT: 'RELEASE_CUSTOMER_EDIT',
  RELEASE_DELETE: 'RELEASE_DELETE',
  RELEASE_EDIT: 'RELEASE_EDIT',
  SOFTWARE_UPDATE_CANCEL: 'SOFTWARE_UPDATE_CANCEL',
  SOFTWARE_UPDATE_SINGLE_DEVICE_RESTART:
    'SOFTWARE_UPDATE_SINGLE_DEVICE_RESTART',
  SOFTWARE_UPDATE_START: 'SOFTWARE_UPDATE_START',
  REINSTALL_SOFTWARE: 'REINSTALL_SOFTWARE',
  REINSTALL_PROFILE: 'REINSTALL_PROFILE',
  PROFILE_PROPERTY_MULTIPLE_DELETE: 'PROFILE_PROPERTY_MULTIPLE_DELETE',
  DEVICE_IMAGE_LOAD_FROM_S3: 'DEVICE_IMAGE_LOAD_FROM_S3',
} as const;

export interface HistoryContextDto {
  contextValue?: HistoryContextDtoContextValue;
  customerId?: string;
  distributorId?: string;
  id?: number;
  /** @deprecated */
  properties?: HistoryContextDtoProperties;
  propertiesList?: HistoryContextDtoPropertiesListItem[];
  timestamp?: string;
  userFirstName?: string;
  userId?: string;
  userLastName?: string;
  username?: string;
}

export type PagePhysicalLocationContentItem =
  | LldpLocation
  | MacLocation
  | OneWireLocation;

export interface PagePhysicalLocation {
  content?: PagePhysicalLocationContentItem[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface DeviceGroupListConfiguration {
  availableColumns?: ColumnDefinition[];
  defaultColumns?: string[];
}

export interface DeviceListConfiguration {
  availableColumns?: ColumnDefinition[];
  defaultColumns?: string[];
}

export interface LocationGroupListConfiguration {
  availableColumns?: ColumnDefinition[];
  defaultColumns?: string[];
}

export interface LocationListConfiguration {
  availableColumns?: ColumnDefinition[];
  defaultColumns?: string[];
}

export type PageObjectContentItem = { [key: string]: unknown };

export interface PageObject {
  content?: PageObjectContentItem[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface PagePort {
  content?: Port[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface ListDeviceDto {
  device_id?: number;
  serial_number?: string;
  state?: ConfigUpdateState;
}

export interface CdUpdateSessionWithDevicesDto {
  beginn_download_time?: string;
  config_id?: number;
  config_update_devices?: ListDeviceDto[];
  id?: number;
  max_concurrent_downloads?: number;
  number_of_devices?: number;
  state?: string;
}

export interface ListUpdateSessionDto {
  beginn_download_time?: string;
  customerId?: string;
  deviceProfileId?: number;
  deviceProfileName?: string;
  distributorId?: string;
  id?: number;
  max_concurrent_downloads?: number;
  number_of_devices?: number;
  state?: string;
  testSession?: boolean;
}

export interface PageListUpdateSessionDto {
  content?: ListUpdateSessionDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export interface StatisticsEntry {
  name?: string;
  value?: number;
}

export interface StatisticsDTO {
  deviceTypes?: StatisticsEntry[];
  onlineOfflineDevices?: StatisticsEntry[];
  softwareVersions?: StatisticsEntry[];
}

export type UpdateSessionDeviceDetailsDtoGroupType =
  (typeof UpdateSessionDeviceDetailsDtoGroupType)[keyof typeof UpdateSessionDeviceDetailsDtoGroupType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateSessionDeviceDetailsDtoGroupType = {
  LOCATION_GROUP: 'LOCATION_GROUP',
  DEVICE_GROUP: 'DEVICE_GROUP',
} as const;

export interface UpdateSessionDeviceDetailsDto {
  device?: Device;
  groupName?: string;
  groupType?: UpdateSessionDeviceDetailsDtoGroupType;
}

export interface SortObject {
  ascending?: boolean;
  direction?: string;
  ignoreCase?: boolean;
  nullHandling?: string;
  property?: string;
}

export interface PageableObject {
  offset?: number;
  paged?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sort?: SortObject[];
  unpaged?: boolean;
}

export interface PageUpdateSessionDto {
  content?: UpdateSessionDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject[];
  totalElements?: number;
  totalPages?: number;
}

export type BridgeDtoBridgeVersion =
  (typeof BridgeDtoBridgeVersion)[keyof typeof BridgeDtoBridgeVersion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BridgeDtoBridgeVersion = {
  OLD: 'OLD',
  NEW: 'NEW',
  UNKNOWN: 'UNKNOWN',
} as const;

export interface BridgeDto {
  bridgeVersion?: BridgeDtoBridgeVersion;
  connected?: boolean;
  connectTimestamp?: string;
  created?: string;
  id?: number;
  name: string;
  updated?: string;
}

export interface MqttMessage {
  duplicate?: boolean;
  message: string;
  retained?: boolean;
  timestamp?: number;
  topic: string;
}

export interface ImportedDevice {
  deviceType?: DeviceType;
  id?: number;
  isNew?: boolean;
  networkInterfaces?: NetworkInterfaceDto[];
}

export interface FwProbingStartResponse {
  bridgeIds?: number[];
  deviceIds?: number[];
  messages?: string[];
  success?: boolean;
}

export interface PushNotification {
  displayDuration?: number;
  iconTag?: string;
  message?: string;
  sender?: string;
  title?: string;
}

export interface PushNotificationList {
  deviceIds?: number[];
  pushNotification?: PushNotification;
}

export interface PortImportResultDto {
  duplicateLocationLines?: number[];
  duplicateLocationsCount?: number;
  duplicatePortLines?: number[];
  duplicatePortsCount?: number;
  importErrorCount?: number;
  newPortsCount?: number;
  skippedLines?: number[];
  totalLines?: number;
  updatedPortsCount?: number;
}

export interface PhoneDto {
  phoneBootloaderVersion?: string;
  phoneHardwareVersion?: string;
  phoneSoftwareVersion?: string;
}

export interface NetworkInterfaceDto {
  interfaceName: string;
  ipAddress?: string;
  macAddress?: string;
}

export interface DisplayDataDto {
  manufacturerName?: string;
  productCode?: string;
  weekOfManufacture?: string;
  yearOfManufacture?: string;
}

export interface DeviceRegisterRequestDto {
  backendInstance?: string;
  backendUrl?: string;
  deviceType?: string;
  displayData?: DisplayDataDto;
  externalIpAddress?: ExternalIpAddress;
  hardwareVersion?: string;
  networkInterfaces?: NetworkInterfaceDto[];
  phone?: PhoneDto;
  powerSource?: string;
  serialNumber?: string;
  softwareRevision?: string;
  softwareVersion?: string;
}

export interface PhysicalLocationDto {
  id?: number;
  lldpData?: LldpData;
  mac?: string;
  oneWireData?: OneWireData;
}

export interface PatientRegisterRequestDto {
  registered?: boolean;
  serialNumber?: string;
}

export type SipStateRegisterRequestDtoSipState =
  (typeof SipStateRegisterRequestDtoSipState)[keyof typeof SipStateRegisterRequestDtoSipState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SipStateRegisterRequestDtoSipState = {
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  REGISTRATION_FAILED: 'REGISTRATION_FAILED',
  REGISTRATION_OK: 'REGISTRATION_OK',
  REGISTRATION_PROGRESS: 'REGISTRATION_PROGRESS',
} as const;

export interface SipStateRegisterRequestDto {
  deviceId?: number;
  sipState: SipStateRegisterRequestDtoSipState;
}

export interface DeviceImageUploadDto {
  deviceFamily: string;
  deviceTypes: string[];
  fileName: string;
  revision: string;
  version: string;
}

export interface BatchConfigUpdateDto {
  profileIds?: number[];
}

export interface StartUpdateDto {
  allDevices?: boolean;
  deviceGroupIds?: number[];
  downloadTime?: string;
  installationTime?: string;
  maxConcurrentDownloads?: number;
  maxConcurrentInstallations?: number;
  portGroupIds?: number[];
  useCanary?: boolean;
  versionId: number;
}

export interface ReleaseNotesDto {
  info?: string;
  releaseNotesName?: string;
  s3BucketName?: string;
  s3Key?: string;
}

export interface ReleaseDto {
  customerWhitelist?: string[];
  deviceFamilies?: string;
  id?: number;
  name?: string;
  releaseDate?: string;
  releaseNotes?: ReleaseNotesDto;
  releaseState?: string;
  revision?: string;
  version?: string;
}

export interface UpdateSessionDto {
  beginDownloadTime?: string;
  beginInstallationTime?: string;
  cancelled?: boolean;
  customerId?: string;
  deviceCount?: number;
  deviceGroupId?: number[];
  downloadComplete?: boolean;
  endDownloadTime?: string;
  endInstallationTime?: string;
  finished?: boolean;
  id?: number;
  installationComplete?: boolean;
  locationGroupId?: number[];
  maxConcurrentDownloads?: number;
  maxConcurrentInstallations?: number;
  release?: ReleaseDto;
  state?: string;
  useCanary?: boolean;
}

export type ProfilePropertyTemplateDtoResponseData = { [key: string]: unknown };

export interface ProfilePropertyTemplateDto {
  parentId?: number;
  responseData?: ProfilePropertyTemplateDtoResponseData;
  templateId?: number;
}

export interface TranslatableMessageDto {
  id?: string;
  locale?: string;
  message?: string;
  title?: string;
}

export interface FrontendNotificationDto {
  customerIds?: number[];
  distributorIds?: number[];
  global?: boolean;
  icon?: string;
  id?: number;
  messageTranslations?: TranslatableMessageDto[];
  pinned?: boolean;
  priority?: string;
  read?: boolean;
  userId?: string;
  validFrom?: string;
  validTo?: string;
}

export type AnnouncementDtoType =
  (typeof AnnouncementDtoType)[keyof typeof AnnouncementDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnouncementDtoType = {
  WARNING: 'WARNING',
  INFO: 'INFO',
} as const;

export interface AnnouncementDto {
  active: boolean;
  allDevices: boolean;
  announcementDeviceGroupsIds?: number[];
  announcementPortGroupsIds?: number[];
  content: string;
  created?: string;
  createdBy?: string;
  customerId?: string;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  pushDesired: boolean;
  subtitle?: string;
  title: string;
  type: AnnouncementDtoType;
  updated?: string;
  validFrom: string;
  validUntil: string;
}

export interface CustomerContactPerson {
  email?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
  mobile?: string;
  notes?: string;
  phone?: string;
  position?: string;
  title?: string;
}

export interface CustomerFullDto {
  address?: PostalAddress;
  created?: string;
  deleted?: boolean;
  deNumber?: string;
  displayName?: string;
  distributorDisplayName?: string;
  distributorId?: number;
  enabled?: boolean;
  id?: number;
  legacyCustomerId?: number;
  licenseModelId?: number;
  name?: string;
  testCustomer?: boolean;
  updated?: string;
}

export interface Customer {
  address?: PostalAddress;
  created?: string;
  deNumber?: string;
  displayName?: string;
  enabled?: boolean;
  id: number;
  legacyCustomerId?: number;
  licenseModelId?: number;
  name?: string;
  testCustomer?: boolean;
  updated?: string;
}

export interface DeviceGroupUpdateDevicesDto {
  deviceIdsToAdd?: number[];
  deviceIdsToDelete?: number[];
}

export interface DistributorContactPerson {
  email?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
  mobile?: string;
  notes?: string;
  phone?: string;
  position?: string;
  title?: string;
}

export interface Distributor {
  address?: PostalAddress;
  created?: string;
  customerCount?: number;
  displayName?: string;
  enabled?: boolean;
  id: number;
  mymedinetId?: number;
  name?: string;
  updated?: string;
}

export interface LicenseModel {
  created?: string;
  id?: number;
  name: string;
  updated?: string;
}

export interface LicenseModelFeature {
  activationOption?: ActivationOption;
  created?: string;
  id?: number;
  licenseModel?: LicenseModel;
  updated?: string;
}

export interface Category {
  created?: string;
  id?: number;
  name: string;
  orderId: number;
  updated?: string;
}

export interface FullFeatureDTO {
  category?: Category;
  created?: string;
  description?: string;
  id?: number;
  licenseModelFeatures?: LicenseModelFeature[];
  name?: string;
  orderId?: number;
  paymentConfigAttribute?: string;
  updated?: string;
}

export interface ActivationOption {
  created?: string;
  id?: number;
  name: string;
  updated?: string;
}

export interface DeviceTableConfig {
  categories?: ColumnCategory[];
  configurationName: string;
  id?: number;
}

export interface ColumnFilterOption {
  displayName: string;
  filterValue: string;
}

export interface ColumnFilter {
  displayName: string;
  filterOptions?: ColumnFilterOption[];
}

export interface ColumnFilterCategory {
  categoryName: string;
  filter?: ColumnFilter[];
}

export interface FilterConfiguration {
  categories?: ColumnFilterCategory[];
  configurationName: string;
  id?: number;
}

export interface ColumnDefinition {
  displayName: string;
  selected: boolean;
  type: string;
  valuePath: string;
}

export interface ColumnCategory {
  categoryName: string;
  columns?: ColumnDefinition[];
}

export interface LocationTableConfiguration {
  categories?: ColumnCategory[];
  configurationName: string;
  id?: number;
}

export interface FeatureNameAndOptionAndActiveAndDescriptionDTO {
  activationOption?: string;
  active?: boolean;
  description?: string;
  name?: string;
  orderId?: number;
}

export interface CategoryNameAndFeatureDTO {
  features?: FeatureNameAndOptionAndActiveAndDescriptionDTO[];
  name?: string;
}

export interface LocationGroupUpdateLocationsDto {
  locationIdsToAdd?: number[];
  locationIdsToDelete?: number[];
}

export type SipStateRegistrationSipState =
  (typeof SipStateRegistrationSipState)[keyof typeof SipStateRegistrationSipState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SipStateRegistrationSipState = {
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  REGISTRATION_FAILED: 'REGISTRATION_FAILED',
  REGISTRATION_OK: 'REGISTRATION_OK',
  REGISTRATION_PROGRESS: 'REGISTRATION_PROGRESS',
} as const;

export interface SipStateRegistration {
  created?: string;
  id?: number;
  sipState?: SipStateRegistrationSipState;
  updated?: string;
}

export type PropertyTemplatePropertyType =
  (typeof PropertyTemplatePropertyType)[keyof typeof PropertyTemplatePropertyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTemplatePropertyType = {
  ARRAY: 'ARRAY',
  BINARY: 'BINARY',
  BOOLEAN: 'BOOLEAN',
  MISSING: 'MISSING',
  NULL: 'NULL',
  NUMBER: 'NUMBER',
  OBJECT: 'OBJECT',
  POJO: 'POJO',
  STRING: 'STRING',
} as const;

export interface PropertyTemplate {
  absolutePath?: string;
  childTemplates?: PropertyTemplate[];
  defaultValue?: JsonNode;
  id?: number;
  isArray?: boolean;
  isArrayObject?: boolean;
  licenses?: Feature[];
  path?: string;
  propertyType?: PropertyTemplatePropertyType;
  validationRegex?: string;
  validationSchema?: JsonNode;
  validatorClass?: string;
}

export interface Property {
  childProperties?: Property[];
  created?: string;
  createdBy?: string;
  customerId?: string;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  overrideInPatientProfile?: boolean;
  propertyTemplate?: PropertyTemplate;
  updated?: string;
  value?: JsonNode;
}

export interface PostalAddress {
  city?: string;
  countryCode?: string;
  postalCode?: string;
  streetAddress?: string;
}

export type PortDtoProperties = { [key: string]: string };

export type PortDtoPhysicalLocation =
  | LldpLocation
  | MacLocation
  | OneWireLocation;

export interface PortDto {
  bed?: string;
  building?: string;
  created?: string;
  createdBy?: string;
  customerId?: string;
  department?: string;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  physicalLocation?: PortDtoPhysicalLocation;
  port?: string;
  properties?: PortDtoProperties;
  room?: string;
  sipPassword?: string;
  sipUsername?: string;
  updated?: string;
}

export type PortPhysicalLocation = LldpLocation | MacLocation | OneWireLocation;

export interface Port {
  bed: string;
  building?: string;
  created?: string;
  createdBy?: string;
  customerId?: string;
  department?: string;
  device?: Device;
  distributorId?: string;
  group?: PortGroup;
  id?: number;
  modifiedBy?: string;
  physicalLocation?: PortPhysicalLocation;
  port?: string;
  properties?: Property[];
  room?: string;
  sipPassword?: string;
  sipUsername?: string;
  updated?: string;
}

export interface PortGroup {
  created?: string;
  createdBy?: string;
  customerId?: string;
  deviceProfileId?: number;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  name?: string;
  portCount?: number;
  ports?: Port[];
  postalAddress?: PostalAddress;
  tags?: string[];
  targetSoftwareVersion?: Release;
  updated?: string;
}

export type PhysicalLocationType =
  (typeof PhysicalLocationType)[keyof typeof PhysicalLocationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalLocationType = {
  LLDP_CDP: 'LLDP_CDP',
  ONE_WIRE: 'ONE_WIRE',
  MAC: 'MAC',
} as const;

export interface PhysicalLocation {
  created?: string;
  createdBy?: string;
  customerId?: string;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  origin: string;
  port?: Port;
  type?: PhysicalLocationType;
  updated?: string;
}

export interface Phone {
  created?: string;
  device?: Device;
  id?: number;
  phoneBootloaderVersion?: string;
  phoneHardwareVersion?: string;
  phoneSoftwareVersion?: string;
  updated?: string;
}

export interface PatientRegistration {
  created?: string;
  id?: number;
  lastLogin?: string;
  lastLogout?: string;
  loggedIn?: boolean;
  updated?: string;
}

export interface OneWireData {
  bed: string;
  building: string;
  department: string;
  room: string;
  sipPassword?: string;
  sipUsername?: string;
}

export type OneWireLocationAllOf = {
  oneWireData?: OneWireData;
};

export type OneWireLocation = PhysicalLocation & OneWireLocationAllOf;

export interface NetworkInterface {
  created?: string;
  id?: number;
  interfaceName: string;
  /** @pattern ^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$ */
  ipAddress: string;
  macAddress: string;
  updated?: string;
}

export type MacLocationAllOf = {
  mac?: string;
};

export type MacLocation = PhysicalLocation & MacLocationAllOf;

export interface Logcat {
  generated?: string;
  s3BucketName?: string;
  s3Key?: string;
}

export interface LldpData {
  port: string;
  system: string;
}

export type LldpLocationAllOf = {
  lldpData?: LldpData;
};

export type LldpLocation = PhysicalLocation & LldpLocationAllOf;

export interface ExternalIpAddress {
  created?: string;
  firstUsage?: string;
  id?: number;
  ipAddress?: string;
  updated?: string;
}

export interface DisplayData {
  displayManufacturerName?: string;
  displayProductCode?: string;
}

export type DeviceUpdateStateState =
  (typeof DeviceUpdateStateState)[keyof typeof DeviceUpdateStateState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeviceUpdateStateState = {
  DOWNLOAD_COMMAND_SENT: 'DOWNLOAD_COMMAND_SENT',
  INSTALLATION_COMMAND_SENT: 'INSTALLATION_COMMAND_SENT',
  DOWNLOADING: 'DOWNLOADING',
  DOWNLOAD_FINISHED: 'DOWNLOAD_FINISHED',
  INSTALLING: 'INSTALLING',
  INSTALLATION_FINISHED: 'INSTALLATION_FINISHED',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR',
  INSTALLATION_ERROR: 'INSTALLATION_ERROR',
  IDLE: 'IDLE',
  CANCELLED: 'CANCELLED',
  NO_IMAGE: 'NO_IMAGE',
  CANCEL_COMMAND_SENT: 'CANCEL_COMMAND_SENT',
  ALREADY_UP_TO_DATE: 'ALREADY_UP_TO_DATE',
  ERROR: 'ERROR',
  AFTER_DOWNLOAD_ERROR: 'AFTER_DOWNLOAD_ERROR',
  DEVICE_LOCKED: 'DEVICE_LOCKED',
  MAX_RETRIES_REACHED: 'MAX_RETRIES_REACHED',
  DOWNLOAD_COMMAND_TIMEOUT: 'DOWNLOAD_COMMAND_TIMEOUT',
} as const;

export interface DeviceUpdateState {
  device?: Device;
  id?: number;
  retryCount: number;
  state: DeviceUpdateStateState;
  statusMessage?: string;
  timestamp: string;
}

export interface DeviceType {
  created?: string;
  deviceFamily?: DeviceFamily;
  displayName?: string;
  displayPpi?: number;
  displayResolutionX?: number;
  displayResolutionY?: number;
  displaySizeCm?: number;
  displaySizeInches?: number;
  id?: number;
  /**
   * @minLength 0
   * @maxLength 256
   */
  name: string;
  oldId?: number;
  updated?: string;
  vintage?: boolean;
  visible?: boolean;
}

export type DeviceOnlineStateConnectionType =
  (typeof DeviceOnlineStateConnectionType)[keyof typeof DeviceOnlineStateConnectionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeviceOnlineStateConnectionType = {
  MQTT: 'MQTT',
  MQTT_DIRECT: 'MQTT_DIRECT',
  MQTT_BRIDGED: 'MQTT_BRIDGED',
  HTTP: 'HTTP',
  OFFLINE: 'OFFLINE',
} as const;

export interface DeviceOnlineState {
  connectionType?: DeviceOnlineStateConnectionType;
  created?: string;
  device: Device;
  id?: number;
  lastChangeTimestamp?: string;
  lastOnlineStateTimestamp?: string;
  online: boolean;
  retainedMessageTopic?: string;
  updated?: string;
}

export interface DeviceMonitoring {
  created?: string;
  dailyJournal?: DailyJournal;
  id?: number;
  logcat?: Logcat;
  updated?: string;
}

export interface DeviceImage {
  checksum?: string;
  checksumAlgorithm?: string;
  created?: string;
  deviceFamily?: string;
  deviceTypes: string[];
  downloadUrl?: string;
  downloadUrlExpirationTimestamp?: string;
  fileExistsInS3Bucket?: boolean;
  id?: number;
  release: Release;
  revision?: string;
  s3Bucket?: string;
  s3Key?: string;
  updated?: string;
  uploadUrl?: string;
  version?: string;
}

export interface DeviceGroup {
  created?: string;
  createdBy?: string;
  customerId?: string;
  deviceCount?: number;
  deviceProfileId?: number;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  name?: string;
  tags?: string[];
  targetSoftwareVersion?: Release;
  updated?: string;
}

export interface DeviceFamily {
  /**
   * @minLength 0
   * @maxLength 256
   */
  codeName: string;
  created?: string;
  deviceTypes?: DeviceType[];
  /**
   * @minLength 0
   * @maxLength 256
   */
  displayName: string;
  id?: number;
  /**
   * @minLength 0
   * @maxLength 256
   */
  name: string;
  updated?: string;
}

export interface Device {
  backendInstance?: string;
  backendUrl?: string;
  bridge?: Bridge;
  configUpdateState?: ConfigUpdateState;
  created?: string;
  createdBy?: string;
  customerId?: string;
  deleted?: boolean;
  deliveryNoteNumber?: string;
  deviceGroup?: DeviceGroup;
  deviceImage?: DeviceImage;
  deviceMonitoring?: DeviceMonitoring;
  deviceOnlineState?: DeviceOnlineState;
  deviceType?: DeviceType;
  displayData?: DisplayData;
  distributorId?: string;
  externalIpAddress?: ExternalIpAddress;
  hardwareVersion?: string;
  id?: number;
  legacyCustomerId?: number;
  lldpData?: LldpData;
  location?: string;
  locked?: boolean;
  modifiedBy?: string;
  networkInterfaces?: NetworkInterface[];
  patientRegistration?: PatientRegistration;
  phone?: Phone;
  port?: Port;
  powerSource?: string;
  /** @pattern ^([0-9A-Fa-f]{2}[:-]?){5,6}([0-9A-Fa-f]{2})$ */
  serialNumber: string;
  sipStateRegistration?: SipStateRegistration;
  softwareRevision?: string;
  softwareVersion?: string;
  testDevice?: boolean;
  updated?: string;
  updateState?: DeviceUpdateState;
}

export interface DailyJournal {
  generated?: string;
  s3BucketName?: string;
  s3Key?: string;
}

export type ConfigUpdateStateConfigUpdateStateState =
  (typeof ConfigUpdateStateConfigUpdateStateState)[keyof typeof ConfigUpdateStateConfigUpdateStateState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConfigUpdateStateConfigUpdateStateState = {
  DOWNLOAD_COMMAND_SENT: 'DOWNLOAD_COMMAND_SENT',
  DOWNLOADING: 'DOWNLOADING',
  DOWNLOAD_FINISHED: 'DOWNLOAD_FINISHED',
  APPLYING: 'APPLYING',
  APPLY_FINISHED: 'APPLY_FINISHED',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR',
  APPLY_ERROR: 'APPLY_ERROR',
  IDLE: 'IDLE',
  CANCELLED: 'CANCELLED',
  CANCEL_COMMAND_SENT: 'CANCEL_COMMAND_SENT',
  NO_CONFIG: 'NO_CONFIG',
} as const;

export interface ConfigUpdateState {
  config_update_state_state?: ConfigUpdateStateConfigUpdateStateState;
  config_update_state_statusmessage?: string;
  config_update_state_timestamp?: string;
}

export type BridgeBridgeVersion =
  (typeof BridgeBridgeVersion)[keyof typeof BridgeBridgeVersion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BridgeBridgeVersion = {
  OLD: 'OLD',
  NEW: 'NEW',
  UNKNOWN: 'UNKNOWN',
} as const;

export interface Bridge {
  bridgeVersion?: BridgeBridgeVersion;
  connected?: boolean;
  connectTimestamp?: string;
  id?: number;
  name?: string;
}

export interface ReleaseNotes {
  info?: string;
  releaseNotesName?: string;
  s3BucketName?: string;
  s3Key?: string;
}

export type ReleaseReleaseState =
  (typeof ReleaseReleaseState)[keyof typeof ReleaseReleaseState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReleaseReleaseState = {
  GLOBAL: 'GLOBAL',
  TESTING: 'TESTING',
  INTERNAL_ONLY: 'INTERNAL_ONLY',
  LOCKED: 'LOCKED',
} as const;

export interface Release {
  created?: string;
  customerWhitelist?: string[];
  deviceFamilies?: string;
  id?: number;
  name?: string;
  releaseDate?: string;
  releaseNotes?: ReleaseNotes;
  releaseState?: ReleaseReleaseState;
  revision?: string;
  updated?: string;
  version?: string;
}

export interface CdOperatorDto {
  id?: number;
  name?: string;
}

export interface CdCriteriaDto {
  id?: number;
  name?: string;
}

export interface CdRuleDto {
  created?: string;
  createdBy?: string;
  criteria: CdCriteriaDto;
  customerId?: string;
  distributorId?: string;
  id?: number;
  modifiedBy?: string;
  operator: CdOperatorDto;
  updated?: string;
  value: string;
}

export interface UpdateCdRulesDto {
  rulesToAdd?: CdRuleDto[];
  rulesToRemove?: CdRuleDto[];
  rulesToUpdate?: CdRuleDto[];
}

export type KeycloakUserDtoAttributes = { [key: string]: string[] };

export interface KeycloakUserDto {
  attributes?: KeycloakUserDtoAttributes;
  customerId?: string;
  customerName?: string;
  distributorId?: string;
  distributorName?: string;
  email: string;
  enabled?: boolean;
  firstName?: string;
  id?: string;
  lastName?: string;
  mobile?: string;
  notes?: string;
  password?: string;
  phone?: string;
  position?: string;
  self?: string;
  username: string;
}

export type WebAppDtoLanguageUrlMap = { [key: string]: string };

export interface WebAppDto {
  customerId?: string;
  displayName: string;
  distributorId?: string;
  id?: number;
  languageUrlMap: WebAppDtoLanguageUrlMap;
}

export interface ProfileDto {
  author?: string;
  cacheDate?: string;
  checkSum?: string;
  created?: string;
  customerId?: string;
  description?: string;
  distributorId?: string;
  id?: number;
  isPatientProfile?: boolean;
  isTestMode?: boolean;
  name?: string;
  order?: number;
  testCacheDate?: string;
  updated?: string;
}

export type UpdateProfilePropertyDtoProperties = { [key: string]: string };

export interface UpdateProfilePropertyDto {
  profileIds: number[];
  properties?: UpdateProfilePropertyDtoProperties;
}

export type PropertyTemplateDtoPropertyType =
  (typeof PropertyTemplateDtoPropertyType)[keyof typeof PropertyTemplateDtoPropertyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTemplateDtoPropertyType = {
  ARRAY: 'ARRAY',
  BINARY: 'BINARY',
  BOOLEAN: 'BOOLEAN',
  MISSING: 'MISSING',
  NULL: 'NULL',
  NUMBER: 'NUMBER',
  OBJECT: 'OBJECT',
  POJO: 'POJO',
  STRING: 'STRING',
} as const;

export interface Feature {
  created?: string;
  createdBy?: string;
  description?: string;
  id?: number;
  modifiedBy?: string;
  name: string;
  orderId: number;
  updated?: string;
}

export interface PropertyTemplateDto {
  absolutePath?: string;
  childTemplates?: PropertyTemplateDto[];
  defaultValue?: string;
  id?: number;
  isArray?: boolean;
  isArrayObject?: boolean;
  licenses?: Feature[];
  parentTemplate?: PropertyTemplateDto;
  path?: string;
  propertyType?: PropertyTemplateDtoPropertyType;
  validationRegex?: string;
  validationSchema?: string;
  validatorClass?: string;
}

export interface JsonNode {
  [key: string]: unknown;
}

export interface PropertyDto {
  childProperties?: PropertyDto[];
  created?: string;
  id?: number;
  overrideInPatientProfile?: boolean;
  parentId?: number;
  path?: string;
  profileId?: number;
  templateId?: number;
  updated?: string;
  value?: JsonNode;
}

export type ErrorEntityHttpStatus =
  (typeof ErrorEntityHttpStatus)[keyof typeof ErrorEntityHttpStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorEntityHttpStatus = {
  '100_CONTINUE': '100 CONTINUE',
  '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
  '102_PROCESSING': '102 PROCESSING',
  '103_EARLY_HINTS': '103 EARLY_HINTS',
  '103_CHECKPOINT': '103 CHECKPOINT',
  '200_OK': '200 OK',
  '201_CREATED': '201 CREATED',
  '202_ACCEPTED': '202 ACCEPTED',
  '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
  '204_NO_CONTENT': '204 NO_CONTENT',
  '205_RESET_CONTENT': '205 RESET_CONTENT',
  '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
  '207_MULTI_STATUS': '207 MULTI_STATUS',
  '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
  '226_IM_USED': '226 IM_USED',
  '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
  '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
  '302_FOUND': '302 FOUND',
  '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
  '303_SEE_OTHER': '303 SEE_OTHER',
  '304_NOT_MODIFIED': '304 NOT_MODIFIED',
  '305_USE_PROXY': '305 USE_PROXY',
  '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
  '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
  '400_BAD_REQUEST': '400 BAD_REQUEST',
  '401_UNAUTHORIZED': '401 UNAUTHORIZED',
  '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
  '403_FORBIDDEN': '403 FORBIDDEN',
  '404_NOT_FOUND': '404 NOT_FOUND',
  '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
  '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
  '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
  '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
  '409_CONFLICT': '409 CONFLICT',
  '410_GONE': '410 GONE',
  '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
  '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
  '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
  '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
  '414_URI_TOO_LONG': '414 URI_TOO_LONG',
  '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
  '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
  '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
  '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
  '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  '420_METHOD_FAILURE': '420 METHOD_FAILURE',
  '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
  '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
  '423_LOCKED': '423 LOCKED',
  '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
  '425_TOO_EARLY': '425 TOO_EARLY',
  '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
  '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
  '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
  '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
  '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
  '502_BAD_GATEWAY': '502 BAD_GATEWAY',
  '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
  '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
  '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
  '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
  '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
  '508_LOOP_DETECTED': '508 LOOP_DETECTED',
  '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
  '510_NOT_EXTENDED': '510 NOT_EXTENDED',
  '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export type ErrorEntityErrors = { [key: string]: string };

export interface ErrorEntity {
  created?: string;
  errorCode?: number;
  errorKey?: string;
  errors?: ErrorEntityErrors;
  httpStatus?: ErrorEntityHttpStatus;
  message?: string;
}

type HttpClientOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: any;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};

@Injectable({ providedIn: 'root' })
export class OpenAPIDefinitionService {
  constructor(private http: HttpClient) {}
  exposeDenyError<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  exposeDenyError<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  exposeDenyError<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  exposeDenyError<TData = ErrorEntity>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/deny`, options);
  }

  exposeDenyError2<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  exposeDenyError2<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  exposeDenyError2<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  exposeDenyError2<TData = ErrorEntity>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/deny`,
      undefined,
      options,
    );
  }

  exposeDenyError1<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  exposeDenyError1<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  exposeDenyError1<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  exposeDenyError1<TData = ErrorEntity>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/deny`,
      undefined,
      options,
    );
  }

  exposeDenyError3<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  exposeDenyError3<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  exposeDenyError3<TData = ErrorEntity>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  exposeDenyError3<TData = ErrorEntity>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(`${environment.apiUrl}/deny`, options);
  }

  getPropertyById<TData = GetPropertyById200>(
    propertyId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPropertyById<TData = GetPropertyById200>(
    propertyId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPropertyById<TData = GetPropertyById200>(
    propertyId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPropertyById<TData = GetPropertyById200>(
    propertyId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/properties/${propertyId}`,
      options,
    );
  }

  updatePropertyById<TData = UpdatePropertyById200>(
    propertyId: number,
    propertyDto: PropertyDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updatePropertyById<TData = UpdatePropertyById200>(
    propertyId: number,
    propertyDto: PropertyDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updatePropertyById<TData = UpdatePropertyById200>(
    propertyId: number,
    propertyDto: PropertyDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updatePropertyById<TData = UpdatePropertyById200>(
    propertyId: number,
    propertyDto: PropertyDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/properties/${propertyId}`,
      propertyDto,
      options,
    );
  }

  deletePropertyById<TData = DeletePropertyById200>(
    propertyId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deletePropertyById<TData = DeletePropertyById200>(
    propertyId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deletePropertyById<TData = DeletePropertyById200>(
    propertyId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deletePropertyById<TData = DeletePropertyById200>(
    propertyId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v2/properties/${propertyId}`,
      options,
    );
  }

  updatePropertyById1<TData = UpdatePropertyById1200>(
    propertyId: number,
    jsonNode: JsonNode,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updatePropertyById1<TData = UpdatePropertyById1200>(
    propertyId: number,
    jsonNode: JsonNode,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updatePropertyById1<TData = UpdatePropertyById1200>(
    propertyId: number,
    jsonNode: JsonNode,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updatePropertyById1<TData = UpdatePropertyById1200>(
    propertyId: number,
    jsonNode: JsonNode,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/properties/${propertyId}/value`,
      jsonNode,
      options,
    );
  }

  batchUpdatePropertyById<TData = BatchUpdatePropertyById200>(
    propertyDto: PropertyDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  batchUpdatePropertyById<TData = BatchUpdatePropertyById200>(
    propertyDto: PropertyDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  batchUpdatePropertyById<TData = BatchUpdatePropertyById200>(
    propertyDto: PropertyDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  batchUpdatePropertyById<TData = BatchUpdatePropertyById200>(
    propertyDto: PropertyDto[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/properties/batch`,
      propertyDto,
      options,
    );
  }

  deletePropertyByIds<TData = DeletePropertyByIds200>(
    deletePropertyByIdsBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deletePropertyByIds<TData = DeletePropertyByIds200>(
    deletePropertyByIdsBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deletePropertyByIds<TData = DeletePropertyByIds200>(
    deletePropertyByIdsBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deletePropertyByIds<TData = DeletePropertyByIds200>(
    deletePropertyByIdsBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v2/properties/batch`,
      { body: deletePropertyByIdsBody, ...options },
    );
  }

  getPropertyTemplates<TData = PropertyTemplateDto>(
    params?: GetPropertyTemplatesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPropertyTemplates<TData = PropertyTemplateDto>(
    params?: GetPropertyTemplatesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPropertyTemplates<TData = PropertyTemplateDto>(
    params?: GetPropertyTemplatesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPropertyTemplates<TData = PropertyTemplateDto>(
    params?: GetPropertyTemplatesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/profiletemplates`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  putProfilePropertyByPath<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    params: PutProfilePropertyByPathParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putProfilePropertyByPath<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    params: PutProfilePropertyByPathParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putProfilePropertyByPath<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    params: PutProfilePropertyByPathParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putProfilePropertyByPath<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    params: PutProfilePropertyByPathParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/profiletemplates`,
      propertyTemplateDto,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  addPropertyTemplate<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  addPropertyTemplate<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  addPropertyTemplate<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  addPropertyTemplate<TData = PropertyTemplateDto>(
    propertyTemplateDto: PropertyTemplateDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiletemplates`,
      propertyTemplateDto,
      options,
    );
  }

  getPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/profiletemplates/${templateId}`,
      options,
    );
  }

  putPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    propertyTemplateDto: PropertyTemplateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    propertyTemplateDto: PropertyTemplateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    propertyTemplateDto: PropertyTemplateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putPropertyTemplatesById<TData = PropertyTemplateDto>(
    templateId: number,
    propertyTemplateDto: PropertyTemplateDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/profiletemplates/${templateId}`,
      propertyTemplateDto,
      options,
    );
  }

  getAllProfiles<TData = GetAllProfiles200>(
    params?: GetAllProfilesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllProfiles<TData = GetAllProfiles200>(
    params?: GetAllProfilesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllProfiles<TData = GetAllProfiles200>(
    params?: GetAllProfilesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllProfiles<TData = GetAllProfiles200>(
    params?: GetAllProfilesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v2/profiles`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  bulkProfilePropertyUpdate<TData = BulkProfilePropertyUpdate200>(
    updateProfilePropertyDto: UpdateProfilePropertyDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  bulkProfilePropertyUpdate<TData = BulkProfilePropertyUpdate200>(
    updateProfilePropertyDto: UpdateProfilePropertyDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  bulkProfilePropertyUpdate<TData = BulkProfilePropertyUpdate200>(
    updateProfilePropertyDto: UpdateProfilePropertyDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  bulkProfilePropertyUpdate<TData = BulkProfilePropertyUpdate200>(
    updateProfilePropertyDto: UpdateProfilePropertyDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/profiles`,
      updateProfilePropertyDto,
      options,
    );
  }

  createNewProfile<TData = CreateNewProfile200>(
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createNewProfile<TData = CreateNewProfile200>(
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createNewProfile<TData = CreateNewProfile200>(
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createNewProfile<TData = CreateNewProfile200>(
    profileDto: ProfileDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles`,
      profileDto,
      options,
    );
  }

  getProfile<TData = GetProfile200>(
    profileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getProfile<TData = GetProfile200>(
    profileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getProfile<TData = GetProfile200>(
    profileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getProfile<TData = GetProfile200>(
    profileId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}`,
      options,
    );
  }

  updateProfile<TData = UpdateProfile200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateProfile<TData = UpdateProfile200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateProfile<TData = UpdateProfile200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateProfile<TData = UpdateProfile200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}`,
      profileDto,
      options,
    );
  }

  deleteProfile<TData = DeleteProfile200>(
    profileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteProfile<TData = DeleteProfile200>(
    profileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteProfile<TData = DeleteProfile200>(
    profileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteProfile<TData = DeleteProfile200>(
    profileId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}`,
      options,
    );
  }

  putWebAppDto<TData = WebAppDto>(
    id: number,
    webAppDto: WebAppDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putWebAppDto<TData = WebAppDto>(
    id: number,
    webAppDto: WebAppDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putWebAppDto<TData = WebAppDto>(
    id: number,
    webAppDto: WebAppDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putWebAppDto<TData = WebAppDto>(
    id: number,
    webAppDto: WebAppDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/webapps/${id}`,
      webAppDto,
      options,
    );
  }

  deleteWebApp<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteWebApp<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteWebApp<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteWebApp<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/webapps/${id}`,
      options,
    );
  }

  putUser<TData = void>(
    id: string,
    keycloakUserDto: KeycloakUserDto,
    params?: PutUserParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putUser<TData = void>(
    id: string,
    keycloakUserDto: KeycloakUserDto,
    params?: PutUserParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putUser<TData = void>(
    id: string,
    keycloakUserDto: KeycloakUserDto,
    params?: PutUserParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putUser<TData = void>(
    id: string,
    keycloakUserDto: KeycloakUserDto,
    params?: PutUserParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/users/${id}`,
      keycloakUserDto,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  deleteUser<TData = void>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteUser<TData = void>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteUser<TData = void>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteUser<TData = void>(
    id: string,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/users/${id}`,
      options,
    );
  }

  reinstallDeviceSoftware<TData = void>(
    reinstallDeviceSoftwareBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  reinstallDeviceSoftware<TData = void>(
    reinstallDeviceSoftwareBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  reinstallDeviceSoftware<TData = void>(
    reinstallDeviceSoftwareBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  reinstallDeviceSoftware<TData = void>(
    reinstallDeviceSoftwareBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/devices/reinstall`,
      reinstallDeviceSoftwareBody,
      options,
    );
  }

  reinstallDeviceProfile<TData = void>(
    reinstallDeviceProfileBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  reinstallDeviceProfile<TData = void>(
    reinstallDeviceProfileBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  reinstallDeviceProfile<TData = void>(
    reinstallDeviceProfileBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  reinstallDeviceProfile<TData = void>(
    reinstallDeviceProfileBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/sessions/devices/reinstall`,
      reinstallDeviceProfileBody,
      options,
    );
  }

  getPatientProfileRules<TData = GetPatientProfileRules200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPatientProfileRules<TData = GetPatientProfileRules200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPatientProfileRules<TData = GetPatientProfileRules200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPatientProfileRules<TData = GetPatientProfileRules200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/rules/${id}`,
      options,
    );
  }

  updatePatientProfileRule<TData = UpdatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updatePatientProfileRule<TData = UpdatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updatePatientProfileRule<TData = UpdatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updatePatientProfileRule<TData = UpdatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/rules/${id}`,
      cdRuleDto,
      options,
    );
  }

  deletePatientProfileRule<TData = DeletePatientProfileRule200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deletePatientProfileRule<TData = DeletePatientProfileRule200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deletePatientProfileRule<TData = DeletePatientProfileRule200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deletePatientProfileRule<TData = DeletePatientProfileRule200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/rules/${id}`,
      options,
    );
  }

  getPatientProfileRulesForConfigId<
    TData = GetPatientProfileRulesForConfigId200,
  >(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPatientProfileRulesForConfigId<
    TData = GetPatientProfileRulesForConfigId200,
  >(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPatientProfileRulesForConfigId<
    TData = GetPatientProfileRulesForConfigId200,
  >(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPatientProfileRulesForConfigId<
    TData = GetPatientProfileRulesForConfigId200,
  >(id: number, options?: HttpClientOptions): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/rules/config/${id}`,
      options,
    );
  }

  updatePatientProfileRulesSingleUpdate<
    TData = UpdatePatientProfileRulesSingleUpdate200,
  >(
    id: number,
    updateCdRulesDto: UpdateCdRulesDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updatePatientProfileRulesSingleUpdate<
    TData = UpdatePatientProfileRulesSingleUpdate200,
  >(
    id: number,
    updateCdRulesDto: UpdateCdRulesDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updatePatientProfileRulesSingleUpdate<
    TData = UpdatePatientProfileRulesSingleUpdate200,
  >(
    id: number,
    updateCdRulesDto: UpdateCdRulesDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updatePatientProfileRulesSingleUpdate<
    TData = UpdatePatientProfileRulesSingleUpdate200,
  >(
    id: number,
    updateCdRulesDto: UpdateCdRulesDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/rules/config/${id}`,
      updateCdRulesDto,
      options,
    );
  }

  createPatientProfileRule<TData = CreatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createPatientProfileRule<TData = CreatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createPatientProfileRule<TData = CreatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createPatientProfileRule<TData = CreatePatientProfileRule200>(
    id: number,
    cdRuleDto: CdRuleDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/rules/config/${id}`,
      cdRuleDto,
      options,
    );
  }

  getAllReleasesPageable<TData = GetAllReleasesPageable200>(
    params?: GetAllReleasesPageableParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllReleasesPageable<TData = GetAllReleasesPageable200>(
    params?: GetAllReleasesPageableParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllReleasesPageable<TData = GetAllReleasesPageable200>(
    params?: GetAllReleasesPageableParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllReleasesPageable<TData = GetAllReleasesPageable200>(
    params?: GetAllReleasesPageableParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/releases`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  putRelease<TData = PutRelease200>(
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putRelease<TData = PutRelease200>(
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putRelease<TData = PutRelease200>(
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putRelease<TData = PutRelease200>(
    release: Release,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/releases`,
      release,
      options,
    );
  }

  postRelease<TData = PostRelease200>(
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postRelease<TData = PostRelease200>(
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postRelease<TData = PostRelease200>(
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postRelease<TData = PostRelease200>(
    release: Release,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/releases`,
      release,
      options,
    );
  }

  getRelease<TData = GetRelease200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getRelease<TData = GetRelease200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getRelease<TData = GetRelease200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getRelease<TData = GetRelease200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/releases/${id}`,
      options,
    );
  }

  putRelease1<TData = PutRelease1200>(
    id: number,
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putRelease1<TData = PutRelease1200>(
    id: number,
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putRelease1<TData = PutRelease1200>(
    id: number,
    release: Release,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putRelease1<TData = PutRelease1200>(
    id: number,
    release: Release,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/releases/${id}`,
      release,
      options,
    );
  }

  deleteRelease<TData = DeleteRelease202>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteRelease<TData = DeleteRelease202>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteRelease<TData = DeleteRelease202>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteRelease<TData = DeleteRelease202>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/releases/${id}`,
      options,
    );
  }

  getPortById<TData = PortDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPortById<TData = PortDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPortById<TData = PortDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPortById<TData = PortDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/ports/${id}`,
      options,
    );
  }

  putPort<TData = PortDto>(
    id: number,
    portDto: PortDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putPort<TData = PortDto>(
    id: number,
    portDto: PortDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putPort<TData = PortDto>(
    id: number,
    portDto: PortDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putPort<TData = PortDto>(
    id: number,
    portDto: PortDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/ports/${id}`,
      portDto,
      options,
    );
  }

  deletePort<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deletePort<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deletePort<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deletePort<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/ports/${id}`,
      options,
    );
  }

  getById<TData = PortGroup>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getById<TData = PortGroup>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getById<TData = PortGroup>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getById<TData = PortGroup>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/port-groups/${id}`,
      options,
    );
  }

  putGroup<TData = PortGroup>(
    id: number,
    portGroup: PortGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putGroup<TData = PortGroup>(
    id: number,
    portGroup: PortGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putGroup<TData = PortGroup>(
    id: number,
    portGroup: PortGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putGroup<TData = PortGroup>(
    id: number,
    portGroup: PortGroup,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/port-groups/${id}`,
      portGroup,
      options,
    );
  }

  deleteGroup<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteGroup<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteGroup<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteGroup<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/port-groups/${id}`,
      options,
    );
  }

  getLocations<TData = PagePort>(
    id: number,
    params?: GetLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLocations<TData = PagePort>(
    id: number,
    params?: GetLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLocations<TData = PagePort>(
    id: number,
    params?: GetLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLocations<TData = PagePort>(
    id: number,
    params?: GetLocationsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/port-groups/${id}/locations`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  putPortLocations<TData = void>(
    id: number,
    locationGroupUpdateLocationsDto: LocationGroupUpdateLocationsDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putPortLocations<TData = void>(
    id: number,
    locationGroupUpdateLocationsDto: LocationGroupUpdateLocationsDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putPortLocations<TData = void>(
    id: number,
    locationGroupUpdateLocationsDto: LocationGroupUpdateLocationsDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putPortLocations<TData = void>(
    id: number,
    locationGroupUpdateLocationsDto: LocationGroupUpdateLocationsDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/port-groups/${id}/locations`,
      locationGroupUpdateLocationsDto,
      options,
    );
  }

  getCustomerLicensesInLicenseModelByCustomerId<
    TData = GetCustomerLicensesInLicenseModelByCustomerId200,
  >(
    params?: GetCustomerLicensesInLicenseModelByCustomerIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomerLicensesInLicenseModelByCustomerId<
    TData = GetCustomerLicensesInLicenseModelByCustomerId200,
  >(
    params?: GetCustomerLicensesInLicenseModelByCustomerIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomerLicensesInLicenseModelByCustomerId<
    TData = GetCustomerLicensesInLicenseModelByCustomerId200,
  >(
    params?: GetCustomerLicensesInLicenseModelByCustomerIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomerLicensesInLicenseModelByCustomerId<
    TData = GetCustomerLicensesInLicenseModelByCustomerId200,
  >(
    params?: GetCustomerLicensesInLicenseModelByCustomerIdParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/models/customer`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  updateLicenseModelDataForCustomer<
    TData = UpdateLicenseModelDataForCustomer200,
  >(
    categoryNameAndFeatureDTO: CategoryNameAndFeatureDTO[],
    params?: UpdateLicenseModelDataForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateLicenseModelDataForCustomer<
    TData = UpdateLicenseModelDataForCustomer200,
  >(
    categoryNameAndFeatureDTO: CategoryNameAndFeatureDTO[],
    params?: UpdateLicenseModelDataForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateLicenseModelDataForCustomer<
    TData = UpdateLicenseModelDataForCustomer200,
  >(
    categoryNameAndFeatureDTO: CategoryNameAndFeatureDTO[],
    params?: UpdateLicenseModelDataForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateLicenseModelDataForCustomer<
    TData = UpdateLicenseModelDataForCustomer200,
  >(
    categoryNameAndFeatureDTO: CategoryNameAndFeatureDTO[],
    params?: UpdateLicenseModelDataForCustomerParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/models/customer`,
      categoryNameAndFeatureDTO,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  createLicenseModelData<TData = CreateLicenseModelData200>(
    params: CreateLicenseModelDataParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createLicenseModelData<TData = CreateLicenseModelData200>(
    params: CreateLicenseModelDataParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createLicenseModelData<TData = CreateLicenseModelData200>(
    params: CreateLicenseModelDataParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createLicenseModelData<TData = CreateLicenseModelData200>(
    params: CreateLicenseModelDataParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/models/customer`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  deleteLicenseModelDataForCustomer<
    TData = DeleteLicenseModelDataForCustomer200,
  >(
    params: DeleteLicenseModelDataForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteLicenseModelDataForCustomer<
    TData = DeleteLicenseModelDataForCustomer200,
  >(
    params: DeleteLicenseModelDataForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteLicenseModelDataForCustomer<
    TData = DeleteLicenseModelDataForCustomer200,
  >(
    params: DeleteLicenseModelDataForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteLicenseModelDataForCustomer<
    TData = DeleteLicenseModelDataForCustomer200,
  >(
    params: DeleteLicenseModelDataForCustomerParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/models/customer`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/locations/${id}`,
      options,
    );
  }

  updateTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    locationTableConfiguration: LocationTableConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    locationTableConfiguration: LocationTableConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    locationTableConfiguration: LocationTableConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateTableConfigurations<TData = LocationTableConfiguration>(
    id: number,
    locationTableConfiguration: LocationTableConfiguration,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/locations/${id}`,
      locationTableConfiguration,
      options,
    );
  }

  getFilterCategory<TData = FilterConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getFilterCategory<TData = FilterConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getFilterCategory<TData = FilterConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getFilterCategory<TData = FilterConfiguration>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/filters/${id}`,
      options,
    );
  }

  createFilterCategory<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createFilterCategory<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createFilterCategory<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createFilterCategory<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/filters/${id}`,
      filterConfiguration,
      options,
    );
  }

  getFilterCategory1<TData = FilterConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getFilterCategory1<TData = FilterConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getFilterCategory1<TData = FilterConfiguration>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getFilterCategory1<TData = FilterConfiguration>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/filters/${id}`,
      options,
    );
  }

  createFilterCategory1<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createFilterCategory1<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createFilterCategory1<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createFilterCategory1<TData = FilterConfiguration>(
    id: number,
    filterConfiguration: FilterConfiguration,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/filters/${id}`,
      filterConfiguration,
      options,
    );
  }

  deleteDeviceFilterConfiguration<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDeviceFilterConfiguration<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDeviceFilterConfiguration<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDeviceFilterConfiguration<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/filters/${id}`,
      options,
    );
  }

  getDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/devices/${id}`,
      options,
    );
  }

  updateDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    deviceTableConfig: DeviceTableConfig,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    deviceTableConfig: DeviceTableConfig,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    deviceTableConfig: DeviceTableConfig,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateDeviceTableConfigurations<TData = DeviceTableConfig>(
    id: number,
    deviceTableConfig: DeviceTableConfig,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/devices/${id}`,
      deviceTableConfig,
      options,
    );
  }

  deleteDeviceTableConfiguration<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDeviceTableConfiguration<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDeviceTableConfiguration<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDeviceTableConfiguration<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/devices/${id}`,
      options,
    );
  }

  putReadStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putReadStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putReadStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putReadStatusNotificationById<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/frontend/notifications/${id}/read`,
      undefined,
      options,
    );
  }

  deleteReadStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteReadStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteReadStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteReadStatusNotificationById<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/frontend/notifications/${id}/read`,
      options,
    );
  }

  putPinStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putPinStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putPinStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putPinStatusNotificationById<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/frontend/notifications/${id}/pin`,
      undefined,
      options,
    );
  }

  deletePinStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deletePinStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deletePinStatusNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deletePinStatusNotificationById<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/frontend/notifications/${id}/pin`,
      options,
    );
  }

  getFeature<TData = FullFeatureDTO>(
    featureId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getFeature<TData = FullFeatureDTO>(
    featureId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getFeature<TData = FullFeatureDTO>(
    featureId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getFeature<TData = FullFeatureDTO>(
    featureId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/features/${featureId}`,
      options,
    );
  }

  updateFeature<TData = FullFeatureDTO>(
    featureId: number,
    fullFeatureDTO: FullFeatureDTO,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateFeature<TData = FullFeatureDTO>(
    featureId: number,
    fullFeatureDTO: FullFeatureDTO,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateFeature<TData = FullFeatureDTO>(
    featureId: number,
    fullFeatureDTO: FullFeatureDTO,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateFeature<TData = FullFeatureDTO>(
    featureId: number,
    fullFeatureDTO: FullFeatureDTO,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/features/${featureId}`,
      fullFeatureDTO,
      options,
    );
  }

  deleteFeature<TData = void>(
    featureId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteFeature<TData = void>(
    featureId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteFeature<TData = void>(
    featureId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteFeature<TData = void>(
    featureId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/features/${featureId}`,
      options,
    );
  }

  getDistributor<TData = Distributor>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDistributor<TData = Distributor>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDistributor<TData = Distributor>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDistributor<TData = Distributor>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}`,
      options,
    );
  }

  putDistributor<TData = Distributor>(
    id: number,
    distributor: Distributor,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDistributor<TData = Distributor>(
    id: number,
    distributor: Distributor,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDistributor<TData = Distributor>(
    id: number,
    distributor: Distributor,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDistributor<TData = Distributor>(
    id: number,
    distributor: Distributor,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}`,
      distributor,
      options,
    );
  }

  deleteDistributor<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDistributor<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDistributor<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDistributor<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}`,
      options,
    );
  }

  getDevicesForDistributor<TData = PageDevice>(
    id: string,
    params?: GetDevicesForDistributorParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDevicesForDistributor<TData = PageDevice>(
    id: string,
    params?: GetDevicesForDistributorParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDevicesForDistributor<TData = PageDevice>(
    id: string,
    params?: GetDevicesForDistributorParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDevicesForDistributor<TData = PageDevice>(
    id: string,
    params?: GetDevicesForDistributorParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/devices`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  associateDevicesToDistributor<TData = void>(
    id: string,
    associateDevicesToDistributorBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  associateDevicesToDistributor<TData = void>(
    id: string,
    associateDevicesToDistributorBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  associateDevicesToDistributor<TData = void>(
    id: string,
    associateDevicesToDistributorBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  associateDevicesToDistributor<TData = void>(
    id: string,
    associateDevicesToDistributorBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/devices`,
      associateDevicesToDistributorBody,
      options,
    );
  }

  putContactForDistributor<TData = PutContactForDistributor200>(
    distributorId: number,
    contactId: number,
    distributorContactPerson: DistributorContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putContactForDistributor<TData = PutContactForDistributor200>(
    distributorId: number,
    contactId: number,
    distributorContactPerson: DistributorContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putContactForDistributor<TData = PutContactForDistributor200>(
    distributorId: number,
    contactId: number,
    distributorContactPerson: DistributorContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putContactForDistributor<TData = PutContactForDistributor200>(
    distributorId: number,
    contactId: number,
    distributorContactPerson: DistributorContactPerson,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/distributors/${distributorId}/contacts/${contactId}`,
      distributorContactPerson,
      options,
    );
  }

  deleteContactForDistributor<TData = void>(
    distributorId: number,
    contactId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteContactForDistributor<TData = void>(
    distributorId: number,
    contactId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteContactForDistributor<TData = void>(
    distributorId: number,
    contactId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteContactForDistributor<TData = void>(
    distributorId: number,
    contactId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/distributors/${distributorId}/contacts/${contactId}`,
      options,
    );
  }

  getDevice<TData = GetDevice200>(
    id: number,
    params?: GetDeviceParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDevice<TData = GetDevice200>(
    id: number,
    params?: GetDeviceParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDevice<TData = GetDevice200>(
    id: number,
    params?: GetDeviceParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDevice<TData = GetDevice200>(
    id: number,
    params?: GetDeviceParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/devices/${id}`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  putDevice<TData = Device>(
    id: number,
    device: Device,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDevice<TData = Device>(
    id: number,
    device: Device,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDevice<TData = Device>(
    id: number,
    device: Device,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDevice<TData = Device>(
    id: number,
    device: Device,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/devices/${id}`,
      device,
      options,
    );
  }

  deleteDevice<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDevice<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDevice<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDevice<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/devices/${id}`,
      options,
    );
  }

  putDeviceTestStatus<TData = void>(
    putDeviceTestStatusBody: number[],
    params: PutDeviceTestStatusParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDeviceTestStatus<TData = void>(
    putDeviceTestStatusBody: number[],
    params: PutDeviceTestStatusParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDeviceTestStatus<TData = void>(
    putDeviceTestStatusBody: number[],
    params: PutDeviceTestStatusParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDeviceTestStatus<TData = void>(
    putDeviceTestStatusBody: number[],
    params: PutDeviceTestStatusParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/devices/test`,
      putDeviceTestStatusBody,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  revokeCustomerAssociation<TData = void>(
    revokeCustomerAssociationBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  revokeCustomerAssociation<TData = void>(
    revokeCustomerAssociationBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  revokeCustomerAssociation<TData = void>(
    revokeCustomerAssociationBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  revokeCustomerAssociation<TData = void>(
    revokeCustomerAssociationBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/devices/revoke`,
      revokeCustomerAssociationBody,
      options,
    );
  }

  putDeviceLock<TData = void>(
    putDeviceLockBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDeviceLock<TData = void>(
    putDeviceLockBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDeviceLock<TData = void>(
    putDeviceLockBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDeviceLock<TData = void>(
    putDeviceLockBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/devices/lock`,
      putDeviceLockBody,
      options,
    );
  }

  deleteDeviceLock<TData = void>(
    deleteDeviceLockBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDeviceLock<TData = void>(
    deleteDeviceLockBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDeviceLock<TData = void>(
    deleteDeviceLockBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDeviceLock<TData = void>(
    deleteDeviceLockBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/devices/lock`,
      { body: deleteDeviceLockBody, ...options },
    );
  }

  getDeviceTypeById<TData = DeviceType>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceTypeById<TData = DeviceType>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceTypeById<TData = DeviceType>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceTypeById<TData = DeviceType>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-types/${id}`,
      options,
    );
  }

  putDeviceType<TData = DeviceType>(
    id: number,
    deviceType: DeviceType,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDeviceType<TData = DeviceType>(
    id: number,
    deviceType: DeviceType,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDeviceType<TData = DeviceType>(
    id: number,
    deviceType: DeviceType,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDeviceType<TData = DeviceType>(
    id: number,
    deviceType: DeviceType,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/device-types/${id}`,
      deviceType,
      options,
    );
  }

  putDeviceImage<TData = PutDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDeviceImage<TData = PutDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDeviceImage<TData = PutDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDeviceImage<TData = PutDeviceImage200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/device-images/${id}/upload`,
      undefined,
      options,
    );
  }

  getDeviceById<TData = GetDeviceById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceById<TData = GetDeviceById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceById<TData = GetDeviceById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceById<TData = GetDeviceById200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-groups/${id}`,
      options,
    );
  }

  putDeviceGroup<TData = DeviceGroup>(
    id: number,
    deviceGroup: DeviceGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDeviceGroup<TData = DeviceGroup>(
    id: number,
    deviceGroup: DeviceGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDeviceGroup<TData = DeviceGroup>(
    id: number,
    deviceGroup: DeviceGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDeviceGroup<TData = DeviceGroup>(
    id: number,
    deviceGroup: DeviceGroup,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/device-groups/${id}`,
      deviceGroup,
      options,
    );
  }

  deleteDeviceGroup<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDeviceGroup<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDeviceGroup<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDeviceGroup<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/device-groups/${id}`,
      options,
    );
  }

  getGroupDevices<TData = PageDevice>(
    id: number,
    params?: GetGroupDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getGroupDevices<TData = PageDevice>(
    id: number,
    params?: GetGroupDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getGroupDevices<TData = PageDevice>(
    id: number,
    params?: GetGroupDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getGroupDevices<TData = PageDevice>(
    id: number,
    params?: GetGroupDevicesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-groups/${id}/devices`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  putDevices<TData = void>(
    id: number,
    deviceGroupUpdateDevicesDto: DeviceGroupUpdateDevicesDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDevices<TData = void>(
    id: number,
    deviceGroupUpdateDevicesDto: DeviceGroupUpdateDevicesDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDevices<TData = void>(
    id: number,
    deviceGroupUpdateDevicesDto: DeviceGroupUpdateDevicesDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDevices<TData = void>(
    id: number,
    deviceGroupUpdateDevicesDto: DeviceGroupUpdateDevicesDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/device-groups/${id}/devices`,
      deviceGroupUpdateDevicesDto,
      options,
    );
  }

  getCustomer<TData = CustomerFullDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomer<TData = CustomerFullDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomer<TData = CustomerFullDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomer<TData = CustomerFullDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}`,
      options,
    );
  }

  putCustomer<TData = CustomerFullDto>(
    id: number,
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putCustomer<TData = CustomerFullDto>(
    id: number,
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putCustomer<TData = CustomerFullDto>(
    id: number,
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putCustomer<TData = CustomerFullDto>(
    id: number,
    customer: Customer,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}`,
      customer,
      options,
    );
  }

  deleteCustomer<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteCustomer<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteCustomer<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteCustomer<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}`,
      options,
    );
  }

  associatePortsToCustomer<TData = void>(
    id: string,
    port: Port[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  associatePortsToCustomer<TData = void>(
    id: string,
    port: Port[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  associatePortsToCustomer<TData = void>(
    id: string,
    port: Port[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  associatePortsToCustomer<TData = void>(
    id: string,
    port: Port[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/ports`,
      port,
      options,
    );
  }

  putDistributorForCustomer<TData = void>(
    id: number,
    distId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putDistributorForCustomer<TData = void>(
    id: number,
    distId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putDistributorForCustomer<TData = void>(
    id: number,
    distId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putDistributorForCustomer<TData = void>(
    id: number,
    distId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/distributor/${distId}`,
      undefined,
      options,
    );
  }

  getDevicesForCustomer<TData = PageDevice>(
    id: string,
    params?: GetDevicesForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDevicesForCustomer<TData = PageDevice>(
    id: string,
    params?: GetDevicesForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDevicesForCustomer<TData = PageDevice>(
    id: string,
    params?: GetDevicesForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDevicesForCustomer<TData = PageDevice>(
    id: string,
    params?: GetDevicesForCustomerParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/devices`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  associateDevicesToCustomer<TData = void>(
    id: number,
    associateDevicesToCustomerBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  associateDevicesToCustomer<TData = void>(
    id: number,
    associateDevicesToCustomerBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  associateDevicesToCustomer<TData = void>(
    id: number,
    associateDevicesToCustomerBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  associateDevicesToCustomer<TData = void>(
    id: number,
    associateDevicesToCustomerBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/devices`,
      associateDevicesToCustomerBody,
      options,
    );
  }

  associateDeviceGroupToCustomer<TData = void>(
    id: string,
    groupId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  associateDeviceGroupToCustomer<TData = void>(
    id: string,
    groupId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  associateDeviceGroupToCustomer<TData = void>(
    id: string,
    groupId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  associateDeviceGroupToCustomer<TData = void>(
    id: string,
    groupId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/device-groups/${groupId}`,
      undefined,
      options,
    );
  }

  putContactForCustomer<TData = PutContactForCustomer200>(
    customerId: number,
    contactId: number,
    customerContactPerson: CustomerContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putContactForCustomer<TData = PutContactForCustomer200>(
    customerId: number,
    contactId: number,
    customerContactPerson: CustomerContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putContactForCustomer<TData = PutContactForCustomer200>(
    customerId: number,
    contactId: number,
    customerContactPerson: CustomerContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putContactForCustomer<TData = PutContactForCustomer200>(
    customerId: number,
    contactId: number,
    customerContactPerson: CustomerContactPerson,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customers/${customerId}/contacts/${contactId}`,
      customerContactPerson,
      options,
    );
  }

  deleteContactForCustomer<TData = void>(
    customerId: number,
    contactId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteContactForCustomer<TData = void>(
    customerId: number,
    contactId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteContactForCustomer<TData = void>(
    customerId: number,
    contactId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteContactForCustomer<TData = void>(
    customerId: number,
    contactId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/customers/${customerId}/contacts/${contactId}`,
      options,
    );
  }

  updatePatientProfile<TData = UpdatePatientProfile200>(
    customerId: number,
    id: number,
    profileDto: ProfileDto,
    params?: UpdatePatientProfileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updatePatientProfile<TData = UpdatePatientProfile200>(
    customerId: number,
    id: number,
    profileDto: ProfileDto,
    params?: UpdatePatientProfileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updatePatientProfile<TData = UpdatePatientProfile200>(
    customerId: number,
    id: number,
    profileDto: ProfileDto,
    params?: UpdatePatientProfileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updatePatientProfile<TData = UpdatePatientProfile200>(
    customerId: number,
    id: number,
    profileDto: ProfileDto,
    params?: UpdatePatientProfileParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customer/${customerId}/patientprofiles/${id}`,
      profileDto,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  removePatientProfile<TData = RemovePatientProfile200>(
    customerId: number,
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  removePatientProfile<TData = RemovePatientProfile200>(
    customerId: number,
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  removePatientProfile<TData = RemovePatientProfile200>(
    customerId: number,
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  removePatientProfile<TData = RemovePatientProfile200>(
    customerId: number,
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/customer/${customerId}/patientprofiles/${id}`,
      options,
    );
  }

  updateCustomerPatientProfileOrder<
    TData = UpdateCustomerPatientProfileOrder200,
  >(
    customerId: number,
    updateCustomerPatientProfileOrderBody: UpdateCustomerPatientProfileOrderBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateCustomerPatientProfileOrder<
    TData = UpdateCustomerPatientProfileOrder200,
  >(
    customerId: number,
    updateCustomerPatientProfileOrderBody: UpdateCustomerPatientProfileOrderBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateCustomerPatientProfileOrder<
    TData = UpdateCustomerPatientProfileOrder200,
  >(
    customerId: number,
    updateCustomerPatientProfileOrderBody: UpdateCustomerPatientProfileOrderBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateCustomerPatientProfileOrder<
    TData = UpdateCustomerPatientProfileOrder200,
  >(
    customerId: number,
    updateCustomerPatientProfileOrderBody: UpdateCustomerPatientProfileOrderBody,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/customer/${customerId}/patientprofiles/order`,
      updateCustomerPatientProfileOrderBody,
      options,
    );
  }

  getAllAnnouncements<TData = PageAnnouncementDto>(
    params?: GetAllAnnouncementsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllAnnouncements<TData = PageAnnouncementDto>(
    params?: GetAllAnnouncementsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllAnnouncements<TData = PageAnnouncementDto>(
    params?: GetAllAnnouncementsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllAnnouncements<TData = PageAnnouncementDto>(
    params?: GetAllAnnouncementsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/announcement`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  updateAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  updateAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  updateAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  updateAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/announcement`,
      announcementDto,
      options,
    );
  }

  createAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createAnnouncement<TData = AnnouncementDto>(
    announcementDto: AnnouncementDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/announcement`,
      announcementDto,
      options,
    );
  }

  putNotificationById<TData = FrontendNotificationDto>(
    id: number,
    frontendNotificationDto: FrontendNotificationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  putNotificationById<TData = FrontendNotificationDto>(
    id: number,
    frontendNotificationDto: FrontendNotificationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  putNotificationById<TData = FrontendNotificationDto>(
    id: number,
    frontendNotificationDto: FrontendNotificationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  putNotificationById<TData = FrontendNotificationDto>(
    id: number,
    frontendNotificationDto: FrontendNotificationDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/api/v1/admin/notifications/${id}`,
      frontendNotificationDto,
      options,
    );
  }

  deleteNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteNotificationById<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteNotificationById<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/admin/notifications/${id}`,
      options,
    );
  }

  getProfileProperties<TData = GetProfileProperties200>(
    profileId: number,
    params?: GetProfilePropertiesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getProfileProperties<TData = GetProfileProperties200>(
    profileId: number,
    params?: GetProfilePropertiesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getProfileProperties<TData = GetProfileProperties200>(
    profileId: number,
    params?: GetProfilePropertiesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getProfileProperties<TData = GetProfileProperties200>(
    profileId: number,
    params?: GetProfilePropertiesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}/properties`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  addProfileProperties<TData = AddProfileProperties200>(
    profileId: number,
    propertyDto: PropertyDto,
    params?: AddProfilePropertiesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  addProfileProperties<TData = AddProfileProperties200>(
    profileId: number,
    propertyDto: PropertyDto,
    params?: AddProfilePropertiesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  addProfileProperties<TData = AddProfileProperties200>(
    profileId: number,
    propertyDto: PropertyDto,
    params?: AddProfilePropertiesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  addProfileProperties<TData = AddProfileProperties200>(
    profileId: number,
    propertyDto: PropertyDto,
    params?: AddProfilePropertiesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}/properties`,
      propertyDto,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  addProfilePropertiesFromTemplate<TData = AddProfilePropertiesFromTemplate200>(
    profileId: number,
    profilePropertyTemplateDto: ProfilePropertyTemplateDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  addProfilePropertiesFromTemplate<TData = AddProfilePropertiesFromTemplate200>(
    profileId: number,
    profilePropertyTemplateDto: ProfilePropertyTemplateDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  addProfilePropertiesFromTemplate<TData = AddProfilePropertiesFromTemplate200>(
    profileId: number,
    profilePropertyTemplateDto: ProfilePropertyTemplateDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  addProfilePropertiesFromTemplate<TData = AddProfilePropertiesFromTemplate200>(
    profileId: number,
    profilePropertyTemplateDto: ProfilePropertyTemplateDto[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}/properties/template`,
      profilePropertyTemplateDto,
      options,
    );
  }

  addProfilePropertiesList<TData = AddProfilePropertiesList200>(
    profileId: number,
    propertyDto: PropertyDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  addProfilePropertiesList<TData = AddProfilePropertiesList200>(
    profileId: number,
    propertyDto: PropertyDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  addProfilePropertiesList<TData = AddProfilePropertiesList200>(
    profileId: number,
    propertyDto: PropertyDto[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  addProfilePropertiesList<TData = AddProfilePropertiesList200>(
    profileId: number,
    propertyDto: PropertyDto[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}/properties/list`,
      propertyDto,
      options,
    );
  }

  startMigrationOfProfiles<TData = StartMigrationOfProfiles200>(
    params?: StartMigrationOfProfilesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  startMigrationOfProfiles<TData = StartMigrationOfProfiles200>(
    params?: StartMigrationOfProfilesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  startMigrationOfProfiles<TData = StartMigrationOfProfiles200>(
    params?: StartMigrationOfProfilesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  startMigrationOfProfiles<TData = StartMigrationOfProfiles200>(
    params?: StartMigrationOfProfilesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles/migration`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  importProfileFromFile<TData = ImportProfileFromFile200>(
    importProfileFromFileBody: ImportProfileFromFileBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  importProfileFromFile<TData = ImportProfileFromFile200>(
    importProfileFromFileBody: ImportProfileFromFileBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  importProfileFromFile<TData = ImportProfileFromFile200>(
    importProfileFromFileBody: ImportProfileFromFileBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  importProfileFromFile<TData = ImportProfileFromFile200>(
    importProfileFromFileBody: ImportProfileFromFileBody,
    options?: HttpClientOptions,
  ): Observable<TData> {
    const formData = new FormData();
    formData.append('file', importProfileFromFileBody.file);
    if (importProfileFromFileBody.meta !== undefined) {
      formData.append('meta', JSON.stringify(importProfileFromFileBody.meta));
    }

    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles/import`,
      formData,
      options,
    );
  }

  cloneProfileById<TData = CloneProfileById200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  cloneProfileById<TData = CloneProfileById200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  cloneProfileById<TData = CloneProfileById200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  cloneProfileById<TData = CloneProfileById200>(
    profileId: number,
    profileDto: ProfileDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v2/profiles/clone/${profileId}`,
      profileDto,
      options,
    );
  }

  getAllWebApps<TData = GetAllWebApps200>(
    params?: GetAllWebAppsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllWebApps<TData = GetAllWebApps200>(
    params?: GetAllWebAppsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllWebApps<TData = GetAllWebApps200>(
    params?: GetAllWebAppsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllWebApps<TData = GetAllWebApps200>(
    params?: GetAllWebAppsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/webapps`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postWebApp<TData = WebAppDto>(
    webAppDto: WebAppDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postWebApp<TData = WebAppDto>(
    webAppDto: WebAppDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postWebApp<TData = WebAppDto>(
    webAppDto: WebAppDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postWebApp<TData = WebAppDto>(
    webAppDto: WebAppDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/webapps`,
      webAppDto,
      options,
    );
  }

  postLocationUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostLocationUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postLocationUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostLocationUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postLocationUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostLocationUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postLocationUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostLocationUpdateSessionParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${versionId}/location-group/${groupId}`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  postDeviceUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostDeviceUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postDeviceUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostDeviceUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postDeviceUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostDeviceUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postDeviceUpdateSession<TData = UpdateSessionDto>(
    versionId: number,
    groupId: number,
    params?: PostDeviceUpdateSessionParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${versionId}/device-group/${groupId}`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  restartSingleDeviceUpdate<TData = Device>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  restartSingleDeviceUpdate<TData = Device>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  restartSingleDeviceUpdate<TData = Device>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  restartSingleDeviceUpdate<TData = Device>(
    sessionId: number,
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${sessionId}/devices/${deviceId}/restart`,
      undefined,
      options,
    );
  }

  postBulkUpdate<TData = UpdateSessionDto>(
    startUpdateDto: StartUpdateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postBulkUpdate<TData = UpdateSessionDto>(
    startUpdateDto: StartUpdateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postBulkUpdate<TData = UpdateSessionDto>(
    startUpdateDto: StartUpdateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postBulkUpdate<TData = UpdateSessionDto>(
    startUpdateDto: StartUpdateDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/start`,
      startUpdateDto,
      options,
    );
  }

  createAndStartConfigUpdateSession<
    TData = CreateAndStartConfigUpdateSession200,
  >(
    sessionId: number,
    params?: CreateAndStartConfigUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createAndStartConfigUpdateSession<
    TData = CreateAndStartConfigUpdateSession200,
  >(
    sessionId: number,
    params?: CreateAndStartConfigUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createAndStartConfigUpdateSession<
    TData = CreateAndStartConfigUpdateSession200,
  >(
    sessionId: number,
    params?: CreateAndStartConfigUpdateSessionParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createAndStartConfigUpdateSession<
    TData = CreateAndStartConfigUpdateSession200,
  >(
    sessionId: number,
    params?: CreateAndStartConfigUpdateSessionParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/sessions/config/${sessionId}`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  processBatchConfigUpdate<TData = ProcessBatchConfigUpdate200>(
    batchConfigUpdateDto: BatchConfigUpdateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  processBatchConfigUpdate<TData = ProcessBatchConfigUpdate200>(
    batchConfigUpdateDto: BatchConfigUpdateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  processBatchConfigUpdate<TData = ProcessBatchConfigUpdate200>(
    batchConfigUpdateDto: BatchConfigUpdateDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  processBatchConfigUpdate<TData = ProcessBatchConfigUpdate200>(
    batchConfigUpdateDto: BatchConfigUpdateDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/sessions/config/batch`,
      batchConfigUpdateDto,
      options,
    );
  }

  checkForMissingRoles<TData = string[]>(
    params?: CheckForMissingRolesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  checkForMissingRoles<TData = string[]>(
    params?: CheckForMissingRolesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  checkForMissingRoles<TData = string[]>(
    params?: CheckForMissingRolesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  checkForMissingRoles<TData = string[]>(
    params?: CheckForMissingRolesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/roles/check-missing`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getReleaseNotes<TData = GetReleaseNotes200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getReleaseNotes<TData = GetReleaseNotes200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getReleaseNotes<TData = GetReleaseNotes200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getReleaseNotes<TData = GetReleaseNotes200>(
    releaseId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/releases/${releaseId}/release-notes`,
      options,
    );
  }

  postReleaseNotes<TData = void>(
    releaseId: number,
    postReleaseNotesBody: PostReleaseNotesBody,
    params?: PostReleaseNotesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postReleaseNotes<TData = void>(
    releaseId: number,
    postReleaseNotesBody: PostReleaseNotesBody,
    params?: PostReleaseNotesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postReleaseNotes<TData = void>(
    releaseId: number,
    postReleaseNotesBody: PostReleaseNotesBody,
    params?: PostReleaseNotesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postReleaseNotes<TData = void>(
    releaseId: number,
    postReleaseNotesBody: PostReleaseNotesBody,
    params?: PostReleaseNotesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/releases/${releaseId}/release-notes`,
      postReleaseNotesBody,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getImagesForRelease<TData = GetImagesForRelease200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getImagesForRelease<TData = GetImagesForRelease200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getImagesForRelease<TData = GetImagesForRelease200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getImagesForRelease<TData = GetImagesForRelease200>(
    releaseId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/releases/${releaseId}/images`,
      options,
    );
  }

  postImageForRelease<TData = PostImageForRelease200>(
    releaseId: number,
    deviceImageUploadDto: DeviceImageUploadDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postImageForRelease<TData = PostImageForRelease200>(
    releaseId: number,
    deviceImageUploadDto: DeviceImageUploadDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postImageForRelease<TData = PostImageForRelease200>(
    releaseId: number,
    deviceImageUploadDto: DeviceImageUploadDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postImageForRelease<TData = PostImageForRelease200>(
    releaseId: number,
    deviceImageUploadDto: DeviceImageUploadDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/releases/${releaseId}/images`,
      deviceImageUploadDto,
      options,
    );
  }

  postReleaseForCustomer<TData = PostReleaseForCustomer200>(
    releaseId: number,
    customerId: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postReleaseForCustomer<TData = PostReleaseForCustomer200>(
    releaseId: number,
    customerId: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postReleaseForCustomer<TData = PostReleaseForCustomer200>(
    releaseId: number,
    customerId: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postReleaseForCustomer<TData = PostReleaseForCustomer200>(
    releaseId: number,
    customerId: string,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/releases/${releaseId}/customers/${customerId}`,
      undefined,
      options,
    );
  }

  registerSipState<TData = void>(
    sipStateRegisterRequestDto: SipStateRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  registerSipState<TData = void>(
    sipStateRegisterRequestDto: SipStateRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  registerSipState<TData = void>(
    sipStateRegisterRequestDto: SipStateRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  registerSipState<TData = void>(
    sipStateRegisterRequestDto: SipStateRegisterRequestDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/register/sip`,
      sipStateRegisterRequestDto,
      options,
    );
  }

  registerPatient<TData = void>(
    patientRegisterRequestDto: PatientRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  registerPatient<TData = void>(
    patientRegisterRequestDto: PatientRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  registerPatient<TData = void>(
    patientRegisterRequestDto: PatientRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  registerPatient<TData = void>(
    patientRegisterRequestDto: PatientRegisterRequestDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/register/patient`,
      patientRegisterRequestDto,
      options,
    );
  }

  registerPhysicalLocation<TData = RegisterPhysicalLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  registerPhysicalLocation<TData = RegisterPhysicalLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  registerPhysicalLocation<TData = RegisterPhysicalLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  registerPhysicalLocation<TData = RegisterPhysicalLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/register/location`,
      physicalLocationDto,
      options,
    );
  }

  registerDevice<TData = RegisterDevice200>(
    deviceRegisterRequestDto: DeviceRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  registerDevice<TData = RegisterDevice200>(
    deviceRegisterRequestDto: DeviceRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  registerDevice<TData = RegisterDevice200>(
    deviceRegisterRequestDto: DeviceRegisterRequestDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  registerDevice<TData = RegisterDevice200>(
    deviceRegisterRequestDto: DeviceRegisterRequestDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/register/device`,
      deviceRegisterRequestDto,
      options,
    );
  }

  getAllPorts<TData = GetAllPorts200>(
    params?: GetAllPortsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllPorts<TData = GetAllPorts200>(
    params?: GetAllPortsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllPorts<TData = GetAllPorts200>(
    params?: GetAllPortsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllPorts<TData = GetAllPorts200>(
    params?: GetAllPortsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/ports`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postPort<TData = Port>(
    portDto: PortDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postPort<TData = Port>(
    portDto: PortDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postPort<TData = Port>(
    portDto: PortDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postPort<TData = Port>(
    portDto: PortDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/ports`,
      portDto,
      options,
    );
  }

  importPropertiesLocations<TData = PortImportResultDto>(
    importPropertiesLocationsBody: ImportPropertiesLocationsBody,
    params: ImportPropertiesLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  importPropertiesLocations<TData = PortImportResultDto>(
    importPropertiesLocationsBody: ImportPropertiesLocationsBody,
    params: ImportPropertiesLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  importPropertiesLocations<TData = PortImportResultDto>(
    importPropertiesLocationsBody: ImportPropertiesLocationsBody,
    params: ImportPropertiesLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  importPropertiesLocations<TData = PortImportResultDto>(
    importPropertiesLocationsBody: ImportPropertiesLocationsBody,
    params: ImportPropertiesLocationsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    const formData = new FormData();
    formData.append('file', importPropertiesLocationsBody.file);

    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/ports/properties/import`,
      formData,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  importLocations<TData = PortImportResultDto>(
    importLocationsBody: ImportLocationsBody,
    params: ImportLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  importLocations<TData = PortImportResultDto>(
    importLocationsBody: ImportLocationsBody,
    params: ImportLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  importLocations<TData = PortImportResultDto>(
    importLocationsBody: ImportLocationsBody,
    params: ImportLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  importLocations<TData = PortImportResultDto>(
    importLocationsBody: ImportLocationsBody,
    params: ImportLocationsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    const formData = new FormData();
    formData.append('file', importLocationsBody.file);

    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/ports/import`,
      formData,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  postLocationsForDevices<TData = Port[]>(
    postLocationsForDevicesBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postLocationsForDevices<TData = Port[]>(
    postLocationsForDevicesBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postLocationsForDevices<TData = Port[]>(
    postLocationsForDevicesBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postLocationsForDevices<TData = Port[]>(
    postLocationsForDevicesBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/ports/deviceIds`,
      postLocationsForDevicesBody,
      options,
    );
  }

  getAllGroups<TData = PageObject>(
    params?: GetAllGroupsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllGroups<TData = PageObject>(
    params?: GetAllGroupsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllGroups<TData = PageObject>(
    params?: GetAllGroupsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllGroups<TData = PageObject>(
    params?: GetAllGroupsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/port-groups`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postGroup<TData = PostGroup200>(
    portGroup: PortGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postGroup<TData = PostGroup200>(
    portGroup: PortGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postGroup<TData = PostGroup200>(
    portGroup: PortGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postGroup<TData = PostGroup200>(
    portGroup: PortGroup,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/port-groups`,
      portGroup,
      options,
    );
  }

  sendPushNotificationToDevice<TData = void>(
    deviceId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  sendPushNotificationToDevice<TData = void>(
    deviceId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  sendPushNotificationToDevice<TData = void>(
    deviceId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  sendPushNotificationToDevice<TData = void>(
    deviceId: number,
    pushNotification: PushNotification,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/notification/${deviceId}/send`,
      pushNotification,
      options,
    );
  }

  sendPushNotifications<TData = void>(
    pushNotificationList: PushNotificationList,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  sendPushNotifications<TData = void>(
    pushNotificationList: PushNotificationList,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  sendPushNotifications<TData = void>(
    pushNotificationList: PushNotificationList,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  sendPushNotifications<TData = void>(
    pushNotificationList: PushNotificationList,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/notification/send`,
      pushNotificationList,
      options,
    );
  }

  sendPushNotificationToLocationGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  sendPushNotificationToLocationGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  sendPushNotificationToLocationGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  sendPushNotificationToLocationGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/notification/location-group/${groupId}/send`,
      pushNotification,
      options,
    );
  }

  sendPushNotificationToDeviceGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  sendPushNotificationToDeviceGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  sendPushNotificationToDeviceGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  sendPushNotificationToDeviceGroup<TData = void>(
    groupId: number,
    pushNotification: PushNotification,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/notification/device-group/${groupId}/send`,
      pushNotification,
      options,
    );
  }

  createLogcatFile<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createLogcatFile<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createLogcatFile<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createLogcatFile<TData = void>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/monitoring/${deviceId}/logcat`,
      undefined,
      options,
    );
  }

  deleteLogcatFile<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteLogcatFile<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteLogcatFile<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteLogcatFile<TData = void>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/monitoring/${deviceId}/logcat`,
      options,
    );
  }

  createDailyJournal<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createDailyJournal<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createDailyJournal<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createDailyJournal<TData = void>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/monitoring/${deviceId}/dailyjournal`,
      undefined,
      options,
    );
  }

  deleteDailyJournal<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDailyJournal<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDailyJournal<TData = void>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDailyJournal<TData = void>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/monitoring/${deviceId}/dailyjournal`,
      options,
    );
  }

  postLogcatFile<TData = void>(
    postLogcatFileBody: PostLogcatFileBody,
    params: PostLogcatFileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postLogcatFile<TData = void>(
    postLogcatFileBody: PostLogcatFileBody,
    params: PostLogcatFileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postLogcatFile<TData = void>(
    postLogcatFileBody: PostLogcatFileBody,
    params: PostLogcatFileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postLogcatFile<TData = void>(
    postLogcatFileBody: PostLogcatFileBody,
    params: PostLogcatFileParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/monitoring/logcat`,
      postLogcatFileBody,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  postDailyJournal<TData = void>(
    postDailyJournalBody: PostDailyJournalBody,
    params: PostDailyJournalParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postDailyJournal<TData = void>(
    postDailyJournalBody: PostDailyJournalBody,
    params: PostDailyJournalParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postDailyJournal<TData = void>(
    postDailyJournalBody: PostDailyJournalBody,
    params: PostDailyJournalParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postDailyJournal<TData = void>(
    postDailyJournalBody: PostDailyJournalBody,
    params: PostDailyJournalParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/monitoring/dailyjournal`,
      postDailyJournalBody,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllTableConfigurations<TData = LocationTableConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllTableConfigurations<TData = LocationTableConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllTableConfigurations<TData = LocationTableConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllTableConfigurations<TData = LocationTableConfiguration[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/locations`,
      options,
    );
  }

  createTableConfiguration<TData = LocationTableConfiguration>(
    locationTableConfiguration: LocationTableConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createTableConfiguration<TData = LocationTableConfiguration>(
    locationTableConfiguration: LocationTableConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createTableConfiguration<TData = LocationTableConfiguration>(
    locationTableConfiguration: LocationTableConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createTableConfiguration<TData = LocationTableConfiguration>(
    locationTableConfiguration: LocationTableConfiguration,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/locations`,
      locationTableConfiguration,
      options,
    );
  }

  getAllFilterCategories<TData = FilterConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllFilterCategories<TData = FilterConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllFilterCategories<TData = FilterConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllFilterCategories<TData = FilterConfiguration[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/filters`,
      options,
    );
  }

  createFilterCategory2<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createFilterCategory2<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createFilterCategory2<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createFilterCategory2<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/table/filters`,
      filterConfiguration,
      options,
    );
  }

  getAllFilterCategories1<TData = FilterConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllFilterCategories1<TData = FilterConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllFilterCategories1<TData = FilterConfiguration[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllFilterCategories1<TData = FilterConfiguration[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/filters`,
      options,
    );
  }

  createFilterCategory3<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createFilterCategory3<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createFilterCategory3<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createFilterCategory3<TData = FilterConfiguration>(
    filterConfiguration: FilterConfiguration,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/filters`,
      filterConfiguration,
      options,
    );
  }

  getAllDeviceTableConfigurations<TData = DeviceTableConfig[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllDeviceTableConfigurations<TData = DeviceTableConfig[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllDeviceTableConfigurations<TData = DeviceTableConfig[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllDeviceTableConfigurations<TData = DeviceTableConfig[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/devices`,
      options,
    );
  }

  createDeviceTableConfiguration<TData = DeviceTableConfig>(
    deviceTableConfig: DeviceTableConfig,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createDeviceTableConfiguration<TData = DeviceTableConfig>(
    deviceTableConfig: DeviceTableConfig,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createDeviceTableConfiguration<TData = DeviceTableConfig>(
    deviceTableConfig: DeviceTableConfig,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createDeviceTableConfiguration<TData = DeviceTableConfig>(
    deviceTableConfig: DeviceTableConfig,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/table/devices`,
      deviceTableConfig,
      options,
    );
  }

  getAllLldpLocations<TData = PagePhysicalLocation>(
    params?: GetAllLldpLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllLldpLocations<TData = PagePhysicalLocation>(
    params?: GetAllLldpLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllLldpLocations<TData = PagePhysicalLocation>(
    params?: GetAllLldpLocationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllLldpLocations<TData = PagePhysicalLocation>(
    params?: GetAllLldpLocationsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/locations`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postLocation<TData = PostLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postLocation<TData = PostLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postLocation<TData = PostLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postLocation<TData = PostLocation200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/locations`,
      physicalLocationDto,
      options,
    );
  }

  postLocationForPort<TData = PostLocationForPort200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postLocationForPort<TData = PostLocationForPort200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postLocationForPort<TData = PostLocationForPort200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postLocationForPort<TData = PostLocationForPort200>(
    physicalLocationDto: PhysicalLocationDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/locations/port`,
      physicalLocationDto,
      options,
    );
  }

  migrateProperties<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  migrateProperties<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  migrateProperties<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  migrateProperties<TData = void>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/history/migrate`,
      undefined,
      options,
    );
  }

  postProbingRequestByProbeResultId<TData = FwProbingStartResponse>(
    probeResultId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postProbingRequestByProbeResultId<TData = FwProbingStartResponse>(
    probeResultId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postProbingRequestByProbeResultId<TData = FwProbingStartResponse>(
    probeResultId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postProbingRequestByProbeResultId<TData = FwProbingStartResponse>(
    probeResultId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/firewall-probing/start-probing/probe-result/${probeResultId}`,
      undefined,
      options,
    );
  }

  postProbingRequestAll<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postProbingRequestAll<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postProbingRequestAll<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postProbingRequestAll<TData = void>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/firewall-probing/start-probing/all`,
      undefined,
      options,
    );
  }

  postFeedback<TData = void>(
    postFeedbackBody: PostFeedbackBody,
    params: PostFeedbackParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postFeedback<TData = void>(
    postFeedbackBody: PostFeedbackBody,
    params: PostFeedbackParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postFeedback<TData = void>(
    postFeedbackBody: PostFeedbackBody,
    params: PostFeedbackParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postFeedback<TData = void>(
    postFeedbackBody: PostFeedbackBody,
    params: PostFeedbackParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    const formData = new FormData();
    if (postFeedbackBody.screenshot !== undefined) {
      formData.append('screenshot', postFeedbackBody.screenshot);
    }

    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/feedback`,
      formData,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllFeatures<TData = PageFeatureDTO>(
    params?: GetAllFeaturesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllFeatures<TData = PageFeatureDTO>(
    params?: GetAllFeaturesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllFeatures<TData = PageFeatureDTO>(
    params?: GetAllFeaturesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllFeatures<TData = PageFeatureDTO>(
    params?: GetAllFeaturesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/features`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  createFeature<TData = FullFeatureDTO>(
    fullFeatureDTO: FullFeatureDTO,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createFeature<TData = FullFeatureDTO>(
    fullFeatureDTO: FullFeatureDTO,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createFeature<TData = FullFeatureDTO>(
    fullFeatureDTO: FullFeatureDTO,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createFeature<TData = FullFeatureDTO>(
    fullFeatureDTO: FullFeatureDTO,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/features`,
      fullFeatureDTO,
      options,
    );
  }

  getAll<TData = PageDistributor>(
    params?: GetAllParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAll<TData = PageDistributor>(
    params?: GetAllParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAll<TData = PageDistributor>(
    params?: GetAllParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAll<TData = PageDistributor>(
    params?: GetAllParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/distributors`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postDistributor<TData = Distributor>(
    distributor: Distributor,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postDistributor<TData = Distributor>(
    distributor: Distributor,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postDistributor<TData = Distributor>(
    distributor: Distributor,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postDistributor<TData = Distributor>(
    distributor: Distributor,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors`,
      distributor,
      options,
    );
  }

  getUsersForDistributor<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForDistributorParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUsersForDistributor<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForDistributorParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUsersForDistributor<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForDistributorParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUsersForDistributor<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForDistributorParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/users`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  postUserForDistributor<TData = PostUserForDistributor200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postUserForDistributor<TData = PostUserForDistributor200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postUserForDistributor<TData = PostUserForDistributor200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postUserForDistributor<TData = PostUserForDistributor200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/users`,
      keycloakUserDto,
      options,
    );
  }

  getCustomers<TData = Customer[]>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomers<TData = Customer[]>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomers<TData = Customer[]>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomers<TData = Customer[]>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/customers`,
      options,
    );
  }

  postCustomerForDistributor<TData = Customer>(
    id: number,
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postCustomerForDistributor<TData = Customer>(
    id: number,
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postCustomerForDistributor<TData = Customer>(
    id: number,
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postCustomerForDistributor<TData = Customer>(
    id: number,
    customer: Customer,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/customers`,
      customer,
      options,
    );
  }

  getContactsForDistributor<TData = GetContactsForDistributor200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getContactsForDistributor<TData = GetContactsForDistributor200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getContactsForDistributor<TData = GetContactsForDistributor200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getContactsForDistributor<TData = GetContactsForDistributor200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/contacts`,
      options,
    );
  }

  postContactForDistributor<TData = PostContactForDistributor200>(
    id: number,
    distributorContactPerson: DistributorContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postContactForDistributor<TData = PostContactForDistributor200>(
    id: number,
    distributorContactPerson: DistributorContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postContactForDistributor<TData = PostContactForDistributor200>(
    id: number,
    distributorContactPerson: DistributorContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postContactForDistributor<TData = PostContactForDistributor200>(
    id: number,
    distributorContactPerson: DistributorContactPerson,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/contacts`,
      distributorContactPerson,
      options,
    );
  }

  postImport<TData = Distributor[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postImport<TData = Distributor[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postImport<TData = Distributor[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postImport<TData = Distributor[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors/import`,
      undefined,
      options,
    );
  }

  checkGroupsForDistributors<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  checkGroupsForDistributors<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  checkGroupsForDistributors<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  checkGroupsForDistributors<TData = void>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors/check-groups`,
      undefined,
      options,
    );
  }

  checkDistributorUsersGroupAttributes<TData = void>(
    params?: CheckDistributorUsersGroupAttributesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  checkDistributorUsersGroupAttributes<TData = void>(
    params?: CheckDistributorUsersGroupAttributesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  checkDistributorUsersGroupAttributes<TData = void>(
    params?: CheckDistributorUsersGroupAttributesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  checkDistributorUsersGroupAttributes<TData = void>(
    params?: CheckDistributorUsersGroupAttributesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/distributors/check-group-attributes`,
      undefined,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllDevices<TData = FullDeviceEntity[]>(
    params?: GetAllDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllDevices<TData = FullDeviceEntity[]>(
    params?: GetAllDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllDevices<TData = FullDeviceEntity[]>(
    params?: GetAllDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllDevices<TData = FullDeviceEntity[]>(
    params?: GetAllDevicesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/devices`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postDevice<TData = Device>(
    device: Device,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postDevice<TData = Device>(
    device: Device,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postDevice<TData = Device>(
    device: Device,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postDevice<TData = Device>(
    device: Device,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/devices`,
      device,
      options,
    );
  }

  restartDevice<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  restartDevice<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  restartDevice<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  restartDevice<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/devices/${id}/restart`,
      undefined,
      options,
    );
  }

  restartDevice1<TData = void>(
    restartDevice1Body: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  restartDevice1<TData = void>(
    restartDevice1Body: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  restartDevice1<TData = void>(
    restartDevice1Body: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  restartDevice1<TData = void>(
    restartDevice1Body: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/devices/restart`,
      restartDevice1Body,
      options,
    );
  }

  importDevices<TData = ImportedDevice[]>(
    importDevicesBody: ImportDevicesBody,
    params: ImportDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  importDevices<TData = ImportedDevice[]>(
    importDevicesBody: ImportDevicesBody,
    params: ImportDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  importDevices<TData = ImportedDevice[]>(
    importDevicesBody: ImportDevicesBody,
    params: ImportDevicesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  importDevices<TData = ImportedDevice[]>(
    importDevicesBody: ImportDevicesBody,
    params: ImportDevicesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    const formData = new FormData();
    formData.append('file', importDevicesBody.file);

    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/devices/import`,
      formData,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllCommands<TData = DeviceCommand[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllCommands<TData = DeviceCommand[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllCommands<TData = DeviceCommand[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllCommands<TData = DeviceCommand[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device/command`,
      options,
    );
  }

  postResponseFromDevice<TData = void>(
    mqttMessage: MqttMessage,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postResponseFromDevice<TData = void>(
    mqttMessage: MqttMessage,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postResponseFromDevice<TData = void>(
    mqttMessage: MqttMessage,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postResponseFromDevice<TData = void>(
    mqttMessage: MqttMessage,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/device/command`,
      mqttMessage,
      options,
    );
  }

  getAllDeviceTypes<TData = PageDeviceType>(
    params?: GetAllDeviceTypesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllDeviceTypes<TData = PageDeviceType>(
    params?: GetAllDeviceTypesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllDeviceTypes<TData = PageDeviceType>(
    params?: GetAllDeviceTypesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllDeviceTypes<TData = PageDeviceType>(
    params?: GetAllDeviceTypesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/device-types`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postDeviceType<TData = DeviceType>(
    deviceType: DeviceType,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postDeviceType<TData = DeviceType>(
    deviceType: DeviceType,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postDeviceType<TData = DeviceType>(
    deviceType: DeviceType,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postDeviceType<TData = DeviceType>(
    deviceType: DeviceType,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/device-types`,
      deviceType,
      options,
    );
  }

  getAllGroups1<TData = PageObject>(
    params?: GetAllGroups1Params,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllGroups1<TData = PageObject>(
    params?: GetAllGroups1Params,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllGroups1<TData = PageObject>(
    params?: GetAllGroups1Params,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllGroups1<TData = PageObject>(
    params?: GetAllGroups1Params,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/device-groups`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postDeviceGroup<TData = DeviceGroup>(
    deviceGroup: DeviceGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postDeviceGroup<TData = DeviceGroup>(
    deviceGroup: DeviceGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postDeviceGroup<TData = DeviceGroup>(
    deviceGroup: DeviceGroup,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postDeviceGroup<TData = DeviceGroup>(
    deviceGroup: DeviceGroup,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/device-groups`,
      deviceGroup,
      options,
    );
  }

  getCustomers1<TData = PageCustomerFullDto>(
    params?: GetCustomers1Params,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomers1<TData = PageCustomerFullDto>(
    params?: GetCustomers1Params,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomers1<TData = PageCustomerFullDto>(
    params?: GetCustomers1Params,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomers1<TData = PageCustomerFullDto>(
    params?: GetCustomers1Params,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/customers`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  postCustomer<TData = Customer>(
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postCustomer<TData = Customer>(
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postCustomer<TData = Customer>(
    customer: Customer,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postCustomer<TData = Customer>(
    customer: Customer,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customers`,
      customer,
      options,
    );
  }

  getUsersForCustomer<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUsersForCustomer<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUsersForCustomer<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUsersForCustomer<TData = PageKeycloakUserDto>(
    id: number,
    params?: GetUsersForCustomerParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/users`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  postUserForCustomer<TData = PostUserForCustomer200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postUserForCustomer<TData = PostUserForCustomer200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postUserForCustomer<TData = PostUserForCustomer200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postUserForCustomer<TData = PostUserForCustomer200>(
    id: number,
    keycloakUserDto: KeycloakUserDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/users`,
      keycloakUserDto,
      options,
    );
  }

  getContactsForCustomer<TData = GetContactsForCustomer200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getContactsForCustomer<TData = GetContactsForCustomer200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getContactsForCustomer<TData = GetContactsForCustomer200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getContactsForCustomer<TData = GetContactsForCustomer200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/contacts`,
      options,
    );
  }

  postContactForCustomer<TData = PostContactForCustomer200>(
    id: number,
    customerContactPerson: CustomerContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postContactForCustomer<TData = PostContactForCustomer200>(
    id: number,
    customerContactPerson: CustomerContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postContactForCustomer<TData = PostContactForCustomer200>(
    id: number,
    customerContactPerson: CustomerContactPerson,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postContactForCustomer<TData = PostContactForCustomer200>(
    id: number,
    customerContactPerson: CustomerContactPerson,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/contacts`,
      customerContactPerson,
      options,
    );
  }

  getCustomerBridges<TData = GetCustomerBridges200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomerBridges<TData = GetCustomerBridges200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomerBridges<TData = GetCustomerBridges200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomerBridges<TData = GetCustomerBridges200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/bridges`,
      options,
    );
  }

  addCustomerBridge<TData = Bridge>(
    id: number,
    bridgeDto: BridgeDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  addCustomerBridge<TData = Bridge>(
    id: number,
    bridgeDto: BridgeDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  addCustomerBridge<TData = Bridge>(
    id: number,
    bridgeDto: BridgeDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  addCustomerBridge<TData = Bridge>(
    id: number,
    bridgeDto: BridgeDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/bridges`,
      bridgeDto,
      options,
    );
  }

  checkGroupsForCustomers<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  checkGroupsForCustomers<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  checkGroupsForCustomers<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  checkGroupsForCustomers<TData = void>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customers/check-groups`,
      undefined,
      options,
    );
  }

  getCustomerPatientProfiles<TData = GetCustomerPatientProfiles200>(
    customerId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomerPatientProfiles<TData = GetCustomerPatientProfiles200>(
    customerId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomerPatientProfiles<TData = GetCustomerPatientProfiles200>(
    customerId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomerPatientProfiles<TData = GetCustomerPatientProfiles200>(
    customerId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customer/${customerId}/patientprofiles`,
      options,
    );
  }

  createPatientProfile<TData = CreatePatientProfile200>(
    customerId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  createPatientProfile<TData = CreatePatientProfile200>(
    customerId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  createPatientProfile<TData = CreatePatientProfile200>(
    customerId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  createPatientProfile<TData = CreatePatientProfile200>(
    customerId: number,
    profileDto: ProfileDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customer/${customerId}/patientprofiles`,
      profileDto,
      options,
    );
  }

  clonePatientProfile<TData = ClonePatientProfile200>(
    customerId: number,
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  clonePatientProfile<TData = ClonePatientProfile200>(
    customerId: number,
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  clonePatientProfile<TData = ClonePatientProfile200>(
    customerId: number,
    profileId: number,
    profileDto: ProfileDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  clonePatientProfile<TData = ClonePatientProfile200>(
    customerId: number,
    profileId: number,
    profileDto: ProfileDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/customer/${customerId}/patientprofiles/${profileId}/clone`,
      profileDto,
      options,
    );
  }

  importCertificateFromFile<TData = ImportCertificateFromFile200>(
    importCertificateFromFileBody: ImportCertificateFromFileBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  importCertificateFromFile<TData = ImportCertificateFromFile200>(
    importCertificateFromFileBody: ImportCertificateFromFileBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  importCertificateFromFile<TData = ImportCertificateFromFile200>(
    importCertificateFromFileBody: ImportCertificateFromFileBody,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  importCertificateFromFile<TData = ImportCertificateFromFile200>(
    importCertificateFromFileBody: ImportCertificateFromFileBody,
    options?: HttpClientOptions,
  ): Observable<TData> {
    const formData = new FormData();
    formData.append('file', importCertificateFromFileBody.file);

    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/certificate-management/import`,
      formData,
      options,
    );
  }

  getAllNotifications<TData = PageFrontendNotificationDto>(
    params?: GetAllNotificationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllNotifications<TData = PageFrontendNotificationDto>(
    params?: GetAllNotificationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllNotifications<TData = PageFrontendNotificationDto>(
    params?: GetAllNotificationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllNotifications<TData = PageFrontendNotificationDto>(
    params?: GetAllNotificationsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/admin/notifications`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  postNotification<TData = void>(
    frontendNotificationDto: FrontendNotificationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  postNotification<TData = void>(
    frontendNotificationDto: FrontendNotificationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  postNotification<TData = void>(
    frontendNotificationDto: FrontendNotificationDto,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  postNotification<TData = void>(
    frontendNotificationDto: FrontendNotificationDto,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/api/v1/admin/notifications`,
      frontendNotificationDto,
      options,
    );
  }

  getProfileJson<TData = GetProfileJson200>(
    profileId: number,
    params?: GetProfileJsonParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getProfileJson<TData = GetProfileJson200>(
    profileId: number,
    params?: GetProfileJsonParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getProfileJson<TData = GetProfileJson200>(
    profileId: number,
    params?: GetProfileJsonParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getProfileJson<TData = GetProfileJson200>(
    profileId: number,
    params?: GetProfileJsonParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/profiles/${profileId}/json`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getLatestProfileInfoForDevice<TData = GetLatestProfileInfoForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLatestProfileInfoForDevice<TData = GetLatestProfileInfoForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLatestProfileInfoForDevice<TData = GetLatestProfileInfoForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLatestProfileInfoForDevice<TData = GetLatestProfileInfoForDevice200>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v2/profiles/device/${deviceId}`,
      options,
    );
  }

  getAllUsers<TData = GetAllUsers200>(
    params?: GetAllUsersParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllUsers<TData = GetAllUsers200>(
    params?: GetAllUsersParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllUsers<TData = GetAllUsers200>(
    params?: GetAllUsersParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllUsers<TData = GetAllUsers200>(
    params?: GetAllUsersParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/users`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getAllUpdateSessions<TData = PageUpdateSessionDto>(
    params?: GetAllUpdateSessionsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllUpdateSessions<TData = PageUpdateSessionDto>(
    params?: GetAllUpdateSessionsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllUpdateSessions<TData = PageUpdateSessionDto>(
    params?: GetAllUpdateSessionsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllUpdateSessions<TData = PageUpdateSessionDto>(
    params?: GetAllUpdateSessionsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getDeviceForUpdateSession<TData = GetDeviceForUpdateSession200>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceForUpdateSession<TData = GetDeviceForUpdateSession200>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceForUpdateSession<TData = GetDeviceForUpdateSession200>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceForUpdateSession<TData = GetDeviceForUpdateSession200>(
    sessionId: number,
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${sessionId}/devices/${deviceId}`,
      options,
    );
  }

  getUpdateSession<TData = UpdateSessionDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUpdateSession<TData = UpdateSessionDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUpdateSession<TData = UpdateSessionDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUpdateSession<TData = UpdateSessionDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${id}`,
      options,
    );
  }

  deleteUpdateSession<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteUpdateSession<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteUpdateSession<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteUpdateSession<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${id}`,
      options,
    );
  }

  getUpdateSessionDeviceStatus<TData = GetUpdateSessionDeviceStatus200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUpdateSessionDeviceStatus<TData = GetUpdateSessionDeviceStatus200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUpdateSessionDeviceStatus<TData = GetUpdateSessionDeviceStatus200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUpdateSessionDeviceStatus<TData = GetUpdateSessionDeviceStatus200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${id}/status`,
      options,
    );
  }

  getUpdateSessionStats<TData = GetUpdateSessionStats200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUpdateSessionStats<TData = GetUpdateSessionStats200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUpdateSessionStats<TData = GetUpdateSessionStats200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUpdateSessionStats<TData = GetUpdateSessionStats200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${id}/stats`,
      options,
    );
  }

  getUpdateSessionHistory<TData = GetUpdateSessionHistory200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUpdateSessionHistory<TData = GetUpdateSessionHistory200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUpdateSessionHistory<TData = GetUpdateSessionHistory200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUpdateSessionHistory<TData = GetUpdateSessionHistory200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${id}/history`,
      options,
    );
  }

  getDevicesForUpdateSession<TData = UpdateSessionDeviceDetailsDto[]>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDevicesForUpdateSession<TData = UpdateSessionDeviceDetailsDto[]>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDevicesForUpdateSession<TData = UpdateSessionDeviceDetailsDto[]>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDevicesForUpdateSession<TData = UpdateSessionDeviceDetailsDto[]>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/${id}/devices`,
      options,
    );
  }

  getUpdateHistory<TData = PageUpdateSessionDto>(
    params?: GetUpdateHistoryParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getUpdateHistory<TData = PageUpdateSessionDto>(
    params?: GetUpdateHistoryParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getUpdateHistory<TData = PageUpdateSessionDto>(
    params?: GetUpdateHistoryParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getUpdateHistory<TData = PageUpdateSessionDto>(
    params?: GetUpdateHistoryParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/update-sessions/history`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getDeviceSWVersions<TData = StatisticsDTO>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceSWVersions<TData = StatisticsDTO>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceSWVersions<TData = StatisticsDTO>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceSWVersions<TData = StatisticsDTO>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/statistics/device`,
      options,
    );
  }

  getAllSessions<TData = PageListUpdateSessionDto>(
    params?: GetAllSessionsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllSessions<TData = PageListUpdateSessionDto>(
    params?: GetAllSessionsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllSessions<TData = PageListUpdateSessionDto>(
    params?: GetAllSessionsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllSessions<TData = PageListUpdateSessionDto>(
    params?: GetAllSessionsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/sessions`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getSessionDevicesBySessionId<TData = ListDeviceDto[]>(
    sessionId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getSessionDevicesBySessionId<TData = ListDeviceDto[]>(
    sessionId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getSessionDevicesBySessionId<TData = ListDeviceDto[]>(
    sessionId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getSessionDevicesBySessionId<TData = ListDeviceDto[]>(
    sessionId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/sessions/${sessionId}/devices`,
      options,
    );
  }

  getDeviceForSession<TData = ListDeviceDto>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceForSession<TData = ListDeviceDto>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceForSession<TData = ListDeviceDto>(
    sessionId: number,
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceForSession<TData = ListDeviceDto>(
    sessionId: number,
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/sessions/${sessionId}/devices/${deviceId}`,
      options,
    );
  }

  getSessionById<TData = GetSessionById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getSessionById<TData = GetSessionById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getSessionById<TData = GetSessionById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getSessionById<TData = GetSessionById200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/sessions/${id}`,
      options,
    );
  }

  getSessionByConfigId<TData = CdUpdateSessionWithDevicesDto[]>(
    id: number,
    params?: GetSessionByConfigIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getSessionByConfigId<TData = CdUpdateSessionWithDevicesDto[]>(
    id: number,
    params?: GetSessionByConfigIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getSessionByConfigId<TData = CdUpdateSessionWithDevicesDto[]>(
    id: number,
    params?: GetSessionByConfigIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getSessionByConfigId<TData = CdUpdateSessionWithDevicesDto[]>(
    id: number,
    params?: GetSessionByConfigIdParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/sessions/config/${id}`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllPatientProfileRules<TData = GetAllPatientProfileRules200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllPatientProfileRules<TData = GetAllPatientProfileRules200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllPatientProfileRules<TData = GetAllPatientProfileRules200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllPatientProfileRules<TData = GetAllPatientProfileRules200>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/rules`, options);
  }

  getPatientProfileRulesForDevice<TData = GetPatientProfileRulesForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPatientProfileRulesForDevice<TData = GetPatientProfileRulesForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPatientProfileRulesForDevice<TData = GetPatientProfileRulesForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPatientProfileRulesForDevice<TData = GetPatientProfileRulesForDevice200>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/rules/device/${deviceId}`,
      options,
    );
  }

  getReleaseNotesDownloadUrl<TData = GetReleaseNotesDownloadUrl200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getReleaseNotesDownloadUrl<TData = GetReleaseNotesDownloadUrl200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getReleaseNotesDownloadUrl<TData = GetReleaseNotesDownloadUrl200>(
    releaseId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getReleaseNotesDownloadUrl<TData = GetReleaseNotesDownloadUrl200>(
    releaseId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/releases/${releaseId}/release-notes/download`,
      options,
    );
  }

  getReleasesForCustomer<TData = GetReleasesForCustomer200>(
    params?: GetReleasesForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getReleasesForCustomer<TData = GetReleasesForCustomer200>(
    params?: GetReleasesForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getReleasesForCustomer<TData = GetReleasesForCustomer200>(
    params?: GetReleasesForCustomerParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getReleasesForCustomer<TData = GetReleasesForCustomer200>(
    params?: GetReleasesForCustomerParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/releases/customer`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllPortsWithoutGroup<TData = PagePort>(
    params?: GetAllPortsWithoutGroupParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllPortsWithoutGroup<TData = PagePort>(
    params?: GetAllPortsWithoutGroupParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllPortsWithoutGroup<TData = PagePort>(
    params?: GetAllPortsWithoutGroupParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllPortsWithoutGroup<TData = PagePort>(
    params?: GetAllPortsWithoutGroupParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/ports/no-location-group`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  exportPortsToCSV<TData = string>(
    params?: ExportPortsToCSVParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  exportPortsToCSV<TData = string>(
    params?: ExportPortsToCSVParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  exportPortsToCSV<TData = string>(
    params?: ExportPortsToCSVParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  exportPortsToCSV<TData = string>(
    params?: ExportPortsToCSVParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/ports/export`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getAllAssignedDevices<TData = number[]>(
    deviceProfileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllAssignedDevices<TData = number[]>(
    deviceProfileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllAssignedDevices<TData = number[]>(
    deviceProfileId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllAssignedDevices<TData = number[]>(
    deviceProfileId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/ports/device-profile/${deviceProfileId}`,
      options,
    );
  }

  getPortGroupForDeviceId<TData = GetPortGroupForDeviceId200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPortGroupForDeviceId<TData = GetPortGroupForDeviceId200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPortGroupForDeviceId<TData = GetPortGroupForDeviceId200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPortGroupForDeviceId<TData = GetPortGroupForDeviceId200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/port-groups/device-id/${id}`,
      options,
    );
  }

  getPatientProfile<TData = GetPatientProfile200>(
    id: number,
    params?: GetPatientProfileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPatientProfile<TData = GetPatientProfile200>(
    id: number,
    params?: GetPatientProfileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPatientProfile<TData = GetPatientProfile200>(
    id: number,
    params?: GetPatientProfileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPatientProfile<TData = GetPatientProfile200>(
    id: number,
    params?: GetPatientProfileParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/patientprofiles/${id}`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getAllOperators<TData = GetAllOperators200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllOperators<TData = GetAllOperators200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllOperators<TData = GetAllOperators200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllOperators<TData = GetAllOperators200>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/operators`,
      options,
    );
  }

  getLogcatDownloadUrl<TData = GetLogcatDownloadUrl200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLogcatDownloadUrl<TData = GetLogcatDownloadUrl200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLogcatDownloadUrl<TData = GetLogcatDownloadUrl200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLogcatDownloadUrl<TData = GetLogcatDownloadUrl200>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/monitoring/${deviceId}/logcat/download`,
      options,
    );
  }

  getDailyJournalDownloadUrl<TData = GetDailyJournalDownloadUrl200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDailyJournalDownloadUrl<TData = GetDailyJournalDownloadUrl200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDailyJournalDownloadUrl<TData = GetDailyJournalDownloadUrl200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDailyJournalDownloadUrl<TData = GetDailyJournalDownloadUrl200>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/monitoring/${deviceId}/dailyjournal/download`,
      options,
    );
  }

  getBridges<TData = GetBridges200>(
    params?: GetBridgesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getBridges<TData = GetBridges200>(
    params?: GetBridgesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getBridges<TData = GetBridges200>(
    params?: GetBridgesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getBridges<TData = GetBridges200>(
    params?: GetBridgesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/monitoring/infrastructure/bridges`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getBridge<TData = GetBridge200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getBridge<TData = GetBridge200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getBridge<TData = GetBridge200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getBridge<TData = GetBridge200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/monitoring/infrastructure/bridges/${id}`,
      options,
    );
  }

  getAllLicenseModels<TData = GetAllLicenseModels200>(
    params?: GetAllLicenseModelsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllLicenseModels<TData = GetAllLicenseModels200>(
    params?: GetAllLicenseModelsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllLicenseModels<TData = GetAllLicenseModels200>(
    params?: GetAllLicenseModelsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllLicenseModels<TData = GetAllLicenseModels200>(
    params?: GetAllLicenseModelsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/models`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getLicenseModelById<TData = GetLicenseModelById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLicenseModelById<TData = GetLicenseModelById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLicenseModelById<TData = GetLicenseModelById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLicenseModelById<TData = GetLicenseModelById200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/models/${id}`,
      options,
    );
  }

  getLocationListConfiguration<TData = LocationListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLocationListConfiguration<TData = LocationListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLocationListConfiguration<TData = LocationListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLocationListConfiguration<TData = LocationListConfiguration>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/config/location-list`,
      options,
    );
  }

  getLocationGroupListConfiguration<TData = LocationGroupListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLocationGroupListConfiguration<TData = LocationGroupListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLocationGroupListConfiguration<TData = LocationGroupListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLocationGroupListConfiguration<TData = LocationGroupListConfiguration>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/location/config/location-group-list`,
      options,
    );
  }

  getDeviceListConfig<TData = DeviceListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceListConfig<TData = DeviceListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceListConfig<TData = DeviceListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceListConfig<TData = DeviceListConfiguration>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/config/device-list`,
      options,
    );
  }

  getDeviceGroupListConfig<TData = DeviceGroupListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceGroupListConfig<TData = DeviceGroupListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceGroupListConfig<TData = DeviceGroupListConfiguration>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceGroupListConfig<TData = DeviceGroupListConfiguration>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/meta-data/asset/config/device-group-list`,
      options,
    );
  }

  getLldpLocation<TData = GetLldpLocation200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLldpLocation<TData = GetLldpLocation200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLldpLocation<TData = GetLldpLocation200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLldpLocation<TData = GetLldpLocation200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/locations/${id}`,
      options,
    );
  }

  getCategories<TData = GetCategories200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCategories<TData = GetCategories200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCategories<TData = GetCategories200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCategories<TData = GetCategories200>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/licenses/categories`,
      options,
    );
  }

  getAllEntries<TData = GetAllEntries200>(
    params?: GetAllEntriesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllEntries<TData = GetAllEntries200>(
    params?: GetAllEntriesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllEntries<TData = GetAllEntries200>(
    params?: GetAllEntriesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllEntries<TData = GetAllEntries200>(
    params?: GetAllEntriesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/history`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getById1<TData = HistoryContextDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getById1<TData = HistoryContextDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getById1<TData = HistoryContextDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getById1<TData = HistoryContextDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/history/${id}`,
      options,
    );
  }

  getNotifications<TData = UserFrontendNotificationDto[]>(
    params?: GetNotificationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getNotifications<TData = UserFrontendNotificationDto[]>(
    params?: GetNotificationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getNotifications<TData = UserFrontendNotificationDto[]>(
    params?: GetNotificationsParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getNotifications<TData = UserFrontendNotificationDto[]>(
    params?: GetNotificationsParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/frontend/notifications`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getNotificationById<TData = GetNotificationById200>(
    id: number,
    params: GetNotificationByIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getNotificationById<TData = GetNotificationById200>(
    id: number,
    params: GetNotificationByIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getNotificationById<TData = GetNotificationById200>(
    id: number,
    params: GetNotificationByIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getNotificationById<TData = GetNotificationById200>(
    id: number,
    params: GetNotificationByIdParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/frontend/notifications/${id}`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getFirewallResultList<TData = PageFwProbingResultOverviewDto>(
    params?: GetFirewallResultListParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getFirewallResultList<TData = PageFwProbingResultOverviewDto>(
    params?: GetFirewallResultListParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getFirewallResultList<TData = PageFwProbingResultOverviewDto>(
    params?: GetFirewallResultListParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getFirewallResultList<TData = PageFwProbingResultOverviewDto>(
    params?: GetFirewallResultListParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/firewall-probing/results`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getFirewallResult<TData = FwProbingResultOverviewDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getFirewallResult<TData = FwProbingResultOverviewDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getFirewallResult<TData = FwProbingResultOverviewDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getFirewallResult<TData = FwProbingResultOverviewDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/firewall-probing/results/${id}`,
      options,
    );
  }

  getPaymentConfigFeaturesForCustomer<
    TData = GetPaymentConfigFeaturesForCustomer200,
  >(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getPaymentConfigFeaturesForCustomer<
    TData = GetPaymentConfigFeaturesForCustomer200,
  >(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getPaymentConfigFeaturesForCustomer<
    TData = GetPaymentConfigFeaturesForCustomer200,
  >(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getPaymentConfigFeaturesForCustomer<
    TData = GetPaymentConfigFeaturesForCustomer200,
  >(options?: HttpClientOptions): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/features/payment`,
      options,
    );
  }

  getFeaturesByCustomerId<TData = FeatureNameDTO[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getFeaturesByCustomerId<TData = FeatureNameDTO[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getFeaturesByCustomerId<TData = FeatureNameDTO[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getFeaturesByCustomerId<TData = FeatureNameDTO[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/features/active`,
      options,
    );
  }

  getDistributorDetailData<TData = DistributorDataDto>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDistributorDetailData<TData = DistributorDataDto>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDistributorDetailData<TData = DistributorDataDto>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDistributorDetailData<TData = DistributorDataDto>(
    id: string,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/distributors/${id}/details`,
      options,
    );
  }

  getDeviceGroup<TData = DeviceGroup>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceGroup<TData = DeviceGroup>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceGroup<TData = DeviceGroup>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceGroup<TData = DeviceGroup>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/devices/${id}/device-group`,
      options,
    );
  }

  getDevicesWithoutGroup<TData = PageDevice>(
    params?: GetDevicesWithoutGroupParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDevicesWithoutGroup<TData = PageDevice>(
    params?: GetDevicesWithoutGroupParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDevicesWithoutGroup<TData = PageDevice>(
    params?: GetDevicesWithoutGroupParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDevicesWithoutGroup<TData = PageDevice>(
    params?: GetDevicesWithoutGroupParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/devices/no-device-group`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getDeviceByMac<TData = Device>(
    mac: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceByMac<TData = Device>(
    mac: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceByMac<TData = Device>(
    mac: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceByMac<TData = Device>(
    mac: string,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/devices/mac/${mac}`,
      options,
    );
  }

  exportDevicesToCsvFile<TData = string[]>(
    params?: ExportDevicesToCsvFileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  exportDevicesToCsvFile<TData = string[]>(
    params?: ExportDevicesToCsvFileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  exportDevicesToCsvFile<TData = string[]>(
    params?: ExportDevicesToCsvFileParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  exportDevicesToCsvFile<TData = string[]>(
    params?: ExportDevicesToCsvFileParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/devices/export`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getCommandForDevice<TData = GetCommandForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCommandForDevice<TData = GetCommandForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCommandForDevice<TData = GetCommandForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCommandForDevice<TData = GetCommandForDevice200>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device/command/${deviceId}`,
      options,
    );
  }

  getAllDeviceFamilies<TData = DeviceFamily[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllDeviceFamilies<TData = DeviceFamily[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllDeviceFamilies<TData = DeviceFamily[]>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllDeviceFamilies<TData = DeviceFamily[]>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-types/families`,
      options,
    );
  }

  getAllImages<TData = GetAllImages200>(
    params?: GetAllImagesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllImages<TData = GetAllImages200>(
    params?: GetAllImagesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllImages<TData = GetAllImages200>(
    params?: GetAllImagesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllImages<TData = GetAllImages200>(
    params?: GetAllImagesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/api/v1/device-images`, {
      ...options,
      params: { ...params, ...options?.params },
    });
  }

  getDeviceImage<TData = GetDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDeviceImage<TData = GetDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDeviceImage<TData = GetDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDeviceImage<TData = GetDeviceImage200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-images/${id}`,
      options,
    );
  }

  deleteDeviceImage<TData = DeleteDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDeviceImage<TData = DeleteDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDeviceImage<TData = DeleteDeviceImage200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDeviceImage<TData = DeleteDeviceImage200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/device-images/${id}`,
      options,
    );
  }

  getDownloadUrl<TData = GetDownloadUrl200>(
    id: number,
    params?: GetDownloadUrlParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDownloadUrl<TData = GetDownloadUrl200>(
    id: number,
    params?: GetDownloadUrlParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDownloadUrl<TData = GetDownloadUrl200>(
    id: number,
    params?: GetDownloadUrlParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDownloadUrl<TData = GetDownloadUrl200>(
    id: number,
    params?: GetDownloadUrlParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-images/${id}/download`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getNumberOfDevices<TData = DeviceCountWrapper>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getNumberOfDevices<TData = DeviceCountWrapper>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getNumberOfDevices<TData = DeviceCountWrapper>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getNumberOfDevices<TData = DeviceCountWrapper>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/device-groups/${id}/device-count`,
      options,
    );
  }

  getDistributor1<TData = Distributor>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getDistributor1<TData = Distributor>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getDistributor1<TData = Distributor>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getDistributor1<TData = Distributor>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/distributor`,
      options,
    );
  }

  getCustomerDetailData<TData = CustomerDataDto>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomerDetailData<TData = CustomerDataDto>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomerDetailData<TData = CustomerDataDto>(
    id: string,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomerDetailData<TData = CustomerDataDto>(
    id: string,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/details`,
      options,
    );
  }

  getCustomerBridge<TData = CustomerBridgeDto>(
    id: number,
    bridgeId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCustomerBridge<TData = CustomerBridgeDto>(
    id: number,
    bridgeId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCustomerBridge<TData = CustomerBridgeDto>(
    id: number,
    bridgeId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCustomerBridge<TData = CustomerBridgeDto>(
    id: number,
    bridgeId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/bridges/${bridgeId}`,
      options,
    );
  }

  deleteCustomerBridge<TData = void>(
    id: number,
    bridgeId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteCustomerBridge<TData = void>(
    id: number,
    bridgeId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteCustomerBridge<TData = void>(
    id: number,
    bridgeId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteCustomerBridge<TData = void>(
    id: number,
    bridgeId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/customers/${id}/bridges/${bridgeId}`,
      options,
    );
  }

  getAllCriterias<TData = GetAllCriterias200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllCriterias<TData = GetAllCriterias200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllCriterias<TData = GetAllCriterias200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllCriterias<TData = GetAllCriterias200>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/criterias`,
      options,
    );
  }

  getConfigJsonById<TData = GetConfigJsonById200>(
    id: number,
    params?: GetConfigJsonByIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getConfigJsonById<TData = GetConfigJsonById200>(
    id: number,
    params?: GetConfigJsonByIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getConfigJsonById<TData = GetConfigJsonById200>(
    id: number,
    params?: GetConfigJsonByIdParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getConfigJsonById<TData = GetConfigJsonById200>(
    id: number,
    params?: GetConfigJsonByIdParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/configs/${id}/json`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getLatestConfigInfoForDevice<TData = GetLatestConfigInfoForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getLatestConfigInfoForDevice<TData = GetLatestConfigInfoForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getLatestConfigInfoForDevice<TData = GetLatestConfigInfoForDevice200>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getLatestConfigInfoForDevice<TData = GetLatestConfigInfoForDevice200>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/configs/device/${deviceId}`,
      options,
    );
  }

  getAllCertificates<TData = PageDeviceCertificateDto>(
    params?: GetAllCertificatesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllCertificates<TData = PageDeviceCertificateDto>(
    params?: GetAllCertificatesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllCertificates<TData = PageDeviceCertificateDto>(
    params?: GetAllCertificatesParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllCertificates<TData = PageDeviceCertificateDto>(
    params?: GetAllCertificatesParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/certificate-management`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getCertificateById<TData = DeviceCertificateDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCertificateById<TData = DeviceCertificateDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCertificateById<TData = DeviceCertificateDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCertificateById<TData = DeviceCertificateDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/certificate-management/${id}`,
      options,
    );
  }

  deleteCertificatedById<TData = DeleteCertificatedById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteCertificatedById<TData = DeleteCertificatedById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteCertificatedById<TData = DeleteCertificatedById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteCertificatedById<TData = DeleteCertificatedById200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/certificate-management/${id}`,
      options,
    );
  }

  getCertificateByDeviceId<TData = DeviceCertificateDto[]>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getCertificateByDeviceId<TData = DeviceCertificateDto[]>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getCertificateByDeviceId<TData = DeviceCertificateDto[]>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getCertificateByDeviceId<TData = DeviceCertificateDto[]>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/certificate-management/device/${deviceId}`,
      options,
    );
  }

  getAnnouncementById<TData = AnnouncementDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAnnouncementById<TData = AnnouncementDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAnnouncementById<TData = AnnouncementDto>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAnnouncementById<TData = AnnouncementDto>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/announcement/${id}`,
      options,
    );
  }

  deleteAnnouncementById<TData = DeleteAnnouncementById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteAnnouncementById<TData = DeleteAnnouncementById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteAnnouncementById<TData = DeleteAnnouncementById200>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteAnnouncementById<TData = DeleteAnnouncementById200>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/announcement/${id}`,
      options,
    );
  }

  getAnnouncementByDeviceId<TData = AnnouncementDto[]>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAnnouncementByDeviceId<TData = AnnouncementDto[]>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAnnouncementByDeviceId<TData = AnnouncementDto[]>(
    deviceId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAnnouncementByDeviceId<TData = AnnouncementDto[]>(
    deviceId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/announcement/device/${deviceId}`,
      options,
    );
  }

  getOverview<TData = PageOverviewResultDto>(
    params?: GetOverviewParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getOverview<TData = PageOverviewResultDto>(
    params?: GetOverviewParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getOverview<TData = PageOverviewResultDto>(
    params?: GetOverviewParams,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getOverview<TData = PageOverviewResultDto>(
    params?: GetOverviewParams,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/admin/management-overview`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  }

  getApplicationHeal<TData = Status>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getApplicationHeal<TData = Status>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getApplicationHeal<TData = Status>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getApplicationHeal<TData = Status>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/admin/health`,
      options,
    );
  }

  getAllActivationOptions<TData = GetAllActivationOptions200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  getAllActivationOptions<TData = GetAllActivationOptions200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  getAllActivationOptions<TData = GetAllActivationOptions200>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  getAllActivationOptions<TData = GetAllActivationOptions200>(
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.get<TData>(
      `${environment.apiUrl}/api/v1/activation-options`,
      options,
    );
  }

  deletePropertyTemplate<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deletePropertyTemplate<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deletePropertyTemplate<TData = void>(
    id: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deletePropertyTemplate<TData = void>(
    id: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v2/profiletemplates/${id}`,
      options,
    );
  }

  cancelConfigUpdateSession<TData = CancelConfigUpdateSession200>(
    sessionId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  cancelConfigUpdateSession<TData = CancelConfigUpdateSession200>(
    sessionId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  cancelConfigUpdateSession<TData = CancelConfigUpdateSession200>(
    sessionId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  cancelConfigUpdateSession<TData = CancelConfigUpdateSession200>(
    sessionId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/sessions/${sessionId}`,
      options,
    );
  }

  deleteMultiplePorts<TData = void>(
    deleteMultiplePortsBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteMultiplePorts<TData = void>(
    deleteMultiplePortsBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteMultiplePorts<TData = void>(
    deleteMultiplePortsBody: number[],
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteMultiplePorts<TData = void>(
    deleteMultiplePortsBody: number[],
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/ports/delete`,
      { body: deleteMultiplePortsBody, ...options },
    );
  }

  deleteDeviceCommand<TData = void>(
    commandId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  deleteDeviceCommand<TData = void>(
    commandId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  deleteDeviceCommand<TData = void>(
    commandId: number,
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  deleteDeviceCommand<TData = void>(
    commandId: number,
    options?: HttpClientOptions,
  ): Observable<TData> {
    return this.http.delete<TData>(
      `${environment.apiUrl}/api/v1/device/command/${commandId}`,
      options,
    );
  }

  isOnline<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  isOnline<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  isOnline<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  isOnline<TData = void>(options?: HttpClientOptions): Observable<TData> {
    return this.http.get<TData>(`${environment.apiUrl}/online`, options);
  }

  isOnline3<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  isOnline3<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  isOnline3<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  isOnline3<TData = void>(options?: HttpClientOptions): Observable<TData> {
    return this.http.put<TData>(
      `${environment.apiUrl}/online`,
      undefined,
      options,
    );
  }

  isOnline2<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  isOnline2<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  isOnline2<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  isOnline2<TData = void>(options?: HttpClientOptions): Observable<TData> {
    return this.http.post<TData>(
      `${environment.apiUrl}/online`,
      undefined,
      options,
    );
  }

  isOnline5<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  isOnline5<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  isOnline5<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  isOnline5<TData = void>(options?: HttpClientOptions): Observable<TData> {
    return this.http.delete<TData>(`${environment.apiUrl}/online`, options);
  }

  isOnline1<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  isOnline1<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  isOnline1<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  isOnline1<TData = void>(options?: HttpClientOptions): Observable<TData> {
    return this.http.head<TData>(`${environment.apiUrl}/online`, options);
  }

  isOnline4<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'body' },
  ): Observable<TData>;
  isOnline4<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'response' },
  ): Observable<AngularHttpResponse<TData>>;
  isOnline4<TData = void>(
    options?: Omit<HttpClientOptions, 'observe'> & { observe?: 'events' },
  ): Observable<HttpEvent<TData>>;
  isOnline4<TData = void>(options?: HttpClientOptions): Observable<TData> {
    return this.http.patch<TData>(
      `${environment.apiUrl}/online`,
      undefined,
      options,
    );
  }
}

export type ExposeDenyErrorClientResult = NonNullable<ErrorEntity>;
export type ExposeDenyError2ClientResult = NonNullable<ErrorEntity>;
export type ExposeDenyError1ClientResult = NonNullable<ErrorEntity>;
export type ExposeDenyError3ClientResult = NonNullable<ErrorEntity>;
export type GetPropertyByIdClientResult = NonNullable<GetPropertyById200>;
export type UpdatePropertyByIdClientResult = NonNullable<UpdatePropertyById200>;
export type DeletePropertyByIdClientResult = NonNullable<DeletePropertyById200>;
export type UpdatePropertyById1ClientResult =
  NonNullable<UpdatePropertyById1200>;
export type BatchUpdatePropertyByIdClientResult =
  NonNullable<BatchUpdatePropertyById200>;
export type DeletePropertyByIdsClientResult =
  NonNullable<DeletePropertyByIds200>;
export type GetPropertyTemplatesClientResult = NonNullable<PropertyTemplateDto>;
export type PutProfilePropertyByPathClientResult =
  NonNullable<PropertyTemplateDto>;
export type AddPropertyTemplateClientResult = NonNullable<PropertyTemplateDto>;
export type GetPropertyTemplatesByIdClientResult =
  NonNullable<PropertyTemplateDto>;
export type PutPropertyTemplatesByIdClientResult =
  NonNullable<PropertyTemplateDto>;
export type GetAllProfilesClientResult = NonNullable<GetAllProfiles200>;
export type BulkProfilePropertyUpdateClientResult =
  NonNullable<BulkProfilePropertyUpdate200>;
export type CreateNewProfileClientResult = NonNullable<CreateNewProfile200>;
export type GetProfileClientResult = NonNullable<GetProfile200>;
export type UpdateProfileClientResult = NonNullable<UpdateProfile200>;
export type DeleteProfileClientResult = NonNullable<DeleteProfile200>;
export type PutWebAppDtoClientResult = NonNullable<WebAppDto>;
export type DeleteWebAppClientResult = NonNullable<void>;
export type PutUserClientResult = NonNullable<void>;
export type DeleteUserClientResult = NonNullable<void>;
export type ReinstallDeviceSoftwareClientResult = NonNullable<void>;
export type ReinstallDeviceProfileClientResult = NonNullable<void>;
export type GetPatientProfileRulesClientResult =
  NonNullable<GetPatientProfileRules200>;
export type UpdatePatientProfileRuleClientResult =
  NonNullable<UpdatePatientProfileRule200>;
export type DeletePatientProfileRuleClientResult =
  NonNullable<DeletePatientProfileRule200>;
export type GetPatientProfileRulesForConfigIdClientResult =
  NonNullable<GetPatientProfileRulesForConfigId200>;
export type UpdatePatientProfileRulesSingleUpdateClientResult =
  NonNullable<UpdatePatientProfileRulesSingleUpdate200>;
export type CreatePatientProfileRuleClientResult =
  NonNullable<CreatePatientProfileRule200>;
export type GetAllReleasesPageableClientResult =
  NonNullable<GetAllReleasesPageable200>;
export type PutReleaseClientResult = NonNullable<PutRelease200>;
export type PostReleaseClientResult = NonNullable<PostRelease200>;
export type GetReleaseClientResult = NonNullable<GetRelease200>;
export type PutRelease1ClientResult = NonNullable<PutRelease1200>;
export type DeleteReleaseClientResult = NonNullable<DeleteRelease202>;
export type GetPortByIdClientResult = NonNullable<PortDto>;
export type PutPortClientResult = NonNullable<PortDto>;
export type DeletePortClientResult = NonNullable<void>;
export type GetByIdClientResult = NonNullable<PortGroup>;
export type PutGroupClientResult = NonNullable<PortGroup>;
export type DeleteGroupClientResult = NonNullable<void>;
export type GetLocationsClientResult = NonNullable<PagePort>;
export type PutPortLocationsClientResult = NonNullable<void>;
export type GetCustomerLicensesInLicenseModelByCustomerIdClientResult =
  NonNullable<GetCustomerLicensesInLicenseModelByCustomerId200>;
export type UpdateLicenseModelDataForCustomerClientResult =
  NonNullable<UpdateLicenseModelDataForCustomer200>;
export type CreateLicenseModelDataClientResult =
  NonNullable<CreateLicenseModelData200>;
export type DeleteLicenseModelDataForCustomerClientResult =
  NonNullable<DeleteLicenseModelDataForCustomer200>;
export type GetTableConfigurationsClientResult =
  NonNullable<LocationTableConfiguration>;
export type UpdateTableConfigurationsClientResult =
  NonNullable<LocationTableConfiguration>;
export type GetFilterCategoryClientResult = NonNullable<FilterConfiguration>;
export type CreateFilterCategoryClientResult = NonNullable<FilterConfiguration>;
export type GetFilterCategory1ClientResult = NonNullable<FilterConfiguration>;
export type CreateFilterCategory1ClientResult =
  NonNullable<FilterConfiguration>;
export type DeleteDeviceFilterConfigurationClientResult = NonNullable<void>;
export type GetDeviceTableConfigurationsClientResult =
  NonNullable<DeviceTableConfig>;
export type UpdateDeviceTableConfigurationsClientResult =
  NonNullable<DeviceTableConfig>;
export type DeleteDeviceTableConfigurationClientResult = NonNullable<void>;
export type PutReadStatusNotificationByIdClientResult = NonNullable<void>;
export type DeleteReadStatusNotificationByIdClientResult = NonNullable<void>;
export type PutPinStatusNotificationByIdClientResult = NonNullable<void>;
export type DeletePinStatusNotificationByIdClientResult = NonNullable<void>;
export type GetFeatureClientResult = NonNullable<FullFeatureDTO>;
export type UpdateFeatureClientResult = NonNullable<FullFeatureDTO>;
export type DeleteFeatureClientResult = NonNullable<void>;
export type GetDistributorClientResult = NonNullable<Distributor>;
export type PutDistributorClientResult = NonNullable<Distributor>;
export type DeleteDistributorClientResult = NonNullable<void>;
export type GetDevicesForDistributorClientResult = NonNullable<PageDevice>;
export type AssociateDevicesToDistributorClientResult = NonNullable<void>;
export type PutContactForDistributorClientResult =
  NonNullable<PutContactForDistributor200>;
export type DeleteContactForDistributorClientResult = NonNullable<void>;
export type GetDeviceClientResult = NonNullable<GetDevice200>;
export type PutDeviceClientResult = NonNullable<Device>;
export type DeleteDeviceClientResult = NonNullable<void>;
export type PutDeviceTestStatusClientResult = NonNullable<void>;
export type RevokeCustomerAssociationClientResult = NonNullable<void>;
export type PutDeviceLockClientResult = NonNullable<void>;
export type DeleteDeviceLockClientResult = NonNullable<void>;
export type GetDeviceTypeByIdClientResult = NonNullable<DeviceType>;
export type PutDeviceTypeClientResult = NonNullable<DeviceType>;
export type PutDeviceImageClientResult = NonNullable<PutDeviceImage200>;
export type GetDeviceByIdClientResult = NonNullable<GetDeviceById200>;
export type PutDeviceGroupClientResult = NonNullable<DeviceGroup>;
export type DeleteDeviceGroupClientResult = NonNullable<void>;
export type GetGroupDevicesClientResult = NonNullable<PageDevice>;
export type PutDevicesClientResult = NonNullable<void>;
export type GetCustomerClientResult = NonNullable<CustomerFullDto>;
export type PutCustomerClientResult = NonNullable<CustomerFullDto>;
export type DeleteCustomerClientResult = NonNullable<void>;
export type AssociatePortsToCustomerClientResult = NonNullable<void>;
export type PutDistributorForCustomerClientResult = NonNullable<void>;
export type GetDevicesForCustomerClientResult = NonNullable<PageDevice>;
export type AssociateDevicesToCustomerClientResult = NonNullable<void>;
export type AssociateDeviceGroupToCustomerClientResult = NonNullable<void>;
export type PutContactForCustomerClientResult =
  NonNullable<PutContactForCustomer200>;
export type DeleteContactForCustomerClientResult = NonNullable<void>;
export type UpdatePatientProfileClientResult =
  NonNullable<UpdatePatientProfile200>;
export type RemovePatientProfileClientResult =
  NonNullable<RemovePatientProfile200>;
export type UpdateCustomerPatientProfileOrderClientResult =
  NonNullable<UpdateCustomerPatientProfileOrder200>;
export type GetAllAnnouncementsClientResult = NonNullable<PageAnnouncementDto>;
export type UpdateAnnouncementClientResult = NonNullable<AnnouncementDto>;
export type CreateAnnouncementClientResult = NonNullable<AnnouncementDto>;
export type PutNotificationByIdClientResult =
  NonNullable<FrontendNotificationDto>;
export type DeleteNotificationByIdClientResult = NonNullable<void>;
export type GetProfilePropertiesClientResult =
  NonNullable<GetProfileProperties200>;
export type AddProfilePropertiesClientResult =
  NonNullable<AddProfileProperties200>;
export type AddProfilePropertiesFromTemplateClientResult =
  NonNullable<AddProfilePropertiesFromTemplate200>;
export type AddProfilePropertiesListClientResult =
  NonNullable<AddProfilePropertiesList200>;
export type StartMigrationOfProfilesClientResult =
  NonNullable<StartMigrationOfProfiles200>;
export type ImportProfileFromFileClientResult =
  NonNullable<ImportProfileFromFile200>;
export type CloneProfileByIdClientResult = NonNullable<CloneProfileById200>;
export type GetAllWebAppsClientResult = NonNullable<GetAllWebApps200>;
export type PostWebAppClientResult = NonNullable<WebAppDto>;
export type PostLocationUpdateSessionClientResult =
  NonNullable<UpdateSessionDto>;
export type PostDeviceUpdateSessionClientResult = NonNullable<UpdateSessionDto>;
export type RestartSingleDeviceUpdateClientResult = NonNullable<Device>;
export type PostBulkUpdateClientResult = NonNullable<UpdateSessionDto>;
export type CreateAndStartConfigUpdateSessionClientResult =
  NonNullable<CreateAndStartConfigUpdateSession200>;
export type ProcessBatchConfigUpdateClientResult =
  NonNullable<ProcessBatchConfigUpdate200>;
export type CheckForMissingRolesClientResult = NonNullable<string[]>;
export type GetReleaseNotesClientResult = NonNullable<GetReleaseNotes200>;
export type PostReleaseNotesClientResult = NonNullable<void>;
export type GetImagesForReleaseClientResult =
  NonNullable<GetImagesForRelease200>;
export type PostImageForReleaseClientResult =
  NonNullable<PostImageForRelease200>;
export type PostReleaseForCustomerClientResult =
  NonNullable<PostReleaseForCustomer200>;
export type RegisterSipStateClientResult = NonNullable<void>;
export type RegisterPatientClientResult = NonNullable<void>;
export type RegisterPhysicalLocationClientResult =
  NonNullable<RegisterPhysicalLocation200>;
export type RegisterDeviceClientResult = NonNullable<RegisterDevice200>;
export type GetAllPortsClientResult = NonNullable<GetAllPorts200>;
export type PostPortClientResult = NonNullable<Port>;
export type ImportPropertiesLocationsClientResult =
  NonNullable<PortImportResultDto>;
export type ImportLocationsClientResult = NonNullable<PortImportResultDto>;
export type PostLocationsForDevicesClientResult = NonNullable<Port[]>;
export type GetAllGroupsClientResult = NonNullable<PageObject>;
export type PostGroupClientResult = NonNullable<PostGroup200>;
export type SendPushNotificationToDeviceClientResult = NonNullable<void>;
export type SendPushNotificationsClientResult = NonNullable<void>;
export type SendPushNotificationToLocationGroupClientResult = NonNullable<void>;
export type SendPushNotificationToDeviceGroupClientResult = NonNullable<void>;
export type CreateLogcatFileClientResult = NonNullable<void>;
export type DeleteLogcatFileClientResult = NonNullable<void>;
export type CreateDailyJournalClientResult = NonNullable<void>;
export type DeleteDailyJournalClientResult = NonNullable<void>;
export type PostLogcatFileClientResult = NonNullable<void>;
export type PostDailyJournalClientResult = NonNullable<void>;
export type GetAllTableConfigurationsClientResult = NonNullable<
  LocationTableConfiguration[]
>;
export type CreateTableConfigurationClientResult =
  NonNullable<LocationTableConfiguration>;
export type GetAllFilterCategoriesClientResult = NonNullable<
  FilterConfiguration[]
>;
export type CreateFilterCategory2ClientResult =
  NonNullable<FilterConfiguration>;
export type GetAllFilterCategories1ClientResult = NonNullable<
  FilterConfiguration[]
>;
export type CreateFilterCategory3ClientResult =
  NonNullable<FilterConfiguration>;
export type GetAllDeviceTableConfigurationsClientResult = NonNullable<
  DeviceTableConfig[]
>;
export type CreateDeviceTableConfigurationClientResult =
  NonNullable<DeviceTableConfig>;
export type GetAllLldpLocationsClientResult = NonNullable<PagePhysicalLocation>;
export type PostLocationClientResult = NonNullable<PostLocation200>;
export type PostLocationForPortClientResult =
  NonNullable<PostLocationForPort200>;
export type MigratePropertiesClientResult = NonNullable<void>;
export type PostProbingRequestByProbeResultIdClientResult =
  NonNullable<FwProbingStartResponse>;
export type PostProbingRequestAllClientResult = NonNullable<void>;
export type PostFeedbackClientResult = NonNullable<void>;
export type GetAllFeaturesClientResult = NonNullable<PageFeatureDTO>;
export type CreateFeatureClientResult = NonNullable<FullFeatureDTO>;
export type GetAllClientResult = NonNullable<PageDistributor>;
export type PostDistributorClientResult = NonNullable<Distributor>;
export type GetUsersForDistributorClientResult =
  NonNullable<PageKeycloakUserDto>;
export type PostUserForDistributorClientResult =
  NonNullable<PostUserForDistributor200>;
export type GetCustomersClientResult = NonNullable<Customer[]>;
export type PostCustomerForDistributorClientResult = NonNullable<Customer>;
export type GetContactsForDistributorClientResult =
  NonNullable<GetContactsForDistributor200>;
export type PostContactForDistributorClientResult =
  NonNullable<PostContactForDistributor200>;
export type PostImportClientResult = NonNullable<Distributor[]>;
export type CheckGroupsForDistributorsClientResult = NonNullable<void>;
export type CheckDistributorUsersGroupAttributesClientResult =
  NonNullable<void>;
export type GetAllDevicesClientResult = NonNullable<FullDeviceEntity[]>;
export type PostDeviceClientResult = NonNullable<Device>;
export type RestartDeviceClientResult = NonNullable<void>;
export type RestartDevice1ClientResult = NonNullable<void>;
export type ImportDevicesClientResult = NonNullable<ImportedDevice[]>;
export type GetAllCommandsClientResult = NonNullable<DeviceCommand[]>;
export type PostResponseFromDeviceClientResult = NonNullable<void>;
export type GetAllDeviceTypesClientResult = NonNullable<PageDeviceType>;
export type PostDeviceTypeClientResult = NonNullable<DeviceType>;
export type GetAllGroups1ClientResult = NonNullable<PageObject>;
export type PostDeviceGroupClientResult = NonNullable<DeviceGroup>;
export type GetCustomers1ClientResult = NonNullable<PageCustomerFullDto>;
export type PostCustomerClientResult = NonNullable<Customer>;
export type GetUsersForCustomerClientResult = NonNullable<PageKeycloakUserDto>;
export type PostUserForCustomerClientResult =
  NonNullable<PostUserForCustomer200>;
export type GetContactsForCustomerClientResult =
  NonNullable<GetContactsForCustomer200>;
export type PostContactForCustomerClientResult =
  NonNullable<PostContactForCustomer200>;
export type GetCustomerBridgesClientResult = NonNullable<GetCustomerBridges200>;
export type AddCustomerBridgeClientResult = NonNullable<Bridge>;
export type CheckGroupsForCustomersClientResult = NonNullable<void>;
export type GetCustomerPatientProfilesClientResult =
  NonNullable<GetCustomerPatientProfiles200>;
export type CreatePatientProfileClientResult =
  NonNullable<CreatePatientProfile200>;
export type ClonePatientProfileClientResult =
  NonNullable<ClonePatientProfile200>;
export type ImportCertificateFromFileClientResult =
  NonNullable<ImportCertificateFromFile200>;
export type GetAllNotificationsClientResult =
  NonNullable<PageFrontendNotificationDto>;
export type PostNotificationClientResult = NonNullable<void>;
export type GetProfileJsonClientResult = NonNullable<GetProfileJson200>;
export type GetLatestProfileInfoForDeviceClientResult =
  NonNullable<GetLatestProfileInfoForDevice200>;
export type GetAllUsersClientResult = NonNullable<GetAllUsers200>;
export type GetAllUpdateSessionsClientResult =
  NonNullable<PageUpdateSessionDto>;
export type GetDeviceForUpdateSessionClientResult =
  NonNullable<GetDeviceForUpdateSession200>;
export type GetUpdateSessionClientResult = NonNullable<UpdateSessionDto>;
export type DeleteUpdateSessionClientResult = NonNullable<void>;
export type GetUpdateSessionDeviceStatusClientResult =
  NonNullable<GetUpdateSessionDeviceStatus200>;
export type GetUpdateSessionStatsClientResult =
  NonNullable<GetUpdateSessionStats200>;
export type GetUpdateSessionHistoryClientResult =
  NonNullable<GetUpdateSessionHistory200>;
export type GetDevicesForUpdateSessionClientResult = NonNullable<
  UpdateSessionDeviceDetailsDto[]
>;
export type GetUpdateHistoryClientResult = NonNullable<PageUpdateSessionDto>;
export type GetDeviceSWVersionsClientResult = NonNullable<StatisticsDTO>;
export type GetAllSessionsClientResult = NonNullable<PageListUpdateSessionDto>;
export type GetSessionDevicesBySessionIdClientResult = NonNullable<
  ListDeviceDto[]
>;
export type GetDeviceForSessionClientResult = NonNullable<ListDeviceDto>;
export type GetSessionByIdClientResult = NonNullable<GetSessionById200>;
export type GetSessionByConfigIdClientResult = NonNullable<
  CdUpdateSessionWithDevicesDto[]
>;
export type GetAllPatientProfileRulesClientResult =
  NonNullable<GetAllPatientProfileRules200>;
export type GetPatientProfileRulesForDeviceClientResult =
  NonNullable<GetPatientProfileRulesForDevice200>;
export type GetReleaseNotesDownloadUrlClientResult =
  NonNullable<GetReleaseNotesDownloadUrl200>;
export type GetReleasesForCustomerClientResult =
  NonNullable<GetReleasesForCustomer200>;
export type GetAllPortsWithoutGroupClientResult = NonNullable<PagePort>;
export type ExportPortsToCSVClientResult = NonNullable<string>;
export type GetAllAssignedDevicesClientResult = NonNullable<number[]>;
export type GetPortGroupForDeviceIdClientResult =
  NonNullable<GetPortGroupForDeviceId200>;
export type GetPatientProfileClientResult = NonNullable<GetPatientProfile200>;
export type GetAllOperatorsClientResult = NonNullable<GetAllOperators200>;
export type GetLogcatDownloadUrlClientResult =
  NonNullable<GetLogcatDownloadUrl200>;
export type GetDailyJournalDownloadUrlClientResult =
  NonNullable<GetDailyJournalDownloadUrl200>;
export type GetBridgesClientResult = NonNullable<GetBridges200>;
export type GetBridgeClientResult = NonNullable<GetBridge200>;
export type GetAllLicenseModelsClientResult =
  NonNullable<GetAllLicenseModels200>;
export type GetLicenseModelByIdClientResult =
  NonNullable<GetLicenseModelById200>;
export type GetLocationListConfigurationClientResult =
  NonNullable<LocationListConfiguration>;
export type GetLocationGroupListConfigurationClientResult =
  NonNullable<LocationGroupListConfiguration>;
export type GetDeviceListConfigClientResult =
  NonNullable<DeviceListConfiguration>;
export type GetDeviceGroupListConfigClientResult =
  NonNullable<DeviceGroupListConfiguration>;
export type GetLldpLocationClientResult = NonNullable<GetLldpLocation200>;
export type GetCategoriesClientResult = NonNullable<GetCategories200>;
export type GetAllEntriesClientResult = NonNullable<GetAllEntries200>;
export type GetById1ClientResult = NonNullable<HistoryContextDto>;
export type GetNotificationsClientResult = NonNullable<
  UserFrontendNotificationDto[]
>;
export type GetNotificationByIdClientResult =
  NonNullable<GetNotificationById200>;
export type GetFirewallResultListClientResult =
  NonNullable<PageFwProbingResultOverviewDto>;
export type GetFirewallResultClientResult =
  NonNullable<FwProbingResultOverviewDto>;
export type GetPaymentConfigFeaturesForCustomerClientResult =
  NonNullable<GetPaymentConfigFeaturesForCustomer200>;
export type GetFeaturesByCustomerIdClientResult = NonNullable<FeatureNameDTO[]>;
export type GetDistributorDetailDataClientResult =
  NonNullable<DistributorDataDto>;
export type GetDeviceGroupClientResult = NonNullable<DeviceGroup>;
export type GetDevicesWithoutGroupClientResult = NonNullable<PageDevice>;
export type GetDeviceByMacClientResult = NonNullable<Device>;
export type ExportDevicesToCsvFileClientResult = NonNullable<string[]>;
export type GetCommandForDeviceClientResult =
  NonNullable<GetCommandForDevice200>;
export type GetAllDeviceFamiliesClientResult = NonNullable<DeviceFamily[]>;
export type GetAllImagesClientResult = NonNullable<GetAllImages200>;
export type GetDeviceImageClientResult = NonNullable<GetDeviceImage200>;
export type DeleteDeviceImageClientResult = NonNullable<DeleteDeviceImage200>;
export type GetDownloadUrlClientResult = NonNullable<GetDownloadUrl200>;
export type GetNumberOfDevicesClientResult = NonNullable<DeviceCountWrapper>;
export type GetDistributor1ClientResult = NonNullable<Distributor>;
export type GetCustomerDetailDataClientResult = NonNullable<CustomerDataDto>;
export type GetCustomerBridgeClientResult = NonNullable<CustomerBridgeDto>;
export type DeleteCustomerBridgeClientResult = NonNullable<void>;
export type GetAllCriteriasClientResult = NonNullable<GetAllCriterias200>;
export type GetConfigJsonByIdClientResult = NonNullable<GetConfigJsonById200>;
export type GetLatestConfigInfoForDeviceClientResult =
  NonNullable<GetLatestConfigInfoForDevice200>;
export type GetAllCertificatesClientResult =
  NonNullable<PageDeviceCertificateDto>;
export type GetCertificateByIdClientResult = NonNullable<DeviceCertificateDto>;
export type DeleteCertificatedByIdClientResult =
  NonNullable<DeleteCertificatedById200>;
export type GetCertificateByDeviceIdClientResult = NonNullable<
  DeviceCertificateDto[]
>;
export type GetAnnouncementByIdClientResult = NonNullable<AnnouncementDto>;
export type DeleteAnnouncementByIdClientResult =
  NonNullable<DeleteAnnouncementById200>;
export type GetAnnouncementByDeviceIdClientResult = NonNullable<
  AnnouncementDto[]
>;
export type GetOverviewClientResult = NonNullable<PageOverviewResultDto>;
export type GetApplicationHealClientResult = NonNullable<Status>;
export type GetAllActivationOptionsClientResult =
  NonNullable<GetAllActivationOptions200>;
export type DeletePropertyTemplateClientResult = NonNullable<void>;
export type CancelConfigUpdateSessionClientResult =
  NonNullable<CancelConfigUpdateSession200>;
export type DeleteMultiplePortsClientResult = NonNullable<void>;
export type DeleteDeviceCommandClientResult = NonNullable<void>;
export type IsOnlineClientResult = NonNullable<void>;
export type IsOnline3ClientResult = NonNullable<void>;
export type IsOnline2ClientResult = NonNullable<void>;
export type IsOnline5ClientResult = NonNullable<void>;
export type IsOnline1ClientResult = NonNullable<void>;
export type IsOnline4ClientResult = NonNullable<void>;
