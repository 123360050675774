import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './services/authGuard/authGuard.service';
import { NetworkAwarePreloadingStrategyService } from './services/preloading/networkAware.preloading.service';

export interface RouteData {
  preload?: boolean;
  needCustomer: boolean;
  roles: ('Customer' | 'Distributor' | 'Bewatec_Admin' | 'Super_Admin')[];
  breadcrumb: {
    name: string;
    icon?: string;
  };
  neededLicense?: number;
  role?: string;
}

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: {
        name: 'Home',
        icon: 'home',
      },
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          role: 'none',
        },
      },
      {
        path: 'deviceprofiles',
        loadChildren: () =>
          import('./modules/device-profiles/device-profiles.module').then(
            (m) => m.DeviceProfilesModule,
          ),
        data: {
          preload: true,
          needCustomer: false,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.DEVICE_PROFILES',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'devices',
        loadChildren: () =>
          import('./modules/device-management/devicemanagement.module').then(
            (m) => m.DeviceManagementModule,
          ),
        data: {
          needCustomer: false,
          preload: true,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.DEVICES',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'device-groups',
        loadChildren: () =>
          import('./modules/device-group/device-group.module').then(
            (m) => m.DeviceGroupModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.DEVICE_GROUPS',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'locations',
        loadChildren: () =>
          import(
            './modules/location-management/location-management.module'
          ).then((m) => m.LocationManagementModule),
        data: {
          needCustomer: true,
          preload: true,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.LOCATIONS',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'location-groups',
        loadChildren: () =>
          import('./modules/location-groups/location-groups.module').then(
            (m) => m.LocationGroupsModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.LOCATION_GROUPS',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./modules/content/content.module').then(
            (m) => m.ContentModule,
          ),
        data: {
          needCustomer: true,
          roles: ['Bewatec_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.CONTENT',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./modules/applications/applications.module').then(
            (m) => m.ApplicationsModule,
          ),
        data: {
          needCustomer: true,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.APPS',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'patientprofiles',
        loadChildren: () =>
          import('./modules/patient-profiles/patient-profiles.module').then(
            (m) => m.PatientProfilesModule,
          ),
        data: {
          needCustomer: true,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.PATIENT_PROFILES',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'certificate-management',
        loadChildren: () =>
          import(
            './modules/certificate-management/certificate-management.module'
          ).then((m) => m.CertificateManagementModule),
        data: {
          needCustomer: true,
          roles: ['Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.CERTIFICATE_MANAGEMENT',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'customers',
        loadChildren: () =>
          import(
            './modules/customer-management/customer-management.module'
          ).then((m) => m.CustomerManagementModule),
        data: {
          needCustomer: false,
          roles: ['Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.CUSTOMERS',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'distributors',
        loadChildren: () =>
          import('./modules/distributors/distributors.module').then(
            (m) => m.DistributorsModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.DISTRIBUTORS',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'licensemanagement',
        loadChildren: () =>
          import('./modules/license/license-management.module').then(
            (m) => m.LicenseManagementModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Distributor'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.LICENSE_MANAGEMENT',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'releasemanagement',
        loadChildren: () =>
          import('./modules/release-management/release-management.module').then(
            (m) => m.ReleaseManagementModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Customer', 'Distributor'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.RELEASE_MANAGEMENT',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'update',
        loadChildren: () =>
          import('./modules/device-update/device-update.module').then(
            (m) => m.DeviceUpdateModule,
          ),
        data: {
          needCustomer: true,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Distributor', 'Customer'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.DEVICE_IMAGE',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'usermanagement',
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Distributor', 'Customer'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.USER_MANAGEMENT',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./modules/administration/administration.module').then(
            (m) => m.AdministrationModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.ADMINISTRATION',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'userhistory',
        loadChildren: () =>
          import('./modules/history/history.module').then(
            (m) => m.HistoryModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.USER_HISTORY',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'bridges',
        loadChildren: () =>
          import('./modules/bridges/bridges.module').then(
            (m) => m.BridgesModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Distributor'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.BRIDGES',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'management/overview',
        loadChildren: () =>
          import(
            './modules/management-overview/management-overview.module'
          ).then((m) => m.ManagementOverviewModule),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Distributor'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.MANAGEMENT_OVERVIEW',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'management/firewall-monitor',
        loadChildren: () =>
          import('./modules/firewall-monitor/firewall-monitor.module').then(
            (m) => m.FirewallMonitorModule,
          ),
        data: {
          needCustomer: false,
          roles: ['Bewatec_Admin', 'Super_Admin', 'Distributor'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.MANAGEMENT_MONITOR',
          },
        },
        canActivate: [AuthGuardService],
      },
      {
        path: 'announcement',
        loadChildren: () =>
          import('./modules/announcement/announcement.module').then(
            (m) => m.AnnouncementModule,
          ),
        data: {
          needCustomer: true,
          roles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
          breadcrumb: {
            name: 'HEADER.BREADCRUMBS.ANNOUNCEMENT',
          },
          neededLicense: 227,
        },
        canActivate: [AuthGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NetworkAwarePreloadingStrategyService,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AppRoutingModule {
  constructor(translate: TranslateService) {}
}
