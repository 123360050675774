import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AbstractService } from '../abstract/abstract.service';

@Injectable({ providedIn: 'root' })
export class ProfileService extends AbstractService {
  getUpdateSessions(page: string, size: string) {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('sort', 'created,desc');

    return this.httpClient
      .get(
        environment.configuration_management.root +
          environment.configuration_management.sessions,
        options,
      )
      .pipe(catchError(this.handleError));
  }
}
