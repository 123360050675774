import { getActiveLanguageSelect } from '@/app/external-modules/header/reducers/module';
import { Component, Injector } from '@angular/core';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BewatecDatetimeAdapter } from './../../services/datetimeAdapter/datetimeAdapter.service';

@UntilDestroy()
@Component({ template: '' })
export class TranslateLoadingBaseComponent {
  private readonly __translate: TranslateService;
  private readonly __store: Store<any>;
  private readonly __datetimeAdapter: BewatecDatetimeAdapter;

  constructor(injector: Injector) {
    this.__translate = injector.get(TranslateService);
    this.__store = injector.get(Store);

    this.__datetimeAdapter = <BewatecDatetimeAdapter>(
      injector.get(DatetimeAdapter, null)
    );

    this.__translate.setDefaultLang('de-DE');

    this.__store
      .select(getActiveLanguageSelect)
      .pipe(untilDestroyed(this))
      .subscribe((activeLanguage) => {
        if (activeLanguage) {
          this.__translate.use(activeLanguage.code);
          if (this.__datetimeAdapter) {
            this.__datetimeAdapter.setLocale(activeLanguage.code);
          }
        }
      });
  }
}
