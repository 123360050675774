import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { AbstractService } from '../../../../../services/abstract/abstract.service';

@Injectable({ providedIn: 'root' })
export class CustomerService extends AbstractService {
  // **************** CUSTOMERS SECTION ***************************

  getCustomers(): Observable<any> {
    const url =
      environment.customer_data_service.root +
      environment.customer_data_service.customers;
    const concatSymbol = '?';

    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    const getReq = new HttpRequest('GET', url, options);
    (getReq as any)['intercept'] = false;

    return this.httpClient.request(getReq).pipe(catchError(this.handleError));
  }

  // ************* GENERAL FUNCTIONS **************************************

  override handleError(error: Response | any): Observable<any> {
    const objError: { status: string; title?: string; text?: string } = {
      status: error.status,
      title: 'Error',
      text: 'Error',
    };
    return throwError(() => objError);
  }
}
