import { CustomerFullDto } from '@/openapi';
import {
  AfterContentChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { combineLatest, of } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { UpdateLicense } from './actions/license.action';
import { TranslateReady } from './actions/translate.action';
import { RouteData } from './app-routing.module';
import { ReceivedHeaderEvent } from './external-modules/header/actions/header';
import {
  UpdateActiveLanguage,
  UpdateAvailableLanguages,
} from './external-modules/header/actions/language';
import { UpdateRedirectUrl } from './external-modules/header/actions/login';
import { UpdateSelectedCustomer } from './external-modules/header/actions/select';
import { EventType } from './external-modules/header/models/header-event';
import { ILanguage } from './external-modules/header/models/language';
import {
  getActiveLanguageSelect,
  getHeaderEventSelect,
  getLoggedInSelect,
  getManualRequestSelect,
  getRedirectUrlSelect,
  getSelectedCustomerSelect,
} from './external-modules/header/reducers/module';
import {
  NotificationAction,
  NotificationListAction,
  NotificationReadAction,
  NotificationType,
} from './external-modules/notifications/actions/notification.action';
import { INotification } from './external-modules/notifications/interfaces/notification.interface';
import {
  getPinnedNotificationSelect,
  getReadNotificationSelect,
} from './external-modules/notifications/reducers/module';
import { NotificationsLibService } from './external-modules/notifications/services/notifications.service';
import { Logger } from './external-modules/utils/logger/logger.service';
import { UtilsService } from './external-modules/utils/utils.service';
import { ChooseCustomerDialogComponent } from './modules/library/components/dialogs/choose-customer/choose-customer-dialog.component';
import { getBackgroundSelect } from './reducer/root.reducer';
import { CustomerManagementService } from './services/customers/customer-management.service';
import { LicenseService } from './services/license.service';
import { LoginService } from './services/login/login.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { WebsocketService } from './services/websocket/websocket.service';

export enum GlobalRoles {
  BEWATEC_ADMIN = 'Bewatec_Admin',
}

export const authConfig: AuthConfig = {
  clientId: environment.clientId,
  redirectUri:
    window.location.origin +
    (window.location.search ? window.location.search : ''),
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  scope: 'openid',
  oidc: true,
  requireHttps: false,
  issuer: environment.authEndpoint,
  sessionChecksEnabled: true,
  clearHashAfterLogin: false,
};

@UntilDestroy()
@Component({
  selector: 'bewa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked, OnInit {
  @ViewChild('header', { read: ElementRef, static: true }) header: ElementRef;
  @ViewChild('colorLine', { static: true }) colorLine: ElementRef;
  headerHeight: number;

  private routeData?: RouteData;

  protected background: string;

  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private loginService: LoginService,
    private oauthService: OAuthService,
    private customerService: CustomerManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private ccService: NgcCookieConsentService,
    private utils: UtilsService,
    private notificationsService: NotificationsService,
    private notificationsLibService: NotificationsLibService,
    private logger: Logger,
    private websocket: WebsocketService,
    private licenseService: LicenseService,
    private titleService: Title,
  ) {
    translate.setDefaultLang(this.getActiveLanguage().code);
    // TODO: Get available languages
    this.store.dispatch(
      new UpdateAvailableLanguages([
        { code: 'de-DE', name: 'Deutsch' },
        { code: 'en-UK', name: 'English' },
      ]),
    );
    this.store.dispatch(new UpdateActiveLanguage(this.getActiveLanguage()));

    this.store
      .select(getActiveLanguageSelect)
      .pipe(
        untilDestroyed(this),
        concatMap((activeLanguage) => {
          this.store.dispatch(new TranslateReady(false));
          if (activeLanguage) {
            const expirationDate = new Date();
            expirationDate.setFullYear(expirationDate.getFullYear() + 1);
            this.cookieService.set(
              'language',
              JSON.stringify(activeLanguage),
              expirationDate,
              '/',
              null!,
              false,
              'Strict',
            );

            this.translateCookieConsent();
            document.documentElement.lang = this.getActiveLanguage().code
              ? this.getActiveLanguage().code.split('-')[0]
              : 'en';
            return this.translate.use(this.getActiveLanguage().code);
          }
          return of(null);
        }),
      )
      .subscribe({
        next: (res) => {
          res;
          this.store.dispatch(new TranslateReady(true));
        },
        error: (err) => {
          err;
          this.store.dispatch(new TranslateReady(false));
        },
      });
    this.store
      .select(getSelectedCustomerSelect)
      .pipe(untilDestroyed(this))
      .subscribe((customer) => {
        if (customer) {
          this.loadCustomerBridges(customer);
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          this.cookieService.set(
            'customer',
            JSON.stringify(customer),
            expirationDate,
            '/',
            null!,
            false,
            'Strict',
          );
          this.loadLicenses();
        }
      });

    this.store
      .select(getManualRequestSelect)
      .pipe(untilDestroyed(this))
      .subscribe((request) => {
        if (request) {
          const lang = this.getActiveLanguage();
          if (lang.code === 'en-UK') {
            window.open(
              'assets/files/ConnectedCare.DeviceManagement_EN.pdf',
              '_blank',
            );
          } else {
            window.open(
              'assets/files/ConnectedCare.DeviceManagement_DE.pdf',
              '_blank',
            );
          }
        }
      });

    router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart && !router.navigated) {
        if (this.cookieService.check('customer')) {
          this.store.dispatch(
            new UpdateSelectedCustomer(
              JSON.parse(this.cookieService.get('customer')),
            ),
          );
        }
      }
    });

    // Initialize oAuthService
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService
      .loadDiscoveryDocument()
      .then((doc) => {
        this.oauthService.tryLogin({});
      })
      .catch((err) => {
        this.logger.log('AppComponent', err);
      });

    this.loginService.initLoginService();

    this.headerHeight = 0;

    this.store
      .select(getBackgroundSelect)
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.background = val!;
      });

    // Subscribe for headerEvents
    this.store
      .select(getHeaderEventSelect)
      .pipe(untilDestroyed(this))
      .subscribe((headerEvent) => {
        if (headerEvent) {
          if (headerEvent.eventType === EventType.ClearRoleView) {
            this.loginService.removeFakeRoles();
            this.store.dispatch(new ReceivedHeaderEvent());
          }
          if (headerEvent.eventType === EventType.SetRoleView) {
            this.loginService.setFakeRole(headerEvent.payload!);
            this.store.dispatch(new ReceivedHeaderEvent());
          }

          if (headerEvent.eventType === EventType.Login) {
            this.loginService.login();
            this.store.dispatch(new ReceivedHeaderEvent());
          }
          if (headerEvent.eventType === EventType.Logout) {
            this.loginService.logout();
            this.store.dispatch(new ReceivedHeaderEvent());
            this.cookieService.delete('customer');
          }
          if (headerEvent.eventType === EventType.SelectCustomer) {
            this.dialog.open(ChooseCustomerDialogComponent, {
              disableClose: true,
              width: '40%',
              closeOnNavigation: false,
            });
          }
          if (headerEvent.eventType === EventType.DeselectCustomer) {
            this.cookieService.delete('customer');
            this.store.dispatch(new UpdateSelectedCustomer(null!));
            if (
              (this.route.snapshot as any)['_routerState'].url !== '/devices'
            ) {
              if (
                this.routeData &&
                this.routeData['needCustomer'] &&
                this.routeData['needCustomer'] === true
              ) {
                this.router.navigate(['/']);
              }
            }
            this.loadLicenses();
          }
        }
      });
    this.getRoutData()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.routeData = data;
        }
      });

    combineLatest([
      this.store.select(getRedirectUrlSelect),
      this.store.select(getLoggedInSelect),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([redirectUrl, loggedIn]) => {
        this._redirectUrl = redirectUrl;
        this._loggedIn = loggedIn;
      });
  }

  get system() {
    const environmentString =
      environment.system === 'prod' ? '' : environment.system;
    const title = `DeviceManagement ${environmentString}`.trim();
    this.titleService.setTitle(title);
    return environmentString;
  }

  private loadLicenses() {
    this.licenseService
      .getAllLicenses()
      .pipe(untilDestroyed(this))
      .subscribe((licenses) => {
        this.store.dispatch(
          new UpdateLicense(licenses?.map((license) => license.id)),
        );
      });
  }

  private loadCustomerBridges(customer: CustomerFullDto) {
    this.websocket
      .subscribe('/ws/bridges/customer/' + customer.id)
      .pipe(
        untilDestroyed(this),
        concatMap((msg) => {
          // add notification with new bridge state
          return this.customerService.getBridge(customer.id!, msg.body);
        }),
      )
      .subscribe((bridge) => {
        if (bridge && !bridge.connected && bridge.connectTimestamp) {
          this.store.dispatch(
            new NotificationAction(
              '',
              NotificationType.NewsBar,
              true,
              () => {},
              {
                data: {
                  buttonText: 'BUTTONS.OK',
                  infoIcon: 'warning',
                  infoTitle: 'NOTIFICATIONS.BRIDGE_STATE',
                  infoText: ' MQTT-Bridge ' + bridge.name,
                  color: 'warn',
                },
              },
            ),
          );
        }
      });
  }

  private _redirectUrl: string | null = null;
  private _navigationStated = false;
  private _loggedIn = false;
  
  ngAfterContentChecked() {
    const newHeight =
      this.header.nativeElement.children[0].offsetHeight +
      this.colorLine.nativeElement.offsetHeight;
    if (newHeight !== this.headerHeight) {
      this.headerHeight = newHeight;
      this.notificationsLibService.setNewsBarTop(newHeight + 'px');
    }

    // Handle redirect after login
    if (this._loggedIn && this._redirectUrl && !this._navigationStated) {
      this._navigationStated = true;

      this.router.navigate([this._redirectUrl]).then(
        (success) => {
          if (success) {
            this._redirectUrl = null;
            this.store.dispatch(new UpdateRedirectUrl(null!));
          } else {
            this.router.navigate(['/']);
          }
          this._navigationStated = false;
        },
        () => {
          this.router.navigate(['/']);
          this._navigationStated = false;
        },
      );
    }
  }

  ngOnInit() {
    combineLatest([
      this.store.select(getRedirectUrlSelect),
      this.store.select(getLoggedInSelect),
    ])
      .pipe(
        untilDestroyed(this),
        map(([redirectUrl, loggedIn]) => {
          return loggedIn;
        }),
        filter((loggedIn: boolean) => loggedIn),
        concatMap((loggedIn) => {
          if (loggedIn) {
            this.loadLicenses();
            return this.notificationsService.loadNotifications(
              this.translate.currentLang,
            );
          }
          return of([]);
        }),
      )
      .subscribe({
        next: (notifications: INotification[]) => {
          if (notifications) {
            const newNotifications = notifications.sort(
              this.compareNotification,
            );
            this.store.dispatch(new NotificationListAction(newNotifications));
            const criticalNotifications = newNotifications.filter(
              (el) => el.priority === 'CRITICAL',
            );
            criticalNotifications.forEach((notification: INotification) => {
              this.store.dispatch(
                new NotificationAction(
                  notification.message,
                  NotificationType.NewsBar,
                  true,
                  () => {
                    this.store.dispatch(
                      new NotificationReadAction(notification),
                    );
                  },
                  {
                    data: {
                      buttonText: 'BUTTONS.READ',
                      infoIcon: notification.icon,
                      infoTitle: notification.title,
                      color: 'warn',
                    },
                  },
                ),
              );
            });
          }
        },
      });

    this.store
      .select(getReadNotificationSelect)
      .pipe(
        untilDestroyed(this),
        filter((notification: INotification) => {
          return !!notification;
        }),
        concatMap((notification: INotification) => {
          return this.notificationsService.readNotification(
            Object.assign({}, notification),
          );
        }),
        concatMap(() => {
          return this.notificationsService.loadNotifications(
            this.translate.currentLang,
          );
        }),
      )
      .subscribe((notifications: INotification[]) => {
        if (notifications) {
          const newNotifications = notifications.sort(this.compareNotification);
          this.store.dispatch(new NotificationListAction(newNotifications));
        }
      });

    this.store
      .select(getPinnedNotificationSelect)
      .pipe(
        untilDestroyed(this),
        filter((notification: INotification) => {
          return !!notification;
        }),
        concatMap((notification: INotification) => {
          if (notification.pinned) {
            return this.notificationsService.unpinNotification(
              Object.assign({}, notification),
            );
          } else {
            return this.notificationsService.pinNotification(
              Object.assign({}, notification),
            );
          }
        }),
        concatMap(() => {
          return this.notificationsService.loadNotifications(
            this.translate.currentLang,
          );
        }),
      )
      .subscribe((notifications: INotification[]) => {
        if (notifications) {
          const newNotifications = notifications.sort(this.compareNotification);
          this.store.dispatch(new NotificationListAction(newNotifications));
        }
      });
  }

  private compareNotification(a: INotification, b: INotification): number {
    let rtn: number;
    if (a.priority === 'LOW') {
      if (b.priority === 'LOW') {
        rtn = 0;
      } else {
        rtn = 1;
      }
    } else if (a.priority === 'NORMAL') {
      if (b.priority === 'LOW') {
        rtn = -1;
      } else if (b.priority === 'NORMAL') {
        rtn = 0;
      } else {
        rtn = 1;
      }
    } else if (a.priority === 'HIGH') {
      if (b.priority === 'CRITICAL') {
        rtn = 1;
      } else if (b.priority === 'HIGH') {
        rtn = 0;
      } else {
        rtn = -1;
      }
    } else if (a.priority === 'CRITICAL') {
      if (b.priority === 'CRITICAL') {
        rtn = 0;
      } else {
        rtn = -1;
      }
    }

    return rtn!;
  }

  private getActiveLanguage(): ILanguage {
    if (!this.cookieService.check('language')) {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      this.cookieService.set(
        'language',
        JSON.stringify({ code: 'de-DE', name: 'Deutsch' }),
        expirationDate,
        '/',
        null!,
        false,
        'Strict',
      );
    }
    return JSON.parse(this.cookieService.get('language'));
  }

  private getRoutData() {
    return this.router.events.pipe(
      map((event): any => {
        if (event instanceof NavigationEnd) {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data) {
              return child.snapshot.data;
            } else {
              return null;
            }
          }
        }
      }),
    );
  }

  private translateCookieConsent() {
    this.translate //
      .get(['COOKIE_CONSENT.MESSAGE', 'COOKIE_CONSENT.DISMISS'])
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.ccService.getConfig().content =
          this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content!.message =
          data['COOKIE_CONSENT.MESSAGE'];
        this.ccService.getConfig().content!.dismiss =
          data['COOKIE_CONSENT.DISMISS'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }
}
