import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UpdateUserRoles } from '../../external-modules/header/actions/auth';
import {
  UpdateIdentity,
  UpdateLoginStatus,
  UpdateRedirectUrl,
} from '../../external-modules/header/actions/login';
import { Subscriptions } from '../../external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '../../external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { Logger } from '../../external-modules/utils/logger/logger.service';

@Injectable({ providedIn: 'root' })
@Subscriptions()
export class LoginService {
  private _fakeRoles: string[] = [];
  private rolesBehaviourSubject = new BehaviorSubject<string[]>([]);
  private fakeRolesBehaviourSubject = new BehaviorSubject<string[]>([]);
  @Unsubscribe()
  private oAuthEventSubscription?: Subscription;

  constructor(
    private oauthService: OAuthService,
    private store: Store<any>,
    private router: Router,
    private logger: Logger,
  ) {}

  setFakeRole(role: string) {
    this._fakeRoles = [role];
    this.fakeRolesBehaviourSubject.next(this._fakeRoles);
  }

  removeFakeRoles() {
    this._fakeRoles = [];
    this.fakeRolesBehaviourSubject.next(this._fakeRoles);
  }

  login() {
    this.oauthService.initImplicitFlow(window.location.pathname);
  }

  logout() {
    this.oauthService.logOut();
  }

  fakeRolesObservable(): Observable<string[]> {
    return this.fakeRolesBehaviourSubject.asObservable();
  }

  rolesObservable(): Observable<string[]> {
    return this.rolesBehaviourSubject.asObservable();
  }

  get roles(): string[] {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return [];
    }

    return claims.roles;
  }

  get fakeRoles(): string[] {
    return this._fakeRoles || [];
  }

  get name() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims.name;
  }

  get identity() {
    const identity: any = this.oauthService.getIdentityClaims();
    return identity;
  }

  get token() {
    return this.oauthService.getAccessToken();
  }

  get idToken() {
    return this.oauthService.getIdToken();
  }

  get loggedInObserver(): Observable<boolean> {
    return of(this.loggedIn);
  }

  get loggedIn() {
    // return true;
    return this.oauthService.hasValidAccessToken();
  }

  get accountLink() {
    return environment.authEndpoint + '/account/';
  }

  updateLoginStatus() {
    this.store.dispatch(new UpdateLoginStatus(this.loggedIn));
    if (this.identity) {
      this.store.dispatch(
        new UpdateIdentity({
          firstName: this.identity['given_name'],
          lastName: this.identity['family_name'],
          accountUrl: environment.authEndpoint + '/account/',
          authToken: this.oauthService.getAccessToken(),
          distributorId: this.identity['distributorId'],
          customerId: this.identity['customerId'],
        }),
      );
      this.rolesBehaviourSubject.next(this.roles);
      this.store.dispatch(new UpdateUserRoles(this.roles));
    } else {
      this.store.dispatch(new UpdateIdentity(null));
      this.store.dispatch(new UpdateUserRoles(null));
    }
  }

  updateRedirectURL() {
    if (this.oauthService.state) {
      this.store.dispatch(new UpdateRedirectUrl(this.oauthService.state));
    }
  }

  initLoginService() {
    this.oAuthEventSubscription = this.oauthService.events.subscribe(
      (e) => {
        switch (e.type) {
          case 'token_expires':
            this.oauthService
              .silentRefresh()
              .then((info) => {})
              .catch((err) => {});
            break;

          case 'token_received':
            this.updateRedirectURL();
            this.updateLoginStatus();
            break;
          case 'logout':
          case 'session_terminated':
            this.updateLoginStatus();
            break;
          default:
            break;
        }
      },
      (e) => {
        this.logger.log('LoginService', e);
      },
    );

    this.updateLoginStatus();
  }
}
