import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AbstractService } from '../../services/abstract/abstract.service';

@Injectable({ providedIn: 'root' })
export class StatisticsService extends AbstractService {
  getStatistics(): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    return this.httpClient
      .get(
        environment.statistics.root + environment.statistics.device_stats,
        options,
      )
      .pipe(catchError(this.handleError));
  }
}
