import { getLicensesSelect } from '@/app/reducer/root.reducer';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ReceivedHeaderEvent } from '../../external-modules/header/actions/header';
import { UpdateSelectedCustomer } from '../../external-modules/header/actions/select';
import { EventType } from '../../external-modules/header/models/header-event';
import {
  getHeaderEventSelect,
  getSelectedCustomerSelect,
} from '../../external-modules/header/reducers/module';
import {
  NotificationAction,
  NotificationType,
} from '../../external-modules/notifications/actions/notification.action';
import { Subscriptions } from '../../external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '../../external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { Logger } from '../../external-modules/utils/logger/logger.service';
import { ChooseCustomerDialogComponent } from '../../modules/library/components/dialogs/choose-customer/choose-customer-dialog.component';
import { LoginService } from '../login/login.service';

@Injectable({ providedIn: 'root' })
@Subscriptions()
export class AuthGuardService {
  private customerSelected = false;
  private _rolesChanged = new BehaviorSubject<string[]>([]);
  private _roles: string[] = [];
  private _fakeRoles: string[] = [];
  private _licenses: number[] = [];
  @Unsubscribe()
  private rolesSubscription: Subscription;
  @Unsubscribe()
  private fakeRolesSubscription: Subscription;
  @Unsubscribe()
  private headerEventSubscription?: Subscription;
  @Unsubscribe()
  private selectedCustomerSubscription?: Subscription;
  @Unsubscribe()
  private licensesSubscription?: Subscription;

  constructor(
    private store: Store<any>,
    private loginService: LoginService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private logger: Logger,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.init();

    this.rolesSubscription = this.loginService
      .rolesObservable()
      .subscribe((newRoles) => {
        this._roles = newRoles;
        this._rolesChanged.next(this._roles);
      });

    this.fakeRolesSubscription = this.loginService
      .fakeRolesObservable()
      .subscribe((newFakeRoles) => {
        this._fakeRoles = newFakeRoles;
        this._rolesChanged.next(this._roles);
      });
  }

  private init() {
    this.customerSelected = false;
    this.headerEventSubscription = this.store
      .select(getHeaderEventSelect)
      .subscribe((headerEvent) => {
        if (headerEvent) {
          if (headerEvent.eventType === EventType.DeselectCustomer) {
            this.customerSelected = false;
            this.store.dispatch(new ReceivedHeaderEvent());
          }
        }
      });

    this.licensesSubscription = this.store
      .select(getLicensesSelect)
      .subscribe((licenses) => {
        this._licenses = licenses;
        const data = this.getRouteData();
        if (
          data &&
          data['license'] &&
          !this._licenses.includes(data['license'])
        ) {
          this.router.navigateByUrl('/');
        }
      });

    this.selectedCustomerSubscription = this.store
      .select(getSelectedCustomerSelect)
      .subscribe((selected) => {
        if (selected !== null) {
          if (selected !== undefined) {
            this.customerSelected = true;
            const data = this.getRouteData();
            if (
              data &&
              data['license'] &&
              !this._licenses.includes(data['license'])
            ) {
              this.router.navigateByUrl('/');
            }
          }
        }
      });
  }

  getRouteData() {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data) {
        return child.snapshot.data;
      } else {
        return null;
      }
    }
    return null;
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.loginService.loggedInObserver.pipe(
      map((loggedIn) => {
        if (
          loggedIn &&
          route.data['roles'] !== undefined &&
          this.hasRoles(route.data['roles']) &&
          (route.data['license'] === undefined ||
            (route.data['license'] !== undefined &&
              this._licenses.includes(route.data['license'])))
        ) {
          return true;
        }

        if (
          (route.data['roles'] === undefined ||
            !this.hasRoles(route.data['roles']) ||
            !(
              route.data['license'] !== undefined &&
              this._licenses.includes(route.data['license'])
            )) &&
          this.loginService.loggedIn
        ) {
          this.store.dispatch(
            new NotificationAction(
              this.translate.instant('ERRORS.NO_PERMISSION'),
              NotificationType.PopUp,
            ),
          );

          this.router.navigateByUrl('/');
        }

        if (!loggedIn) {
          this.logger.log('AuthGuardService', this.oauthService.state);

          this.loginService.login();
        }

        return false;
      }),
      switchMap((loggedIn: boolean) => {
        if (loggedIn && route.data['needCustomer'] !== undefined) {
          if (
            !this.customerSelected &&
            route.data['needCustomer'] === true &&
            (!this.hasRoles(['Customer']) || this._fakeRoles.length > 0)
          ) {
            if (this.cookieService.check('customer')) {
              this.store.dispatch(
                new UpdateSelectedCustomer(
                  JSON.parse(this.cookieService.get('customer')),
                ),
              );
              return of(loggedIn);
            } else {
              const dialogRef = this.dialog.open(
                ChooseCustomerDialogComponent,
                {
                  disableClose: true,
                  width: '40%',
                  minWidth: '20%',
                  closeOnNavigation: false,
                },
              );
              return dialogRef.afterClosed();
            }
          } else {
            return of(loggedIn);
          }
        } else {
          return of(false);
        }
      }),
      switchMap((result: boolean) => {
        return of(
          result &&
            (route.data['license'] === undefined ||
              (route.data['license'] !== undefined &&
                this._licenses.includes(route.data['license']))),
        );
      }),
    );
  }

  hasRoles(requiredRoles: string[]) {
    if (requiredRoles === undefined) {
      return false;
    }

    if (requiredRoles.includes('none')) {
      return true;
    }

    if (this.loginService.fakeRoles && this.loginService.fakeRoles.length > 0) {
      return (
        this.loginService.fakeRoles &&
        this.containsOneRole(this.loginService.fakeRoles, requiredRoles) &&
        this.loginService.loggedIn
      );
    } else {
      return (
        this.loginService.roles &&
        this.containsOneRole(this.loginService.roles, requiredRoles) &&
        this.loginService.loggedIn
      );
    }
  }

  rolesChanged(): Observable<string[]> {
    return this._rolesChanged.asObservable();
  }

  fakeRolesChanged(): Observable<string[]> {
    return this._rolesChanged.asObservable();
  }

  containsOneRole(availableRoles: string[], requiredRoles: string[]): boolean {
    if (availableRoles) {
      return availableRoles.some((role) => requiredRoles.indexOf(role) >= 0);
    }
    return false;
  }
}
