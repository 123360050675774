import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeContentCardComponent } from './components/home-content-card/home-content-card.component';
import { HomeComponent } from './components/home/home.component';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { HeaderModule } from './external-modules/header/header.module';
import { HeaderModuleConfig } from './external-modules/header/header.module.config';
import { NotificationsModule } from './external-modules/notifications/notifications.module';
import { TourMatMenuModule } from './external-modules/tour-md-menu/md-menu.module';
import { BewatecLibraryModule } from './modules/library/bewatec-library.module';
import { BewatecDashboardModule } from './modules/library/components/bewatec-dashboard/bewatec-dashboard.module';
import { reducers as RootReducers, RootState } from './reducer/root.reducer';
import { RequestInterceptor } from './services/httpinterceptor/request.interceptor';
import { BewatecOAuthService } from './services/o-auth/bewatec-oauth.service';

export const ROOT_REDUCER_TOKEN: InjectionToken<ActionReducerMap<RootState>> =
  new InjectionToken<ActionReducerMap<RootState>>('Root Reducers');

export function getReducers(): ActionReducerMap<RootState> {
  const reducers: ActionReducerMap<RootState> = RootReducers;
  return reducers;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.langEndpoint, '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: document.location.hostname,
    // or 'your.domain.com'
    // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#FFF',
    },
    button: {
      background: '#303f9f',
    },
  },
  theme: 'classic',
  type: 'info',
  showLink: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainNavigationComponent,
    MenuItemComponent,
    HomeContentCardComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BewatecLibraryModule,
    BrowserModule,
    TourMatMenuModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    StoreModule.forRoot(getReducers()),
    NotificationsModule.forRoot({ langEndpoint: environment.langEndpoint }),
    HeaderModule.forRoot(
      new HeaderModuleConfig({
        langEndpoint: environment.langEndpoint,
        showRoleView: true,
        enableNotifications: true,
        showHelp: true,
        useCustomHeading: false,
      }),
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    BewatecDashboardModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: OAuthService,
      useClass: BewatecOAuthService,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
